import React, { useState, useEffect } from "react";

import EasySeeso from "seeso/easy-seeso";
import { UserStatusOption } from "seeso";

import { useSelector } from "react-redux";

const licenseKey = process.env.REACT_APP_EYETRACKING_LICENSE_KEY;
const dotMaxSize = 10;
const dotMinSize = 5;

let isCalibrationMode = false;
let currentX, currentY;
let calibrationButton;
let isUseAttention = false;
let isUseDrowsiness = false;
let isUseBlink = true;
const userStatusOption = new UserStatusOption(
  isUseAttention,
  isUseBlink,
  isUseDrowsiness
);

let isBlinkL = false;
let isBlinkR = false;
let isB = false;
let leftOpen = 0;
let rightOpen = 0;
let eyeTracker = null;

const ClassroomEyetrackingLogger = (props) => {
  const {
    focusTimeState,
    name,
    activeClassroom,
    onEyeTrackingData,
    restartEyeTracker,
    setRestartEyeTracker,
  } = props;
  const { calibrationData } = useSelector(({ calibration }) => calibration);
  const [gazeArchive, setGazeArchive] = useState([]);

  useEffect(() => {
    if (!focusTimeState) return;
    if (!restartEyeTracker) return;
    if (restartEyeTracker) {
      if (eyeTracker) {
        eyeTracker.stopTracking();
        eyeTracker = null;
      }
      main();
      setRestartEyeTracker(false);
    }
  }, [restartEyeTracker, activeClassroom, focusTimeState]);

  useEffect(() => {
    if (!focusTimeState) return;
    main();
    return () => {
      if (eyeTracker) {
        eyeTracker.stopTracking();
        eyeTracker = null;
      }
    };
  }, [activeClassroom, focusTimeState]);

  useEffect(() => {
    if (!focusTimeState) return;
    onEyeTrackingData(gazeArchive);
  }, [gazeArchive, focusTimeState]);

  const onBlink = (
    timestamp,
    isBlinktLeft,
    isBlinkRight,
    isBlink,
    leftOpenness,
    rightOpenness
  ) => {
    isBlinkL = isBlinktLeft;
    isBlinkR = isBlinkRight;
    isB = isBlink;
    leftOpen = leftOpenness;
    rightOpen = rightOpenness;
  };

  let attentionScore = 0;
  const onAttention = (timestampBegin, timestampEnd, score) => {
    attentionScore = score;
  };

  let isDrowsy = false;
  const onDrowsiness = (timestamp, isDrowsiness) => {
    isDrowsy = isDrowsiness;
  };

  const onGaze = (gazeInfo) => {
    if (!isCalibrationMode) {
      // do something with gaze info.

      showGaze(gazeInfo);
    } else {
      hideGaze();
    }
  };

  const showGaze = (gazeInfo) => {
    // console.log("Entering showGazeDotOnDom");
    let canvas = document.getElementById("output");
    //언제 이 배열이 reset돼서 archive에 넘어가는지에 대한 고민은 필요함
    // let gazeArchive = [];
    canvas.width = window.self.innerWidth;
    canvas.height = window.self.innerHeight;
    let ctx = canvas.getContext("2d");
    //점색깔 투명하게하기
    // ctx.globalAlpha = "0";
    ctx.fillStyle = "#425080";
    // console.log("x: ", gazeInfo.x, "y: ", gazeInfo.y);
    //gaze 좌표 위치 저장
    // console.log(gazeInfo)
    // gazeArchive.push({
    //   x: gazeInfo.x,
    //   y: gazeInfo.y,
    //   timeStamp: gazeInfo.timestamp,
    //   trackingState: gazeInfo.trackingState,
    //   eyemovementState: gazeInfo.eyemovementState,
    //   leftOpenness: gazeInfo.leftOpenness,
    //   rightOpenness: gazeInfo.rightOpenness
    // });
    let isoDate = new Date().toISOString();

    setGazeArchive({
      x: gazeInfo.x,
      y: gazeInfo.y,
      time: gazeInfo.timestamp,
      timeStamp: isoDate,
      trackingState: gazeInfo.trackingState,
      eyemovementState: gazeInfo.eyemovementState,
      leftOpenness: gazeInfo.leftOpenness,
      rightOpenness: gazeInfo.rightOpenness,
      screenState:
        Number.isNaN(gazeInfo.x) && Number.isNaN(gazeInfo.y)
          ? "outside"
          : "inside",
    });

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // 배열 비우는 코드
    // if (gazeArchive.length === 10) {
    //   console.log(gazeArchive);
    //   gazeArchive.splice(0);
    // }
    // console.log(gazeArchive);
    // console.log(gazeArchive.length, gazeArchive[gazeArchive.length - 1]);

    // 현재 gaze위치에 점을 그림
    ctx.beginPath();
    ctx.arc(gazeInfo.x, gazeInfo.y, 10, 0, Math.PI * 2, true);
    ctx.fill();
  };

  const hideGaze = () => {
    let canvas = document.getElementById("output");
    let ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  };

  const drawCircle = (x, y, dotSize, ctx) => {
    ctx.fillStyle = "#FF0000";
    ctx.beginPath();
    ctx.arc(x, y, dotSize, 0, Math.PI * 2, true);
    ctx.fill();
  };

  const hideCalibrationTitle = () => {
    const calibrationTitle = document.getElementById("calibrationTitle");
    calibrationTitle.style.display = "none";
  };

  const showCalibrationTitle = () => {
    const calibrationTitle = document.getElementById("calibrationTitle");
    calibrationTitle.style.display = "block";
  };

  const onDebug = (FPS, latency_min, latency_max, latency_avg) => {
    // do something with debug info.
  };

  const main = async () => {
    //   if (!calibrationButton) {
    //     calibrationButton = document.getElementById("calibrationButton");
    //     calibrationButton.addEventListener("click", onClickCalibrationBtn);
    //     calibrationButton.disabled = true;
    //   }
    console.log("main ", eyeTracker);

    try {
      if (!eyeTracker) {
        eyeTracker = new EasySeeso();
        try {
          await eyeTracker.init(
            licenseKey,
            async () => {
              if (calibrationData) {
                eyeTracker.seeso.setCalibrationData(calibrationData);
              }
              await eyeTracker.startTracking(onGaze, onDebug);
              eyeTracker.seeso.setTrackingFps(10);
              // eyeTracker.showImage();
              // if (!eyeTracker.checkMobile()) {
              //   eyeTracker.setMonitorSize(14); // 14 inch
              //   eyeTracker.setFaceDistance(50);
              // }
              // enableCalibrationButton();
              eyeTracker.setUserStatusCallback(
                onAttention,
                onBlink,
                onDrowsiness
              );
            },
            async () => {
              console.log("SDK initialization failed");
              // 초기화 실패에 시 다시 시도
              await eyeTracker.init(
                licenseKey,
                async () => {
                  if (calibrationData) {
                    eyeTracker.seeso.setCalibrationData(calibrationData);
                  }
                  await eyeTracker.startTracking(onGaze, onDebug);
                  eyeTracker.seeso.setTrackingFps(10);
                  // eyeTracker.showImage();
                  // if (!eyeTracker.checkMobile()) {
                  //   eyeTracker.setMonitorSize(14); // 14 inch
                  //   eyeTracker.setFaceDistance(50);
                  // }
                  // enableCalibrationButton();
                  eyeTracker.setUserStatusCallback(
                    onAttention,
                    onBlink,
                    onDrowsiness
                  );
                },
                () => {
                  console.log("SDK initialization failed");
                },
                userStatusOption
              );
            },
            userStatusOption
          );
        } catch (error) {
          console.log("callback when init failed.", error);
        }
      } else {
        // calibrationButton.disabled = false;
        if (calibrationData) {
          eyeTracker.seeso.setCalibrationData(calibrationData);
        }
      }
    } catch (error) {
      console.log("main error", error);
    }
  };

  return (
    <div name={name} {...props}>
      <canvas
        id="output"
        style={{
          position: "absolute",
          display: "none",
          zIndex: "2",
          pointerEvents: "none",
        }}
      ></canvas>
      {props.children}
    </div>
  );
};

export default ClassroomEyetrackingLogger;
