import { useQuery } from "@tanstack/react-query";
import { getQuizsetSessionActive, getQuizsetSessionForTest, getQuizsetSessionResult } from "@api";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useMemo } from "react";
import { setSocketData } from "@store/actions";
import { useHistory } from "react-router-dom";
import { getQuizsetSessionActiveByCliendId } from "@api/quizpang/quizpangApi";

export const useQuizsetSession = (groupId = "") => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.user);

  const { data: activeQuizsetSession, isFetching, refetch } = useQuery({
    queryKey: ["quizsetSessionActive", groupId],
    queryFn: () => getQuizsetSessionActive(groupId),
    enabled: !!user?.clientId && !!groupId,
    select: ({ data }) => data,
    refetchOnMount: true,
    onError: () => {
      if (/quizpang\/.*\/session|result/g.test(window.location.pathname)) {
        history.replace(`/quizpang/${groupId}`);
      }
    },
    onSuccess: ({ quizsetSession }) => {
      switch (quizsetSession.state) {
        case "READY":
          if (!/quizpang\/.*\/ready/g.test(window.location.pathname)) {
            dispatch(
              setSocketData({
                method: "POST",
                uri: "/classroom/sendImage",
                groupId,
                clientId: user.clientId,
                type: "QUIZSET_SESSION_ACTIVE_CHECK",
                data: "",
              })
            );
          }
          break;
        case "STARTED":
          if (!/quizpang\/.*\/session|result/g.test(window.location.pathname)) {
            dispatch(
              setSocketData({
                method: "POST",
                uri: "/classroom/sendImage",
                groupId,
                clientId: user.clientId,
                type: "QUIZSET_SESSION_ACTIVE_CHECK",
                data: "",
              })
            );
          }
          break;
        // case 'FINISHED':
        default:
          break;
      }
    },
  });

  const storageQuizTime = useMemo(() => {
    const storage = localStorage.getItem("quizSessionSpinner");
    if (storage && activeQuizsetSession) {
      const { quizsetSessionQuizJoint, quizsetSession } = activeQuizsetSession;
      const { quizsetSessionId, quizOrder } = JSON.parse(storage);

      if (
        quizOrder === quizsetSessionQuizJoint.quizOrder &&
        quizsetSessionId === quizsetSession.quizsetSessionId
      ) {
        return JSON.parse(storage);
      }
    }
    return null;
  }, [activeQuizsetSession]);

  const saveQuizTime = useCallback(
    (second) => {
      if (!activeQuizsetSession) {
        return;
      }
      localStorage.setItem(
        "quizSessionSpinner",
        JSON.stringify({
          quizsetSessionId:
            activeQuizsetSession?.quizsetSession.quizsetSessionId,
          quizOrder: activeQuizsetSession?.quizsetSessionQuizJoint.quizOrder,
          second,
        })
      );
    },
    [activeQuizsetSession]
  );
  const clearQuizTime = useCallback(() => {
    localStorage.removeItem("quizSessionSpinner");
  }, []);

  return {
    quizsetSession: activeQuizsetSession?.quizsetSession,
    quizOrder: activeQuizsetSession?.quizsetSessionQuizJoint.quizOrder,
    isLoading: isFetching,
    refetch,
    storageQuizTime,
    saveQuizTime,
    clearQuizTime,
  };
};

export const useActiveQuizsetSessionByClientId = (clientId = "") => {
  const history = useHistory();
  const { data: activeQuizsetSession, isFetching, refetch } = useQuery({
    queryKey: ["quizsetSessionActiveByClientId", clientId],
    queryFn: () => getQuizsetSessionActiveByCliendId(clientId),
    enabled: !!clientId,
    select: ({ data }) => data,
    refetchOnMount: true,

    onSuccess: ({ quizsetSession }) => {
      switch (quizsetSession.state) {
        case "READY":
          break;
        case "STARTED":
          // if (!/quizpang\/.*\/session|result/g.test(window.location.pathname)) {
          //   history.replace(
          //     `/quizpang/${quizsetSession.groupId}/session/${quizsetSession.quizsetSessionId}`
          //   );
          // }
          // 현재 퀴즈팡 주소가 아니거나 quizsetSessionId가 다른 경우 퀴즈팡으로 이동
          if (
            !/quizpang\/.*\/session|result/g.test(window.location.pathname) ||
            quizsetSession.quizsetSessionId !==
              window.location.pathname.split("/")[4]
          ) {
            history.replace(
              `/quizpang/${quizsetSession.groupId}/session/${quizsetSession.quizsetSessionId}`
            );
          }

          break;
        // case 'FINISHED':
        default:
          break;
      }
    },
  });

  return {
    quizsetSession: activeQuizsetSession?.quizsetSession,
    quizOrder: activeQuizsetSession?.quizsetSessionQuizJoint.quizOrder,
    isLoading: isFetching,
    refetch,
  };
};

export const useQuizsetSessionResultByQuizsetSessionId = (
  quizsetSessionId,
  quizOrder
) => {
  const { data: quizResult, isLoading } = useQuery({
    queryKey: ["result", quizsetSessionId, quizOrder],
    queryFn: () => getQuizsetSessionResult(quizsetSessionId, quizOrder),
    enabled: !!quizsetSessionId,
    select: (data) => data.data,
  });
  return { quizResult, isLoading };
};
export const useQuizsetABSessionResultByQuizsetSessionId = (
  quizsetSessionId,
  quizOrder
) => {
  const { data:abQuizResult, isLoading } = useQuery({
    queryKey: ["ab-result", quizsetSessionId, quizOrder],
    queryFn: () => getQuizsetSessionForTest(quizsetSessionId, quizOrder),
    enabled: !!quizsetSessionId,
    select: data => data.data
  });
  return {abQuizResult, isLoading};
};
