import { Box, Text } from "@chakra-ui/react";
import React from "react";

const QuizShort = ({ choiceDataItem, answerData, totalParticipants }) => {
  const { description, chooserCount, choiceId, isAnswer } = choiceDataItem;

  const answerMatch = answerData?.some(
    (answer) => answer.responseText === description
  );
  const answerChoiceId = answerData?.filter(
    (answer) => answer.choiceId === choiceId
  );
  console.log(choiceId, answerData);
  const studentAnswer = !answerMatch && !isAnswer ? "orange" : "";
  const correctAnswerMatches =
    answerMatch && isAnswer
      ? "royalblue"
      : (isAnswer && "teal.500") || "gray.200";
  const isWrongAnswer = description === "Wrong Answer";

  const percentage = chooserCount / totalParticipants || 0;
  const isEmptyAnswer = answerChoiceId?.length === 0;
  return (
    <Box
      borderRadius="md"
      bg="white"
      display="flex"
      gap="20px"
      alignItems="center"
      m="20px"
      p="20px 10px"
      border="2px"
      borderColor={studentAnswer || correctAnswerMatches}
    >
      {!isWrongAnswer ? (
        <Text fontWeight="600">{description}</Text>
      ) : (
        answerChoiceId.map((answerItemData) => (
          <Text fontWeight="600">
            {answerItemData.responseText ? (
              answerItemData.responseText
            ) : (
              <>답변을 제출하지 않았습니다.</>
            )}
          </Text>
        ))
      )}
      {isWrongAnswer && isEmptyAnswer && (
        <Text fontWeight="600">답변을 제출하지 않았습니다.</Text>
      )}

      <Text fontSize="12px" color="gray.500">
        {chooserCount}명<Box as="span">({Math.round(percentage * 100)}%)</Box>
      </Text>
    </Box>
  );
};

export default QuizShort;
