import {
  Button,
  Container,
  IconButton,
  SimpleGrid,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import SurveyCard from "./SurveyCard";
import { useQuizsetSurvey } from "@pages/QuizPang/hooks/useSurvey";
import { useSelector, useDispatch } from "react-redux";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { Spinner } from "@chakra-ui/react";
import { ArrowDownIcon, ArrowUpIcon } from "@chakra-ui/icons";
import {
  purgeHasAnswer,
  purgeUploadResponse,
  purgeUploadTrigger,
} from "@store/actions";

const SurveyCardWrapper = () => {
  //설문 상태 초기화
  const dispatch = useDispatch();
  const initSurveySession = () => {
    dispatch(purgeUploadTrigger());
    dispatch(purgeUploadResponse());
    dispatch(purgeHasAnswer());
    console.log("INIT FOCUS SURVEY SESSION");
  };
  initSurveySession();

  const { groupId } = useParams();
  const user = useSelector((state) => state.user);
  const quizsetSurvey = useQuizsetSurvey(groupId, user.clientId);
  const history = useHistory();
  if (quizsetSurvey.isLoading) {
    return (
      <Container maxW={"container.xl"}>
        <VStack
          dir="column"
          spacing={4}
          mb={8}
          alignItems={"center"}
          justifyContent={"center"}
          minHeight={"300px"}
          maxHeight={"300px"}
        >
          <Spinner />

          <Text fontSize="2xl" fontWeight="bold">
            선생님께서 배포하신 설문지를 가져오고 있어요...
          </Text>
        </VStack>
      </Container>
    );
  }

  if (quizsetSurvey.isError)
    return (
      <Container maxW={"container.xl"}>
        <VStack
          dir="column"
          spacing={4}
          mb={8}
          alignItems={"center"}
          justifyContent={"center"}
          minHeight={"300px"}
          maxHeight={"300px"}
        >
          <Text fontSize="xl" fontWeight="bold">
            아직 선생님께서 배포하신 설문지가 없는 것 같아요.
          </Text>
          <Button
            colorScheme="blue"
            onClick={() => {
              history.goBack();
            }}
            w={40}
          >
            돌아가기
          </Button>
        </VStack>
      </Container>
    );

  const quizsetSurveyData = quizsetSurvey.quizsetSurvey.data;

  console.log("quizsetSurveyData", quizsetSurveyData);
  console.log("quizsetSurveyData", quizsetSurvey.isSuccess);

  return (
    <>
      <Stack direction={"row"} spacing={4} mb={8}>
        {/* <IconButton
          aria-label="오름차순"
          icon={<ArrowUpIcon />}
          onClick={() => {
            quizsetSurvey.data.sort((a, b) => {
              return a.id - b.id;
            });
          }}
          w={8}
        />
        <IconButton
          aria-label="내림차순"
          icon={<ArrowDownIcon />}
          onClick={() => {
            quizsetSurvey.data.sort((a, b) => {
              return b.id - a.id;
            });
          }}
          w={8}
        /> */}
      </Stack>
      <SimpleGrid
        spacing={8}
        columns={{
          sm: 1,
          md: 2,
          lg: 3,
          xl: 4,
        }}
      >
        {quizsetSurveyData.map((survey) => {
          return (
            <SurveyCard key={survey.quizset.quizsetId} surveyData={survey} />
          );
        })}
      </SimpleGrid>
    </>
  );
};

export default SurveyCardWrapper;
