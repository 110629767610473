import {
  CREATE_SURVEY_RESPONSE,
  PURGE_SURVEYRESPONSE,
  PURGE_SURVEY_LIST_IDS,
  SET_SURVEY_LIST_IDS,
  UPDATE_SRUVEY_LIST_IDS,
} from "./actionTypes";

export const purgeSurveyresponse = () => ({
  type: PURGE_SURVEYRESPONSE,
});

export const createSurveyresponse = (
  quizId,
  choiceId,
  quizType,
  responseText,
  responseLink
) => {
  return {
    type: CREATE_SURVEY_RESPONSE,
    payload: {
      quizId: quizId,
      choiceId: choiceId,
      quizType: quizType,
      responseText: responseText,
      responseLink: responseLink,
    },
  };
};

export const setSurveyListIds = (quizsetId) => {
  const quizsetIdList = quizsetId.split(",");
  const quizsetIdIndex = {};

  quizsetIdList.forEach((quizsetId, index) => {
    quizsetIdIndex[quizsetId] = {
      index: index,
      state: false,
    };
  });
  console.log("quizsetIdIndex", quizsetIdIndex);

  return {
    type: SET_SURVEY_LIST_IDS,
    payload: {
      quizsetIdList: quizsetIdList,
      quizsetIdIndex: quizsetIdIndex,
    },
  };
};

export const purgeSurveyListIds = () => {
  return {
    type: PURGE_SURVEY_LIST_IDS,
  };
};

export const updateSurveyListIds = (quizsetId) => {
  return {
    type: UPDATE_SRUVEY_LIST_IDS,
    payload: quizsetId,
  };
};
