import { useEffect } from 'react';

const useCreateQuizResponse = (uploadTrigger, handleCreateQuizresponse) => {
  useEffect(() => {
    if (uploadTrigger) {
      handleCreateQuizresponse();
    }
  }, [uploadTrigger]);
};

export default useCreateQuizResponse;
