import {
  PURGE_REPORT_LOG,
  SET_REPORT_LOG,
  SET_REPORT_LOCATION_LOG,
  SET_REPORT_CLIENT_ID,
  SET_REPORT_ERROR_LOG,
  CREATE_REPORT_LOG_FAIL,
} from './actionTypes';

import { default as userAgent } from 'utils/deviceInfo/useUserAgent';
const initialState = {
  clientId: '',
  reports: [],
};

const { os: osType } = userAgent();

// {
//   "clientId": "XXXX-XXXX-XXXX-XXXX", // UUID
//   "osType": "ANDROID | IOS | WINDOWS | CHROMEBOOK", // String
//   "title": "XXXX", // String, Optional
//   "content": "XXXX", // String
//   "status": "ALLOWED | DISALLOWED | ETC", // String, Optional
//   "reportedAt": "2022-09-30T20:32:55.639463Z" // Instant
// },

const reportLogs = (state = initialState, action) => {
  // const clientId = useSelector(state => state.user.clientId);

  switch (action.type) {
    case SET_REPORT_CLIENT_ID:
      return {
        ...state,
        clientId: action.clientId,
      };
    case PURGE_REPORT_LOG:
      return {
        ...state,
        reports: [],
      };
    case SET_REPORT_ERROR_LOG:
      return {
        ...state,
        reports: [
          ...state.reports,
          {
            clientId: state.clientId,
            osType,
            title: 'error',
            content: action.reports,
            status: 'ETC',
            reportedAt: new Date().toISOString(),
          },
        ],
      };
    case SET_REPORT_LOG:
      // console.log("elmelm ", action.reports);
      // console.log([
      //   ...state.reports,
      //   {
      //     clientId: state.clientId,
      //     osType,
      //     title: "event",
      //     content: action.reports,
      //     status: "ETC",
      //     reportedAt: new Date().toISOString(),
      //   },
      // ]);
      return {
        ...state,
        reports: [
          ...state.reports,
          {
            clientId: state.clientId,
            osType,
            title: 'event',
            content: action.reports,
            status: 'ETC',
            reportedAt: new Date().toISOString(),
          },
        ],
      };
    case SET_REPORT_LOCATION_LOG:
      return {
        ...state,
        reports: [
          ...state.reports,
          {
            clientId: state.clientId,
            osType,
            title: 'location',
            content: action.reports,
            status: 'ETC',
            reportedAt: new Date().toISOString(),
          },
        ],
      };
    case CREATE_REPORT_LOG_FAIL:
      return {
        ...state,
        reports: [],
      };
    default:
      break;
  }
  return state;
};
export default reportLogs;
