import { createQuizresponse } from "@api";
import {
  Box,
  Image,
  Input,
  Spinner,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import {
  purgeHasAnswer,
  purgeSurveyResponseOk,
  purgeUploadResponse,
  purgeUploadTrigger,
  setHasAnswer,
  setSurveyResponseOk,
  setUploadResponse,
} from "@store/actions";
import { useMutation } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useCreateQuizResponse from "./useCreateQuizResponse";
import SurveyLoading from "./SurveyLoading";
import ReactQuill from "react-quill";
import useKeyboardLogger from "hooks/useKeyboardLogger";

const SurveyShortInput = ({
  quizId,
  quizsetSessionId,
  link,
  linkType,
  title,
  isLastQuiz,
  groupId,
  setQuizEnded,
  type,
  keyboardData,
  setKeyboardData,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const uploadTrigger = useSelector(state => state.control.uploadTrigger);
  const [responseText, setResponseText] = useState("");

  const handleKeyData = data => {
    setKeyboardData(prevData => [...data]);
  };

  useKeyboardLogger(handleKeyData, responseText);
  const { mutate: mutateResponse, isLoading: mutateIsLoading } = useMutation(
    quizResponse => {
      try {
        createQuizresponse(quizResponse).then(r => {
          setResponseText("");
          dispatch(purgeUploadTrigger());
          dispatch(purgeHasAnswer());
          dispatch(purgeUploadResponse());
          setQuizEnded(false);
          dispatch(setSurveyResponseOk());

          if (type !== "PACKAGE" && isLastQuiz) {
            dispatch(purgeSurveyResponseOk());
            alert("제출이 완료되었습니다!");
            history.push(`/focussurvey/${groupId}`);
          } else if (type === "PACKAGE" && isLastQuiz) {
            alert("제출이 완료되었습니다!");
          }
        });
      } catch (error) {
        setResponseText("");
        dispatch(purgeUploadTrigger());
        dispatch(purgeHasAnswer());
        dispatch(purgeUploadResponse());
        console.log(error);
        alert("제출에 실패했습니다.");
      }
    },
  );

  if (responseText !== "") {
    dispatch(setHasAnswer());
  } else {
    dispatch(purgeHasAnswer());
  }

  const handleCreateQuizresponse = async () => {
    console.log("ccc long");
    const quizresponse = {
      quizsetSessionId: quizsetSessionId,
      quizId: quizId,
      clientId: user.clientId,
      responseText: responseText,
    };
    mutateResponse(quizresponse);
  };

  useCreateQuizResponse(uploadTrigger, handleCreateQuizresponse);

  // if (mutateIsLoading) {
  if (uploadTrigger) {
    return <SurveyLoading />;
  }
  return (
    <Stack direction={"column"} spacing={4} mb={8} maxH={"70vh"}>
      {title !== "" && (
        <Box
          border="1px"
          borderColor="gray.200"
          borderRadius={"lg"}
          p={4}
          minH={"15vh"}
          maxH={"30vh"}
          overflow={"auto"}
        >
          <ReactQuill
            theme="bubble"
            value={title}
            readOnly={true}
            modules={{ toolbar: false }}
          />
        </Box>
      )}
      {linkType === "IMG" && link !== "" && (
        <Box
          borderRadius={"lg"}
          border="1px"
          borderColor="gray.200"
          p={4}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          // h={{
          //   base: "400px",
          //   md: "400px",
          //   lg: "400px",
          // }}
          h={"40vh"}
          overflow={"auto"}
        >
          <Image
            // cors
            crossOrigin={"anonymous"}
            src={link}
            maxW="100%" // 최대 너비 설정
            h="auto"
          />
        </Box>
      )}
      <Box
        borderRadius={"lg"}
        border="1px"
        borderColor="gray.200"
        p={8}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        maxH={"30vh"}
        overflowY={"auto"}
      >
        <Input
          type="text"
          placeholder="답변을 입력해주세요."
          h={{
            base: "50px",
            md: "50px",
            lg: "75px",
          }}
          bg={"white"}
          as="textarea"
          resize="none"
          onChange={e => {
            setResponseText(e.target.value);
          }}
          maxH={"90%"}
        />
      </Box>
    </Stack>
  );
};

export default SurveyShortInput;
