import { Accordion } from "@chakra-ui/react";
import QuizResultAccordionItem from "./QuizResultAccordionItem";

const QuizResultAccordion = ({ quizresults, leaderboards, answerData, isShowRank }) => {
  return (
    <Accordion w="full" defaultIndex={[0, 1]} allowToggle>
      {quizresults?.map((resultDataItem) => (
        <QuizResultAccordionItem
          resultDataItem={resultDataItem}
          answerData={answerData}
          leaderboards={leaderboards}
          isShowRank={isShowRank}
        />
      ))}
    </Accordion>
  );
};

export default QuizResultAccordion;
