import { TimeIcon } from "@chakra-ui/icons";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Heading,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";
import { setReportLog } from "@store/actions";
import { ButtonConstants } from "constants/buttonConstants";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const SurveyCard = ({ surveyData }) => {
  const groupId = surveyData.quizsetSession.groupId;
  const history = useHistory();
  const surveySetData = surveyData.quizset;
  const surveyState = surveyData.surveyState;
  const surveySetSessionData = surveyData.quizsetSession;
  const dispatch = useDispatch();

  // 설문 남은 날짜
  const surveyLeftDays = Math.floor(
    (new Date(surveySetSessionData.finishAt) - new Date()) /
      1000 /
      60 /
      60 /
      24,
  );

  // 설문 남은 시간
  const surveyLeftHours = Math.floor(
    (new Date(surveySetSessionData.finishAt) - new Date()) / 1000 / 60 / 60,
  );

  // 설문 남은 날짜와 시간을 계산하였을 때 0보다 작으면 시간이 아닌 지난 날짜로 표시 하는 함수
  const surveyLeftDaysAndHours = () => {
    if (surveyLeftDays < 0) {
      return "설문이 마감되었습니다.";
    } else if (surveyLeftDays === 0 && surveyLeftHours < 0) {
      return "설문이 마감되었습니다.";
    } else if (surveyLeftDays === 0 && surveyLeftHours > 0) {
      return surveyLeftHours + "시간 남았어요!";
    }
    return surveyLeftDays + "일 남았어요!";
  };

  return (
    <SimpleGrid
      spacing={8}
      templateColumns="repeat(auto-fill, minmax(240px, 1fr))"
      minHeight={"400px"}
      maxWidth={"320px"}
    >
      <Card>
        <CardHeader>
          <Heading size="md">설문지</Heading>
        </CardHeader>
        <CardBody>
          <Stack direction={"column"} spacing={4}>
            <Heading size="xs">{surveySetData.title}</Heading>
            <Stack direction={"column"} spacing={2}>
              <Text fontSize="sm" color="gray.500">
                배포일:
              </Text>
              <Text fontSize="sm" color="gray.500">
                {surveySetSessionData.startAt.slice(0, 4) +
                  "년 " +
                  surveySetSessionData.startAt.slice(5, 7) +
                  "월 " +
                  surveySetSessionData.startAt.slice(8, 10) +
                  "일 " +
                  surveySetSessionData.startAt.slice(11, 13) +
                  "시 " +
                  surveySetSessionData.startAt.slice(14, 16) +
                  "분"}
              </Text>
              <Text fontSize="sm" color="gray.500">
                설문 마감일:
              </Text>
              <Text fontSize="sm" color="gray.500">
                {surveySetSessionData.finishAt &&
                  surveySetSessionData.finishAt.slice(0, 4) +
                    "년 " +
                    surveySetSessionData.finishAt.slice(5, 7) +
                    "월 " +
                    surveySetSessionData.finishAt.slice(8, 10) +
                    "일 " +
                    surveySetSessionData.finishAt.slice(11, 13) +
                    "시 " +
                    surveySetSessionData.finishAt.slice(14, 16) +
                    "분"}
              </Text>
              {surveySetSessionData.finishAt && (
                <Text
                  mt={2}
                  fontSize="sm"
                  color="gray.500"
                  fontWeight="bold"
                  display={surveyState === "DONE" ? "none" : "block"}
                >
                  <TimeIcon color={"gray.500"} mr={2} w={4} h={4} />
                  {surveyLeftDaysAndHours()}
                </Text>
              )}

              {!surveySetSessionData.finishAt && (
                <Text
                  mt={2}
                  fontSize="sm"
                  color="gray.500"
                  fontWeight="bold"
                  display={surveyState === "DONE" ? "none" : "block"}
                >
                  <TimeIcon color={"gray.500"} mr={2} w={4} h={4} />
                  마감일이 정해지지 않았어요!
                </Text>
              )}
            </Stack>
            <Divider />
            <Text
              fontSize="lg"
              color="gray.500"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {surveySetData.description}
            </Text>
          </Stack>
        </CardBody>
        <CardFooter>
          <Button
            variant={
              surveyState === "NOT_DONE"
                ? "solid"
                : surveyState === "DONE"
                ? "solid"
                : surveyState === "IN_PROGRESS"
                ? "outline"
                : "solid"
            }
            colorScheme={
              surveyState === "NOT_DONE"
                ? "blue"
                : surveyState === "DONE"
                ? "teal"
                : surveyState === "IN_PROGRESS"
                ? "blue"
                : "teal"
            }
            isDisabled={surveyState === "DONE" ? true : false}
            onClick={() => {
              history.push(
                `/focussurvey/${groupId}/${surveySetSessionData.quizsetId}/${surveySetSessionData.quizsetSessionId}`,
              );
              dispatch(setReportLog(ButtonConstants.SURVEY.START_SURVEY));
            }}
          >
            {surveyState === "NOT_DONE"
              ? "설문 시작하기"
              : surveyState === "DONE"
              ? "설문지 완료"
              : surveyState === "IN_PROGRESS"
              ? "진행중인 설문 다시 시작하기"
              : "설문 시작하기"}
          </Button>
        </CardFooter>
      </Card>
    </SimpleGrid>
  );
};

export default SurveyCard;
