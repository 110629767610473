export const socketKind = {
  classroom: {
    CLASS_START: "CLASS_START",
    CLASS_END: "CLASS_END",
    DOING_CLASS: "DOING_CLASS",
    FINISHED_CLASS: "FINISHED_CLASS",
    FOCUS_START: "FOCUS_START",
    FOCUS_END: "FOCUS_END",
    JOIN_GROUP: "JOIN_GROUP",
    ATTEND_CLASS: "ATTEND_CLASS",
  },

  timetable: {
    CLASS_UPDATE: "CLASS_UPDATE",
    CLASS_DELETE: "CLASS_DELETE",
  },

  quizpang: {
    QUIZ_NEXT_START: "QUIZ_NEXT_START",
    QUIZSET_START: "QUIZSET_START",
    QUIZSET_DOING: "QUIZSET_DOING",
    QUIZSET_COMPLETED: "QUIZSET_COMPLETED",
    QUIZSET_NEXT_START: "QUIZSET_NEXT_START",
    QUIZSET_SESSION_ACTIVE_CHECK: "QUIZSET_SESSION_ACTIVE_CHECK",
    QUIZSET_SESSION_ACTIVE_CONFIRM: "QUIZSET_SESSION_ACTIVE_CONFIRM",
  },
  system: {
    REFRESH_NOW: "REFRESH_NOW",
  },
  survey: {
    SURVEYSET_START: "SURVEYSET_START",
  },
};

export const socketUrl = {
  classroomSendImage: "/classroom/sendImage",
  pulse: "/pulse",
};

export const socketMethod = {
  // method
  POST: "POST",
  GET: "GET",
  PATCH: "PATCH",
  DELETE: "DELETE",
};
