import styled from 'styled-components'
import { useState } from 'react';
import { ReactComponent as DateSmallImg } from '@images/icons/date-small.svg';
import ChooseDate from './ChooseDate';

const Container = styled.div``
const DateContainer = styled.div`
  color: #212120;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 133.333% */
  letter-spacing: 0.25px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: relative;
`
const Title = styled.div`
  margin-right: 10px;
  color: #212120;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 100% */
  letter-spacing: 0.25px;
`
const Date = styled.button`
  width: 160px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #E4E4E4;
  background: var(--White, #FFF);
  color: #212120;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 133.333% */
  letter-spacing: 0.25px;
`
const DateContents = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`

const AttendanceListDatePicker = ({ fromDate, toDate, setYear, setMonth }) => {
  const [showFromDatePicker, setShowFromDatePicker] = useState(false)
  const [showToDatePicker, setShowToDatePicker] = useState(false)

  const fromDateString = fromDate.getFullYear() + '. ' + (fromDate.getMonth() + 1) + '. ' + fromDate.getDate()
  const toDateString = toDate.getFullYear() + '. ' + (toDate.getMonth() + 1) + '. ' + toDate.getDate()

  return (
    <>
      <Container>
        <DateContainer>
          <Title>검색 기간</Title>
          <Date>
            <DateContents onClick={() => setShowFromDatePicker(true)}>
              <DateSmallImg />{fromDateString}
            </DateContents>
          </Date>
          ~
          <Date>
            <DateContents onClick={() => setShowToDatePicker(true)}>
              <DateSmallImg />{toDateString}
            </DateContents>
          </Date>

          {
            (showFromDatePicker || showToDatePicker) && (
              <div style={{
                position: 'absolute',
                top: 'calc(100% + 10px)'
              }}>
                <ChooseDate year={showFromDatePicker ? fromDate.getFullYear() : toDate.getFullYear()}
                            month={showFromDatePicker ? fromDate.getMonth() + 1 : toDate.getMonth() + 1}
                            minYear={!showFromDatePicker && fromDate.getFullYear()}
                            maxYear={showFromDatePicker && toDate.getFullYear()}
                            minMonth={!showFromDatePicker && fromDate.getFullYear() === toDate.getFullYear() && fromDate.getMonth() + 1}
                            maxMonth={showFromDatePicker && fromDate.getFullYear() === toDate.getFullYear() && toDate.getMonth() + 1}
                            changeYear={(year) => { setYear(year, showFromDatePicker ? 'from' : 'to') }}
                            changeMonth={(month) => { setMonth(month, showFromDatePicker ? 'from' : 'to') }}
                            close={() => { showFromDatePicker ? setShowFromDatePicker(false) : setShowToDatePicker(false) }}
                />
              </div>
            )
          }
        </DateContainer>
      </Container>
    </>
  )
}

export default AttendanceListDatePicker