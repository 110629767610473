import {
  useQuizsetABSessionResultByQuizsetSessionId,
  useTestCrewType,
} from "@pages/QuizPang/hooks";
import React from "react";
import QuizResultAccordion from "./Accordion/QuizResultAccordion";

const ABQuizResultList = ({
  quizsetSessionId,
  quizOrder,
  userClientId,
  answerData,
  isShowRank,
}) => {
  const { abQuizResult } = useQuizsetABSessionResultByQuizsetSessionId(
    quizsetSessionId,
    quizOrder
  );
  const { crewType } = useTestCrewType(quizsetSessionId, null, userClientId);

  const quizResultData = abQuizResult?.testCrews[crewType].quizresults ?? [];
  const leaderBoardData = abQuizResult?.testCrews[crewType].leaderboards ?? [];
  return (
    <QuizResultAccordion
      quizresults={quizResultData}
      leaderboards={leaderBoardData}
      answerData={answerData}
      isShowRank={isShowRank}
    />
  );
};

export default ABQuizResultList;
