import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import styles from "./index.module.scss";

import Sidebar from "@components/Layout/Sidebar";
import CardLectureInfo from "@components/Card/CardLectureInfo";
import CardLectureInfo_Empty from "@components/Card/CardLectureInfo_Empty";
import InviteCodeJoinUp from "@components/Modal/InviteCodeJoinUp";

import { useTimetableByClientId } from "@pages/Classroom/hooks/useTimetable";
import {
  classifyTimetable,
  getDayToKorean,
} from "utils/timetable/timetableUtils";

import { selectAllMyGroupsCachever } from "@store/selectors";
import { setReportLog } from "@store/actions";
import { ButtonConstants } from "constants/buttonConstants";
import { Button, HStack, Text } from "@chakra-ui/react";

const Home = () => {
  const user = useSelector((state) => state.user);
  const allMyGroups = useSelector((state) =>
    selectAllMyGroupsCachever(state, user)
  );
  const [showInvite, setShowInvite] = useState(false);

  const getTimetableData = useTimetableByClientId(user.clientId);
  const dispatch = useDispatch();
  if (getTimetableData.isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  if (getTimetableData.isError) {
    return (
      <>
        <div className="flex justify-center items-center h-screen">
          <div className="justify-center items-center flex flex-col space-y-4 ">
            <div className="text-2xl text-gray-900">
              참여한 수업이 존재하지 않습니다. 수업을 추가해볼까요?
            </div>
            {/* <button
              className={styles["add-class-button"]}
              onClick={() => {
                setShowInvite(true);
                dispatch(setReportLog(ButtonConstants.HOME.ADD_CLASS));
              }}
            >
              수업 추가
              <AddCircleIcon
                style={{
                  width: 20,
                  height: 20,
                  color: "#0894A0",
                  marginLeft: 10,
                  marginBottom: 3,
                }}
              />
            </button> */}
            <Button
              leftIcon={<AddCircleIcon />}
              onClick={() => {
                setShowInvite(true);
                dispatch(setReportLog(ButtonConstants.HOME.ADD_CLASS));
              }}
            >
              수업 추가
            </Button>
          </div>
        </div>
        <InviteCodeJoinUp
          showInvite={showInvite}
          setShowInvite={setShowInvite}
        />
      </>
    );
  }

  const timetableData = getTimetableData.timetable.data;

  const classifiedTimetable = classifyTimetable(timetableData);

  return (
    <>
      <Sidebar />
      <div className={styles["container"]}>
        <HStack spacing={2} mt={4} mb={4}>
          <Text fontSize="2xl" fontWeight="bold" color="gray.600">
            수업 시간표
          </Text>
          {/* <button
            className={styles["add-class-button"]}
            onClick={() => {
              setShowInvite(true);
              dispatch(setReportLog(ButtonConstants.HOME.ADD_CLASS));
            }}
          >
            수업 추가
            <AddCircleIcon
              style={{
                width: 20,
                height: 20,
                color: "#0894A0",
                marginLeft: 10,
                marginBottom: 3,
              }}
            />
          </button> */}

          <Button
            leftIcon={<AddCircleIcon />}
            onClick={() => {
              setShowInvite(true);
              dispatch(setReportLog(ButtonConstants.HOME.ADD_CLASS));
            }}
          >
            수업 추가
          </Button>
        </HStack>

        <div className={styles["list-wrapper"]}>
          {Object.entries(classifiedTimetable).map(([weekDay, timeTables]) => {
            var timeTables2 = [];

            var previousClass = 0;
            for (var i = 0; i < timeTables.length; i++) {
              var thisEntry = timeTables[i];
              var currentClass = thisEntry.period;
              while (previousClass + 1 < currentClass) {
                var timeTableObjectKey = weekDay + "-" + (previousClass + 1);
                var emptyEntry = {
                  timetableId: timeTableObjectKey,
                  groupId: null,
                  period: previousClass + 1,
                  startTime: "",
                  endTime: "",
                };
                timeTables2.push(emptyEntry);
                previousClass = previousClass + 1;
              }
              timeTables2.push(thisEntry);
              previousClass = currentClass;
            }

            var maxClass = 8;
            while (previousClass + 1 <= maxClass) {
              var timeTableObjectKey = weekDay + "-" + (previousClass + 1);
              var emptyEntry = {
                timetableId: timeTableObjectKey,
                groupId: null,
                period: previousClass + 1,
                startTime: "",
                endTime: "",
              };
              timeTables2.push(emptyEntry);
              previousClass = previousClass + 1;
            }
            return (
              <div className={styles["column"]} key={String(weekDay)}>
                <div className={styles["top"]}>
                  <div className={styles["weekday"]}>
                    {getDayToKorean(weekDay)}
                  </div>
                </div>
                {timeTables2.map((timeTable) => {
                  const targetGroup = allMyGroups?.find(
                    (group) => group.groupId === timeTable.groupId
                  );
                  if (!targetGroup) {
                    if (timeTable.groupdId != null) return null;
                    var targetGroupEmpty = {
                      grade: 0,
                      groupName: "emptySlot",
                      subjectName: "",
                    };
                    return (
                      <CardLectureInfo_Empty
                        key={timeTable.timetableId}
                        timetable={timeTable}
                        group={targetGroupEmpty}
                        prop_tables={timeTables}
                        prop_weekday={weekDay}
                      />
                    );
                  }
                  return (
                    <CardLectureInfo
                      key={timeTable.timetableId}
                      timetable={timeTable}
                      group={targetGroup}
                      prop_tables={timeTables}
                      prop_weekday={weekDay}
                    />
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
      <InviteCodeJoinUp showInvite={showInvite} setShowInvite={setShowInvite} />
    </>
  );
};

export default Home;
