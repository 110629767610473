import React from 'react';
import styled from 'styled-components';
import QuizEyetrackingLogger from './quiz-eyetracking-logger';
import QuizGPSLogger from './quiz-gps-logger-buffer';
import QuizTouchLoggerContainer from './quiz-touch-logger-container';
import QuizAudioLogger from './quiz-audio-logger';
import QuizAccelGyroLogger from './quiz-accel-gyro-logger-buffer';
import { Container } from '@chakra-ui/react';
const QuizLogger = ({
  name,
  quizType,
  quizIndex,
  quizSessionType,
  onEyeTrackingData,
  onGpsData,
  onAccelgyroData,
  myRef,
  touchData,
  setTouchData,
  deviceType,
  isStartRecording,
  onVoiceData,
  clientId,
  quizId,
  quizEnded,
  children,
}) => {
  return (
    <QuizEyetrackingLogger
      name={name}
      quizType={quizType}
      quizIndex={quizIndex}
      onEyeTrackingData={onEyeTrackingData}
      quizSessionType={quizSessionType}
    >
      <QuizGPSLogger
        name={name}
        quizType={quizType}
        onGpsData={onGpsData}
        quizSessionType={quizSessionType}
      >
        <QuizAccelGyroLogger
          name={name}
          quizType={quizType}
          onAccelgyroData={onAccelgyroData}
          quizSessionType={quizSessionType}
        >
          <QuizTouchLoggerContainer
            name={name}
            myRef={myRef}
            touchData={touchData}
            setTouchData={setTouchData}
            quizType={quizType}
            deviceType={deviceType}
            quizSessionType={quizSessionType}
          >
            {/* <QuizAudioLogger
                name={name}
                quizType={quizType}
                isStartRecording={isStartRecording}
                onVoiceData={onVoiceData}
                clientId={clientId}
                quizId={quizId}
                quizSessionType={quizSessionType}
                upload={quizEnded}
              > */}
            {children}
            {/* </QuizAudioLogger> */}
          </QuizTouchLoggerContainer>
        </QuizAccelGyroLogger>
      </QuizGPSLogger>
    </QuizEyetrackingLogger>
  );
};

export default React.memo(QuizLogger);
