import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import styles from "./Sidebar.module.scss";
import SidebarClassList from "@components/Layout/SidebarClassList";

import { selectAllClients, selectManagingGroup } from "@store/selectors";

import InviteCodeJoinUp from "@components/Modal/InviteCodeJoinUp";
import ProfileImage from "@components/ProfileImage";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import { ReactComponent as PsychologySvg } from "@images/icons/psychology.svg";
import { ReactComponent as AttendanceSvg } from "@images/icons/attendance.svg";
import { profileImgPath } from "@api";
import SidebarTab from "@components/SidebarTab/SidebarTab";
import SidebarGroupTab from "@components/SidebarTab/SidebarGroupTab";
import SettingsIcon from "@mui/icons-material/Settings";
import { selectTeacherMemberships } from "@store/membership/selector";
import PlusIcon from "@images/icons/mdi_plus.png";
import { Tooltip } from "@mui/material";
import { setSelectedClass } from "@store/actions";
import { setReportLog } from "@store/reportLog/actions";
import { ButtonConstants } from "constants/buttonConstants";

/**
 * @typedef {Object} SidebarProps
 * @property {focuspang.Group} [afterSchoolGroup]
 */

/**
 *
 * @param {SidebarProps} props
 * @returns
 */
function Sidebar({ afterSchoolGroup }) {
  const dispatch = useDispatch();
  let ClassListEnalble = false;
  const history = useHistory();
  const { groupId } = useParams();
  const [showInvite, setShowInvite] = useState(false);
  const user = useSelector((state) => state.user);
  const clients = useSelector((state) => selectAllClients(state));
  const managingGroup = useSelector((state) => selectManagingGroup(state));

  const teacherMemberships = useSelector((state) =>
    selectTeacherMemberships(state)
  );

  const findClientName = (groupId) => {
    if (!groupId) return null;

    const foundMembership = teacherMemberships?.find(
      (membership) => membership.groupId === groupId
    );
    if (!foundMembership) return null;

    const foundClient = clients?.find(
      (client) => foundMembership?.clientId === client.clientId
    );
    if (foundClient !== null) return foundClient?.userName;

    return null;
  };

  useEffect(() => {
    groupId && dispatch(setSelectedClass(groupId));
  }, [dispatch, groupId]);

  // const group = useSelector(
  //   (/** @type {import('@store').State} */ state) => selectHRGroup(state)
  // );
  // const afterSchoolGroup = useSelector(
  //   (/** @type {import('@store').State} */ state) => selectAfterSchoolGroup(state)
  // );
  // const sub_group = useSelector((state) => selectSubjectGroups(state));

  const profileImageUrl = useSelector((
    /** @type {import('@store').State} */ state
  ) => {
    return state.profile.profileImageUrl;
  });

  const myProfileImage = useMemo(() => {
    if (!user) {
      return undefined;
    }

    return profileImgPath(user.clientId);
  }, [user]);

  useEffect(() => {
    if (ClassListEnalble) return <SidebarClassList />;
  }, [ClassListEnalble]);

  const onPressSettings = () => {
    if (user.signedIn) {
      history.replace("/settings");
    }
    return;
  };

  const onPressDevMode = () => {
    if (user.signedIn) {
      history.replace("/dev");
    }
    return;
  };

  const onPressHelp = () => {
    const helpUrl = "https://focuspang.com/about.html";
    try {
      window.cAPI.openUrl(helpUrl);
    } catch (error) {
      window.open(helpUrl);
    }
  };

  return (
    <div className={styles["wrapper"]}>
      <div className={styles["inner-side"]}>
        <div className={styles["student-info-container"]}>
          {user.signedIn && (
            <>
              <ProfileImage size={40} url={profileImageUrl} />
              <div className={styles["user-name-container"]}>
                <Tooltip title={user?.userName} arrow>
                  <div className={styles["p-user-name"]}>{user?.userName}</div>
                </Tooltip>
                <div className={styles["p-student-number"]}>
                  학번 : {user?.studentNumber}
                </div>
              </div>
            </>
          )}
        </div>

        <div className={styles["horizontal-line"]} />

        <div
          onClick={() => {
            history.replace("/home");
            dispatch(setReportLog(ButtonConstants.SIDEBAR.HOME));
          }}
        >
          <SidebarTab
            icon={<HomeRoundedIcon style={{ marginRight: 24 }} />}
            text="처음으로"
            active={history.location.pathname === "/home"}
          />
        </div>

        <div className={styles["horizontal-line"]} />

        <div
          onClick={() => {
            history.replace(
              `/managing${
                (managingGroup ?? null) === null
                  ? ""
                  : `/${managingGroup[0]?.groupId}`
              }`
            );
            dispatch(setReportLog(ButtonConstants.SIDEBAR.SELF_MANAGING));
          }}
        >
          <SidebarTab
            icon={<PsychologySvg style={{ marginRight: 24 }} />}
            text="자기 관리 연습"
            active={history.location.pathname.startsWith("/managing")}
          />
        </div>

        {history.location.pathname.startsWith("/managing") &&
          managingGroup.length !== 0 && (
            <>
              <div className={styles["managing-sidebar"]}>
                <span className={styles["managing-title"]}>
                  초대된 관리 그룹
                </span>

                <button
                  className={styles["managing-add-box"]}
                  onClick={() => setShowInvite(true)}
                >
                  <div className={styles["managing-add"]}>그룹 초대 받기</div>
                  <img className={styles["managing-add-icon"]} src={PlusIcon} />
                </button>
              </div>

              <div className={styles["managing-group-container"]}>
                {managingGroup.map((group) => {
                  return (
                    <SidebarGroupTab
                      group={group}
                      findClientName={findClientName}
                      active={
                        history.location.pathname ===
                        `/managing/${group.groupId}`
                      }
                    />
                  );
                })}
              </div>
            </>
          )}

        <div />
      </div>

      <div className={styles["setting-parent"]}>
        <div
          className={styles["setting-box"]}
          onClick={() => {
            onPressSettings();
            dispatch(setReportLog(ButtonConstants.SIDEBAR.CONFIG));
          }}
        >
          <SettingsIcon
            style={{
              color: "#424242",
              width: 20,
              height: 20,
            }}
          />
        </div>
      </div>

      <InviteCodeJoinUp showInvite={showInvite} setShowInvite={setShowInvite} />
    </div>
  );
}

export default Sidebar;
