import styled from 'styled-components';
import { ReactComponent as SettingBlackImg } from '@assets/images/icons/setting-black.svg';

const Table = styled.table`
  margin-top: 30px;
  text-align: center;
`
const ColGroup = styled.colgroup``
const Col = styled.col``
const THead = styled.thead``
const TBody = styled.tbody``
const Tr = styled.tr``
const Th = styled.th`
  height: 60px;
  background: #F2F2F2;
  color: ${props => props.color || '#212120'};
  text-align: center;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 133.333% */
  letter-spacing: 0.25px;
  &:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  &:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`
const Td = styled.td`
  height: 60px;
  color: ${props => props.color || '#212120'};
  text-align: center;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 133.333% */
  letter-spacing: 0.25px;
`
const TdButton = styled.button`
  width: 100%;
  height: 100%;
  color: #418894;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 10px;
`

const AttendanceDetailList = ({ onShowChangeAttendanceStatePopup }) => {
  return (
    <Table>
      <ColGroup>
        <Col width="1%" />
        <Col width="1%" />
        <Col width="1%" />
        <Col width="1%" />
        <Col width="1%" />
      </ColGroup>
      <THead>
        <Tr>
          <Th>학번</Th>
          <Th>학생명</Th>
          <Th>출석 체크 시간</Th>
          <Th>출결 상태</Th>
          <Th>상세 내용</Th>
        </Tr>
      </THead>
      <TBody>
        <Tr>
          <Td>30501</Td>
          <Td>김일번</Td>
          <Td>08:59</Td>
          <Td>
            <TdButton onClick={onShowChangeAttendanceStatePopup}>
              출석
              <SettingBlackImg style={{ transform: 'translateY(-1px)' }} />
            </TdButton>
          </Td>
          <Td>-</Td>
        </Tr>
      </TBody>
    </Table>
  )
}

export default AttendanceDetailList