import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  getActiveClassroomPromise,
  setTeacherScreen,
  setSelectedStudent,
  getGroupProgramconfigsPromise,
  deleteProgramconfigSuccess,
  getUserPromise,
  resetSocket,
  resetSocketDataQueue,
  registerNoty,
  purgeClient,
  purgeClassroom,
  purgeGroup,
  purgeMembership,
  purgeTimetable,
  getMembershipPromise,
  getActiveManagingPromise,
  purgeManaging,
  getManagedPromise,
  updateManagedPromise,
  createManagedPromise,
  getManagingsByGroupPromise,
  setStudentImages,
  setCommentsTeacher,
  setCommentsLayerActive,
  setCommentsTarget,
  setCommentsStudent,
  setCommentsSharedScreen,
  setSocketData,
  getParticipationPromise,
  updateParticipationPromise,
  getGroup,
  purgeParticipation,
  setSelectedClass,
  setReceviedGroupId,
  setFocusTimeState,
  purgeFocusTimeState,
  setSurveyListIds,
  purgeSurveyListIds,
} from "@store/actions";
import { useInterval } from "./utils";
import { selectActiveClassroom, selectAllGroups } from "@store/selectors";
import { useHistory } from "react-router-dom";
import { useSystemNotification } from "hooks/useSystemNotification";
import { useQuizsetSession } from "@pages/QuizPang/hooks";
import { useTimetableByClientId } from "@pages/Classroom/hooks/useTimetable";
import { socketKind, socketMethod, socketUrl } from "constants/socketConstants";
import {
  serviceSocketDisconnectSlackAlarm,
  serviceSocketReconnectErrorSlackAlarm,
} from "utils/alert/slackAlarm";

/**
 * @typedef {Object} ServerMessage
 * @property {string} groupId
 * @property {string} clientId
 * @property {string} type
 * @property {string} data
 */

const ConnectionManager = () => {
  const user = useSelector(
    (/** @type {import('@store').State} */ state) => state.user
  );
  const groupId = useSelector((state) => state.control.selectedClassId);
  useSystemNotification(true);
  useQuizsetSession(groupId);

  const [lastTimestamp, setLastTimestamp] = useState(new Date());

  const [webSocket, setWebSocket] = useState();
  const [webSocketReady, setWebSocketReady] = useState(false);
  const [serverMessage, setServerMessage] = useState("");
  const activeClassroom = useSelector((state) => selectActiveClassroom(state));
  const screenData = useSelector((state) => state.control.screenData);
  const dispatch = useDispatch();
  const socketDataQueue = useSelector((state) => state.control.socketDataQueue);
  const registerStudentNoty = useSelector(
    (state) => state.control.registerNoty
  );
  const socketResetTime = useSelector((state) => state.control.socketResetTime);
  const group = useSelector((state) => selectAllGroups(state));

  const history = useHistory();

  const { refetch: refetchTimetable } = useTimetableByClientId(user.clientId);

  const getTimetableData = useTimetableByClientId(user.clientId);

  const retries = useRef(0);

  const chromeExtensionId = process.env.REACT_APP_CHROME_EXTENSION_ID;

  useEffect(() => {
    if (!user.signedIn || !webSocket) {
      return;
    }

    webSocket.onopen = (event) => {
      console.log("[Connection Manager] Socket Opened", user.clientId);
      setWebSocketReady(true);
      global.eventEmitter.emit("websocketreconnected");
    };
    webSocket.onmessage = function(event) {
      setServerMessage(JSON.parse(event.data));
    };
    webSocket.onclose = function(event) {
      console.log("[Connection Manager] Socket closed", event);
      serviceSocketDisconnectSlackAlarm(
        user?.schoolId,
        user?.userName,
        user?.clientId
      );
      setWebSocketReady(false);
      dispatch(resetSocket(new Date()));
    };

    webSocket.onerror = function(err) {
      console.log(
        "[Connection Manager] Socket encountered error: ",
        err,
        "Socket will be resetted"
      );
      console.log(webSocket.readyState);
    };

    return () => {
      console.log("[Connection Manager] Connection Manager unmounted.");
      // NOTE: 리렌더될때마다 호출되어서 여기서 close하지 않겠습니다. 웹소켓 닫아지는건 브라우저 새로고침에 의존하는게 맞을거같습니다.
      //webSocket.close();
    };
  }, [user.clientId, user?.signedIn, webSocket]);

  // TODO: 신규 웹소켓 코드로 이전하기
  // useEffect(() => {
  //   const maxRetries = 10; // 최대 재시도 횟수 설정
  //   const retryInterval = 5000;

  //   const setupWebSocket = () => {
  //     if (!user || !user.signedIn || !webSocket || !user.clientId) {
  //       return;
  //     }

  //     webSocket.onopen = () => {
  //       console.log("[Connection Manager] Socket Open");
  //       setWebSocketReady(true);
  //     };
  //     webSocket.onmessage = (event) => {
  //       setServerMessage(JSON.parse(event.data));
  //     };

  //     webSocket.onclose = (event) => {
  //       console.log("[Connection Manager] Socket Close Code", event.code);
  //       if (event.code === 4000) {
  //         return;
  //       }
  //       setWebSocketReady(false);
  //       if (retries.current < maxRetries) {
  //         setTimeout(() => {
  //           try {
  //             // serviceSocketReconnectSlackAlarm(
  //             //   user?.schoolId,
  //             //   user?.userName,
  //             //   user?.clientId
  //             // );
  //             console.log("----- [Connection Manager] Socket Reconnect -----");
  //             webSocket.close(); // 이전 WebSocket을 닫음
  //             setWebSocket(
  //               new WebSocket(
  //                 `${process.env.REACT_APP_WS_DEV_ADDR}/${user.clientId}`
  //               )
  //             );
  //           } catch (error) {
  //             serviceSocketReconnectErrorSlackAlarm(
  //               error,
  //               user?.schoolId,
  //               user?.userName,
  //               user?.clientId
  //             );
  //             console.log("[Connection Manager] Socket Reconnect Error", error);
  //           } finally {
  //             console.log("----- [Connection Manager] Socket Reconnect -----");
  //           }
  //           retries.current++;
  //         }, retryInterval);
  //       } else {
  //         console.log("[Connection Manager] Maximum retries reached");
  //         alert("네트워크가 불안정합니다.");
  //         retries.current = 0;
  //         window.location.reload();
  //       }
  //     };

  //     webSocket.onerror = (error) => {
  //       // serviceSocketReconnectErrorSlackAlarm(
  //       //   error,
  //       //   user?.schoolId,
  //       //   user?.userName,
  //       //   user?.clientId
  //       // );
  //       console.log("[Connection Manager] Socket Error", error, new Date());
  //       setWebSocketReady(false);
  //       webSocket?.close();
  //     };
  //   };

  //   setupWebSocket();

  //   return () => {
  //     console.log("[Connection Manager] Socket Disconnect");
  //     webSocket?.close(4000, "reset");
  //   };
  // }, [user.clientId, user.signedIn, webSocket]);

  // useEffect(() => {
  //   if (webSocketReady && webSocket) {
  //     try {
  //       webSocket.send(JSON.stringify(socketData));
  //     } catch (e) {
  //       console.log("[Connection Manager] Socket send error", e);
  //     }
  //   }
  // }, [webSocketReady, webSocket, socketData]);

  useEffect(() => {
    console.log(
      `[Connection Manager] 웹소켓을 초기화 합니다... 웹소켓 초기화 시간: ${socketResetTime}`
    );
    if (webSocket?.readyState === WebSocket.OPEN) {
      webSocket.close(4001, "Socket reset");
    }
    setWebSocket(
      new WebSocket(`${process.env.REACT_APP_WS_DEV_ADDR}/${user.clientId}`)
    );
  }, [socketResetTime]);

  useEffect(() => {
    if (
      webSocketReady &&
      screenData &&
      Object.keys(screenData)?.length &&
      activeClassroom?.groupId
    ) {
      try {
        webSocket.send(JSON.stringify(screenData));
      } catch (e) {
        console.log("[Connection Manager] Socket send error", e);
      }
    }
  }, [screenData, webSocket, webSocketReady, activeClassroom?.groupId]);

  // pulse
  useInterval(() => {
    if (!getTimetableData.timetable.data[0]?.timetables) return;
    const timetableArray = getTimetableData.timetable.data[0]?.timetables;
    if (!activeClassroom && timetableArray.length > 0) {
      const aliveData = {
        clientId: user.clientId,
        groupId: timetableArray[0].groupId,
        method: socketMethod.POST,
        uri: socketUrl.pulse,
      };
      dispatch(getActiveClassroomPromise(user.clientId));
      try {
        webSocket?.send(JSON.stringify(aliveData));
      } catch (e) {
        setWebSocket(
          new WebSocket(`${process.env.REACT_APP_WS_DEV_ADDR}/${user.clientId}`)
        );
        console.log(
          "[Connection Manager] 웹소켓을 전송하던 도중 문제가 발생하였습니다.",
          e
        );
      }
      const diff = (new Date() - lastTimestamp) / 1000;
      const diffSecond = parseInt(diff);
      if (diffSecond >= 10 && !user.clientId) {
        setWebSocket(
          new WebSocket(`${process.env.REACT_APP_WS_DEV_ADDR}/${user.clientId}`)
        );
        setLastTimestamp(new Date());
        console.log("웹소켓을 다시 연결합니다...");
      }
    }
    if (activeClassroom) {
      const aliveData = {
        clientId: user.clientId,
        groupId: activeClassroom?.groupId,
        method: socketMethod.POST,
        uri: socketUrl.pulse,
      };
      try {
        webSocket?.send(JSON.stringify(aliveData));
      } catch (e) {
        setWebSocket(
          new WebSocket(`${process.env.REACT_APP_WS_DEV_ADDR}/${user.clientId}`)
        );
        console.log(
          "[Connection Manager] 웹소켓을 전송하던 도중 문제가 발생하였습니다.",
          e
        );
      }
      const diff = (new Date() - lastTimestamp) / 1000;
      const diffSecond = parseInt(diff);

      if (diffSecond >= 10 && !user.clientId) {
        setWebSocket(
          new WebSocket(`${process.env.REACT_APP_WS_DEV_ADDR}/${user.clientId}`)
        );
        setLastTimestamp(new Date());
        console.log("reconnet to socket");
      }
    }
    if (!serverMessage?.now && !user.clientId) {
      return;
    }
  }, 10000);

  useEffect(() => {
    console.log("Socket queue", webSocket?.readyState);
    console.log("Socket queue", socketDataQueue);
    if (webSocket?.readyState === WebSocket.OPEN) {
      if (socketDataQueue.length > 0) {
        socketDataQueue.forEach((socketData) => {
          // console.log('sending data', socketData)
          webSocket?.send(JSON.stringify(socketData?.data));
          socketData?.onSend?.();
        });
        dispatch(resetSocketDataQueue());
      }
    }
  }, [socketDataQueue, webSocket]);

  useEffect(() => {
    if (!user.signedIn || !webSocket) {
      return;
    }

    if (webSocket?.readyState === WebSocket.OPEN && group?.length !== 0) {
      if (!registerStudentNoty) {
        return;
      }
      console.log("registerStudentNoty", registerStudentNoty);
      const joinGroupData = {
        clientId: user.clientId,
        data: "",
        groupId: group[0].groupId,
        method: "POST",
        type: "JOIN_GROUP",
        uri: "/classroom/sendImage",
      };

      try {
        webSocket?.send(JSON.stringify(joinGroupData));
      } catch (e) {
        console.log("[Connection Manager] Socket send error", e);
      }
    }
  }, [user?.clientId, registerStudentNoty, group, webSocket, webSocketReady]);

  useEffect(() => {
    if (!serverMessage?.type && serverMessage?.now) {
      setLastTimestamp(new Date(serverMessage?.now));
      return;
    }
    switch (serverMessage?.type) {
      case socketKind.system.REFRESH_NOW:
        if (serverMessage.data !== user.clientId) {
          return;
        }
        if (serverMessage.data === user.clientId) {
          window.location.reload();
        }
        break;
      case socketKind.classroom.FOCUS_START:
        dispatch(setFocusTimeState(true));
        break;
      case socketKind.classroom.FOCUS_END:
        dispatch(purgeFocusTimeState());
        break;

      case "CLASS_START":
        try {
          chrome.runtime.sendMessage(chromeExtensionId, {
            type: "CLASS_START",
          });
          chrome.runtime.sendMessage(chromeExtensionId, {
            type: "GET_MONITOR_STAT",
          });
        } catch (error) {
          console.error(error);
        }
        console.log(
          "[ConnectionManager]: CLASS_START",
          serverMessage?.data,
          user?.clientId
        );

        // todo ClassManager와 중복 요청이 발생함
        dispatch(
          getParticipationPromise({
            classroomId: serverMessage?.data,
            clientId: user?.clientId,
          })
        ).then((participation) => {
          if (participation?.state === "ABSENT") {
            console.log("[Connection Manager]", "출석이 필요합니다.");
            dispatch(
              updateParticipationPromise({
                classroomId: serverMessage.data,
                clientId: user.clientId,
                state: "ATTEND",
                attendedAt: moment().toISOString(),
              })
            ).then(() => {
              dispatch(
                setSocketData({
                  method: "POST",
                  uri: "/classroom/sendImage",
                  groupId: serverMessage.groupId,
                  clientId: user.clientId,
                  type: "ATTEND_CLASS",
                  data: "",
                })
              );
            });
          }
          dispatch(getActiveClassroomPromise(user.clientId));
        });
        break;

      case "CLASS_END":
        console.log("[ConnectionManager] CLASS_END", serverMessage.data);
        dispatch(setTeacherScreen(null));
        dispatch(purgeClassroom());
        dispatch(getActiveClassroomPromise(user.clientId));
        dispatch(getActiveManagingPromise(user.clientId));
        break;

      case "CLASS_UPDATE":
        console.log("CLASS_UPDATE", serverMessage);
        dispatch(getActiveClassroomPromise(user.clientId));
        break;

      case "CLASS_DELETE":
        console.log("CLASS_DELETE", serverMessage.data);
        dispatch(purgeClient());
        dispatch(purgeGroup());
        dispatch(purgeMembership());
        dispatch(purgeTimetable());
        dispatch(getMembershipPromise(user.clientId));
        window.location.reload();
        dispatch(resetSocket(new Date()));
        break;

      case "SCREEN_SHARE_ON":
        console.log("[ConnectManager]: SCREEN_SHARE_ON");
        dispatch(getActiveClassroomPromise(user.clientId));
        break;
      case "SCREEN_SHARE_OFF":
        console.log("[ConnectManager]: SCREEN_SHARE_OFF");
        dispatch(setTeacherScreen(null));
        dispatch(getActiveClassroomPromise(user.clientId));
        break;

      case "EDIT_TIMETABLE":
        console.log("[ConnectManager]: EDIT_TIMETABLE", serverMessage.data);
        refetchTimetable();
        dispatch(getGroup(serverMessage?.groupId));
        break;

      case "MANAGING_START":
        console.log(
          "[ConnectManager]: MANAGING_START",
          serverMessage?.data,
          user?.clientId
        );

        dispatch(
          createManagedPromise({
            managingId: serverMessage?.data,
            clientId: user.clientId,
            attendedAt: new Date().toISOString(),
          })
        );
        dispatch(
          getManagedPromise({
            managingId: serverMessage?.data,
            clientId: user?.clientId,
          })
        ).then((managed) => {
          if (managed?.state === "ABSENT") {
            console.log("[Connection Manager]", "출석 필요!");
            dispatch(
              updateManagedPromise({
                managingId: serverMessage.data,
                clientId: user.clientId,
                state: "ATTEND",
                attendedAt: moment().toISOString(),
              })
            ).then(() => {});
          }
          try {
            dispatch(getActiveManagingPromise(user.clientId));
            chrome.runtime.sendMessage(chromeExtensionId, {
              type: "MANAGING_START",
            });
          } catch (error) {
            console.error(error);
          }
        });

        dispatch(
          getManagingsByGroupPromise({ groupId: serverMessage.groupId })
        ).then((res) => {
          res.managings.map((managing) => {
            if (managing.state === "IN_MANAGING")
              dispatch(getGroupProgramconfigsPromise(managing.managingId));
            return;
          });
        });
        break;

      case "MANAGING_END":
        console.log("[ConnectionManager]: MANAGING_END", serverMessage.data);
        try {
          chrome.runtime.sendMessage(chromeExtensionId, {
            type: "MANAGING_END",
          });
        } catch (error) {
          console.error(error);
        }

        dispatch(getActiveManagingPromise(user.clientId));

        break;

      case "MANAGING_UPDATE":
        console.log("[ConnectManager]: MANAGING_UPDATE", serverMessage.data);
        dispatch(getActiveManagingPromise(user.clientId));
        dispatch(getManagingsByGroupPromise(serverMessage.groupId));
        dispatch(getGroupProgramconfigsPromise(serverMessage.groupId));
        break;

      case "MANAGING_DELETE":
        console.log("[ConnectManager]: MANAGING_DELETE", serverMessage.data);
        dispatch(purgeClassroom());
        dispatch(purgeClient());
        dispatch(purgeGroup());
        dispatch(purgeManaging());
        dispatch(purgeMembership());
        dispatch(getMembershipPromise(user.clientId));
        if (!activeClassroom) {
          history.push("/home");
        }
        break;

      case "SELECT_STUDENT":
        console.log("SELECT_STUDENT", serverMessage.data);
        dispatch(setSelectedStudent(serverMessage.data));
        break;

      case "IMAGE_TEACHER":
        console.log("IMAGE_TEACHER", serverMessage.data);
        dispatch(setTeacherScreen(serverMessage.data));
        break;

      case "CREATE_FILELINK":
        console.log("FILELINK COME", serverMessage.groupId);
        //dispatch(getGroupFilelinkPromise(serverMessage.groupId));
        global.eventEmitter.emit("filelinkchanged");
        break;

      case "DELETE_FILELINK":
        console.log("FILELINK COME", serverMessage.groupId);
        // dispatch(deleteFilelinkSuccess(serverMessage.data));
        // dispatch(getGroupFilelinkPromise(serverMessage.groupId));
        global.eventEmitter.emit("filelinkchanged");
        break;

      case "CREATE_PROGRAMCONFIG":
        console.log(
          "PROGRAMCONFIG CREATE",
          serverMessage.groupId,
          serverMessage.data
        );
        // dispatch(getGroupProgramconfigsPromise(serverMessage.groupId));
        global.eventEmitter.emit("programconfigchanged");
        break;
      case "UPDATE_PROGRAMCONFIG":
        console.log(
          "PROGRAMCONFIG UPDATE",
          serverMessage.groupId,
          serverMessage.data
        );
        // dispatch(getGroupProgramconfigsPromise(serverMessage.groupId));
        global.eventEmitter.emit("programconfigchanged");
        break;
      case "DELETE_PROGRAMCONFIG":
        console.log("PROGRAMCONFIG COME", serverMessage.groupId);
        // dispatch(getGroupProgramconfigsPromise(serverMessage.groupId));
        // dispatch(deleteProgramconfigSuccess(serverMessage.data));
        global.eventEmitter.emit("programconfigchanged");
        break;

      case "CREATE_MANAGING_PROGRAMCONFIG":
        console.log(
          "[ConnectionManager]: CREATE_MANAGING_PROGRAMCONFIG",
          serverMessage.groupId,
          serverMessage.data
        );
        dispatch(getGroupProgramconfigsPromise(serverMessage.data));
        break;

      case "UPDATE_MANAGING_PROGRAMCONFIG":
        console.log(
          "[ConnectionManager]: UPDATE_MANAGING_PROGRAMCONFIG",
          serverMessage.data
        );
        dispatch(getGroupProgramconfigsPromise(serverMessage.data));

        break;

      case "DELETE_MANAGING_PROGRAMCONFIG":
        console.log(
          "[ConnectionManager]: DELETE_MANAGING_PROGRAMCONFIG",
          serverMessage.data
        );
        // dispatch(getGroupProgramconfigsPromise(serverMessage.data));
        dispatch(deleteProgramconfigSuccess(serverMessage.data));
        break;

      case "KICK_STUDENT":
        if (serverMessage.data === user.clientId) {
          dispatch(purgeClient());
          dispatch(purgeGroup());
          dispatch(purgeMembership());
          dispatch(purgeTimetable());
          dispatch(purgeParticipation());
          dispatch(getMembershipPromise(user.clientId));
          // dispatch(deleteGroupSuccess(serverMessage.groupId));
          // dispatch(deleteMembershipSuccess(user.clientId));
          window.location.reload();
          dispatch(resetSocket(new Date()));
        }
        break;
      case "UPDATE_STUDENT":
        dispatch(getUserPromise(user.clientId));
        break;
      case "IMAGE_STUDENT":
        dispatch(
          setStudentImages({
            clientId: serverMessage.clientId,
            image: serverMessage.data,
          })
        );
        break;

      case "JOIN_GROUP":
        dispatch(registerNoty(false));
        break;

      // case 'QUIZ_BE_READY':
      //   console.log('[ConnectionManager]', 'quiz be ready: ' + serverMessage.data);
      //   history.replace(`/quizpang/quiz-ready-calib/${serverMessage.groupId}/${serverMessage.data}`);
      //   break;

      // case 'QUIZ_START':
      //   history.replace(`/inquizpang/ready/${serverMessage.data}`);
      // {
      //   const isQuizGettingReady = location.pathname.includes('quizpang/before')
      //   if (isQuizGettingReady) {
      //     global.eventEmitter.once('QUIZ_READY', navigate);
      //   } else {
      //     navigate();
      //   }
      // }
      // break;

      // case 'QUIZ_NEXT':
      //   {
      //     const [quizsetSessionId, quizIndex] = serverMessage.data.split(',');
      //     history.replace(`/inquizpang/session/${quizsetSessionId}/${quizIndex}`)
      //   }
      //   break;

      // case 'QUIZ_SUMMARY':
      //   {
      //     const [quizsetSessionId, quizIndex] = serverMessage.data.split(',');
      //     history.replace(`/quizpang/result/${quizsetSessionId}/${quizIndex}`);
      //   }
      //   break;

      // 퀴즈팡
      // 1204 주석처리
      // case socketKind.quizpang.QUIZSET_SESSION_ACTIVE_CONFIRM:
      //   if (JSON.parse(serverMessage.data).targetClient === user.clientId) {
      //     if (!/quizpang\/.*\/wait/g.test(window.location.pathname)) {
      //       history.replace(`/quizpang/${serverMessage.groupId}/wait`);
      //     }
      //   }
      //   break;

      // case socketKind.quizpang.QUIZSET_READY:
      //   dispatch(setTeacherClientId(serverMessage.clientId));
      //   dispatch(setSelectedClass(serverMessage.groupId));
      //   // history.replace(`/quizpang/${serverMessage.groupId}/posture`);
      //   history.replace(
      //     `/quizpang/${serverMessage.groupId}/ready/${
      //       JSON.parse(serverMessage.data).quizsetId
      //     }`
      //   );
      //   break;
      case socketKind.quizpang.QUIZSET_START:
        console.log(
          "[ConnectionManager]: QUIZSET_START",
          serverMessage.groupId
        );
        dispatch(setSelectedClass(serverMessage.groupId));
        history.replace(
          `/quizpang/${serverMessage.groupId}/session/${
            JSON.parse(serverMessage.data).quizsetSessionId
          }`
        );
        break;

      case socketKind.quizpang.QUIZ_NEXT_START:
        console.log(
          "[ConnectionManager]: QUIZ_NEXT_START",
          serverMessage.groupId
        );
        history.replace(
          `/quizpang/${serverMessage.groupId}/session/${
            JSON.parse(serverMessage.data).quizsetSessionId
          }`
        );
        break;

      case socketKind.survey.SURVEYSET_START:
        console.log(
          "[ConnectionManager]: SURVEYSET_START",
          serverMessage.groupId
        );
        history.replace(`/survey`);
        break;

      case socketKind.quizpang.QUIZSET_COMPLETED:
        console.log(
          "[ConnectionManager]: QUIZSET_COMPLETED",
          serverMessage.groupId
        );
        history.replace(`/quizpang/${serverMessage.groupId}?tabKey=quiz`);
        break;

      // case 'SURVEY_START':
      //   {
      //     const [quizsetSessionId] = serverMessage.data.split(',');
      //     history.replace(`/survey/${quizsetSessionId}`);
      //   }
      //   break;

      case "UPDATE_FORBID_LIST":
        try {
          chrome.runtime.sendMessage(chromeExtensionId, {
            type: "UPDATE_FORBID_LIST",
            data: serverMessage.data,
          });
        } catch (error) {
          console.error(error);
        }
        break;

      case "COMMENTS_LAYER_START":
        dispatch(setCommentsLayerActive(true));
        break;
      case "COMMENTS_LAYER_END":
        dispatch(setCommentsLayerActive(false));
        break;
      case "COMMENTS_TEACHER":
        const data = JSON.parse(serverMessage.data) || {};
        dispatch(setCommentsTarget(data?.targetClients || []));
        dispatch(setCommentsTeacher(data?.image || ""));
        break;
      case "COMMENTS_STUDENT":
        dispatch(
          setCommentsStudent({
            image: serverMessage.data,
            clientId: serverMessage.clientId,
          })
        );
        break;
      case "COMMENTS_SHARED_SCREEN":
        dispatch(setCommentsSharedScreen(serverMessage.data));
        break;
      case "SURVEY_START":
        console.log("[ConnectionManager] SURVEY_START", serverMessage.data);
        if (serverMessage?.groupId) {
          dispatch(setReceviedGroupId(serverMessage.groupId));
        }
        dispatch(setSurveyListIds(serverMessage.data));
        setTimeout(() => {
          history.replace(`/survey/${serverMessage.groupId}`);
        }, 2000);
        break;
      case "SURVEY_END":
        console.log("[ConnectionManager] SURVEY_END");
        dispatch(purgeSurveyListIds());
        history.push("/home");
        window.location.reload();
        break;
      default:
    }
  }, [serverMessage, dispatch, user.clientId]);

  return <div />;
};

export default ConnectionManager;
