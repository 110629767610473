import React, { useEffect, useState } from 'react';

import { ReactComponent as ListImg } from '@images/icons/list.svg';
import { Body, Btn, Container, Contents, ContentsBody, ContentsHeader, DateContainer, Header, HeaderMenu, HeaderMenuContainer, HeaderMenuTitle, HeaderTitle, Class } from './index.module';

import Sidebar from '@components/Layout/Sidebar';
import AttendanceList from './AttendanceList';
import AttendanceDetail from './AttendanceDetail';
import AttendanceCalendar from './AttendanceCalendar';
import AttendanceTodayState from './AttendanceTodayState';
import AttendanceListDatePicker from './AttendanceListDatePicker';
import AttendanceCalendarDatePicker from './AttendanceCalendarDatePicker';

const Attendance = () => {
  const [year, setYear] = useState(new Date().getFullYear())
  const [month, setMonth] = useState(new Date().getMonth() + 1)
  const [grade, setGrade] = useState(3)
  const [_class, setClass] = useState(5)
  const [calendar, setCalendar] = useState([])
  const [isShowChooseClass, setIsShowChooseClass] = useState(false)
  const [viewType, setViewType] = useState('calendar') // calendar, list
  const [selectedDate, setSelectedDate] = useState({}) // {year, month, date}

  // 한달 전
  const [fromDate, setFromDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1))
  const [toDate, setToDate] = useState(new Date())

  useEffect(() => {
    const firstDay = new Date(year, month - 1, 1).getDay()
    const lastDate = new Date(year, month, 0).getDate()
    const lastDay = new Date(year, month - 1, lastDate).getDay()

    const prevDates = []
    const currentDates = []
    const nextDates = []

    if (firstDay !== 0) {
      for (let i = 0; i < firstDay; i++) {
        prevDates.unshift(new Date(year, month - 1, -i).getDate())
      }
    }

    for (let i = 1; i <= lastDate; i++) {
      currentDates.push(i)
    }

    if (lastDay !== 6) {
      for (let i = 1; i <= 6 - lastDay; i++) {
        nextDates.push(i)
      }
    }

    setCalendar([...prevDates, ...currentDates, ...nextDates])
  }, [year, month])

  return (
    <>
      <Sidebar />
      <Container>
        <Header>
          <HeaderTitle>출석부</HeaderTitle>
        </Header>
        <Body>
            <Contents>
              {/* 금일 출석 상태 */}
              <AttendanceTodayState />

              {/* 상세 보기 */}
              {
                selectedDate.year && selectedDate.month && selectedDate.date ? (
                  <AttendanceDetail grade={grade}
                                    _class={_class}
                                    year={selectedDate.year}
                                    month={selectedDate.month}
                                    date={selectedDate.date}
                                    setDate={(d) => {
                                      setSelectedDate({
                                        ...selectedDate,
                                        year: (() => {
                                          if (d === -1 && selectedDate.date === 1 && selectedDate.month === 1) return selectedDate.year - 1;
                                          else if (d === 1 && selectedDate.date === new Date(selectedDate.year, selectedDate.month, 0).getDate() && selectedDate.month === 12) return selectedDate.year + 1;
                                          else return selectedDate.year;
                                        })(),
                                        month: (() => {
                                          if (d === -1 && selectedDate.date === 1) return selectedDate.month - 1;
                                          else if (d === 1 && selectedDate.date === new Date(selectedDate.year, selectedDate.month, 0).getDate()) return selectedDate.month + 1;
                                          else return selectedDate.month;
                                        })(),
                                        date: (() => {
                                          if (d === -1 && selectedDate.date === 1) return new Date(selectedDate.year, selectedDate.month - 1, 0).getDate();
                                          else if (d === 1 && selectedDate.date === new Date(selectedDate.year, selectedDate.month, 0).getDate()) return 1;
                                          else return selectedDate.date + d;
                                        })()
                                      })
                                    }}
                                    onShowChangeAttendanceState={() => { }}
                                    back={() => { setSelectedDate({}) }}
                  />
                ) : (
                  <>
                    <ContentsHeader>
                      <DateContainer>
                        {
                          viewType === 'calendar' ? (
                            <AttendanceCalendarDatePicker year={year} month={month} setYear={setYear} setMonth={setMonth} />
                          ) : viewType === 'list' ? (
                            <AttendanceListDatePicker fromDate={fromDate} toDate={toDate} setYear={(year, type) => {
                              if (type === 'from') {
                                setFromDate(new Date(year, fromDate.getMonth(), fromDate.getDate()))
                              } else if (type === 'to') {
                                setToDate(new Date(year, toDate.getMonth(), toDate.getDate()))
                              }
                            }} setMonth={(month, type) => {
                              if (type === 'from') {
                                setFromDate(new Date(fromDate.getFullYear(), month - 1, fromDate.getDate()))
                              } else if (type === 'to') {
                                setToDate(new Date(toDate.getFullYear(), month - 1, toDate.getDate()))
                              }
                            }} />
                          ) : null
                        }
                      </DateContainer>
                      <Btn style={{ position: 'absolute', right: 0 }} onClick={() => { setViewType(viewType === 'calendar' ? 'list' : 'calendar') }}>
                        <ListImg />
                        { viewType === 'calendar' ? '리스트로 보기' : '캘린더로 보기' }
                      </Btn>
                    </ContentsHeader>
                    <ContentsBody>
                      {
                        viewType === 'calendar' ? (
                          <AttendanceCalendar year={year} month={month} calendar={calendar} selectDate={({ year, month, date }) => { setSelectedDate({ year, month, date }) }} />
                        ) : viewType === 'list' ? (
                          <AttendanceList fromDate={fromDate} toDate={toDate} selectDate={({ year, month, date }) => { setSelectedDate({ year, month, date }) }} />
                        ) : null
                      }
                    </ContentsBody>
                  </>
                )
              }
            </Contents>
          </Body>
      </Container>
    </>
  );
};

export default Attendance;
