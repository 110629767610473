import { getUserAgent } from '@pages/QuizPang/utils';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SurveySection } from '@components/Survey/SurveySection';
import { TimeChoices } from '@components/Survey/TimeChoices';
import { StylusChoices } from '@components/Survey/StylusChoices';
import { ShortChoices } from '@components/Survey/ShortChoices';
import { RadioChoices } from '@components/Survey/RadioForm';
import { Button, CircularProgress, Container, Stack } from '@mui/material';
import QuizEyetrackingLogger from '@pages/QuizPang/quiz-eyetracking-logger';
import QuizGPSLogger from '@pages/QuizPang/quiz-gps-logger-buffer';
import QuizAccelGyroLogger from '@pages/QuizPang/quiz-accel-gyro-logger-buffer';
import QuizAudioLogger from '@pages/QuizPang/quiz-audio-logger';
import ArchiveModule from '@pages/QuizPang/data-archive';
import QuizTouchLoggerContainer from '@pages/QuizPang/quiz-touch-logger-container';
import { useCreateSurveyResponse } from '@pages/QuizPang/hooks/useSurvey';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { postBackend } from '@api';
import { purgeSurveyresponse } from '@store/actions';
import { postQuisResponseList } from '@api/quizpang/quizpangApi';
import { TrueFalseChoices } from '@components/Survey/TrueFalseChoices';
import { LongChoices } from '@components/Survey/LongChoices';
import { LoadingButton } from '@mui/lab';
import { processSurveyResponse } from './surveyLogic';
import { QueryClient, useMutation, useQuery } from '@tanstack/react-query';
import { createArchive, updateCompletedArchive } from '@api/archive/archiveApi';
import axios from 'axios';

export const SurveySession = ({
  groupId,
  quizsetId,
  quizsetSessionId,
  quizsetData,
  quizCount,
  index,
  setIndex,
  quizsetSessionIdsLength,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const sessionDeviceType = getUserAgent();
  const history = useHistory();
  const [sessionDeviceInfo, setSessionDeviceInfo] = useState('');

  const [quizCurrentIndex, setQuizCurrentIndex] = useState(0);
  const [prevQuizCurrentIndex, setPrevQuizCurrentIndex] = useState(0);

  const [submit, setSubmit] = useState(false);

  //logger state
  const [isStartRecording, setIsStartRecording] = useState(true);

  // logger date state
  const [gpsData, setGpsData] = useState([]);
  const [accelgyroData, setAceelgyroData] = useState([]);
  const [sessionTouchData, setSessionTouchData] = useState([]);
  const [sessionKeyboardData, setSessionKeyboardData] = useState([]);
  const [sessionStylusData, setSessionStylusData] = useState([]);
  const [voiceData, setVoiceData] = useState([]);
  const [eyetrackingData, setEyeTrackingData] = useState([]);

  // logger quiz start, end state
  const [quizStart, setQuizStart] = useState(null);
  const [quizEnd, setQuizEnd] = useState(null);

  // logger data upload state
  const [archiveDataUpload, setArchiveDataUpload] = useState(false);

  // uploading state
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    if (quizCurrentIndex === 0) {
      setQuizStart(new Date().toISOString());
    }

    if (quizCurrentIndex !== prevQuizCurrentIndex) {
      setQuizStart(new Date().toISOString());
    }
  }, [quizCurrentIndex, prevQuizCurrentIndex]);

  // survey 스토어에 있는 설문 응답 데이터 가져오기
  const surveyResponse = useSelector((state) => state.survey.quizResponse);

  // 리덕스 업로드 트리거
  const [upload, setUpload] = useState(0);

  const sessionType = 'SURVEY';

  const containerRef = useRef(null);

  useEffect(() => {
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    const deviceInfo = `${screenWidth}x${screenHeight}`;
    setSessionDeviceInfo(deviceInfo);
  }, []);

  // quizCoun가 있을 경우 quizCount를 사용하고, 없을 경우 quizsetData.quizzes를 사용한다
  const quizsetTotalPage = quizCount || JSON.parse(quizsetData.quizzes)?.length;

  // 설문 응답 데이터를 서버에 전송
  const handleSubmit = async () => {
    const surveyResponseData = processSurveyResponse(
      surveyResponse,
      quizsetSessionId,
      user
    );
    const response = await postQuisResponseList(surveyResponseData);

    if (response.status === 200) {
      if (quizsetSessionIdsLength - 1 === index) {
        alert('제출이 완료되었습니다!');
        history.push(`/quizpang/${groupId}`);
      } else {
        setIndex(index + 1);
        dispatch(purgeSurveyresponse());
      }
    }
  };

  // 변경된 로직
  // 1. useState에 있는 데이터를 가져온다.
  // 3. 아카이브에 먼저 clientId, quizId, domainType, quizStart, quizEnd를 보낸다
  // 3. 아카이브에서 성공적으로 보냈을 경우 response에 presignedUrl이 담겨온다
  // 4. quizStart와 quizEnd를 기준으로 데이터를 자른다
  // 5. presignedUrl에 데이터를 보낸다 다만 headers에 Content-Type 'text/plain'을 추가해야한다
  // 6. 데이터를 보낸 후에는 스토어에 저장된 데이터를 삭제한다

  // 파라미터 셋팅
  const params = {
    clientId: user.clientId,
    context: 'quizpang',
    contextId: JSON.parse(quizsetData.quizzes)[quizCurrentIndex]?.quizId,
    startedAt: quizStart,
    os: sessionDeviceType,
  };

  const sendToArchive = async (domain) => {
    const response = await createArchive({
      ...params,
      // domain이 객체일 경우 domain.domain으로 접근
      domain: domain.domain || domain,
      // endedAt이 객체일 경우 domain.endedAt으로 접근
      endedAt: domain.endedAt || quizEnd,
    });
    return {
      presignedUrl: response.data.presignedUrl,
      archiveId: response.data.archiveId,
    };
  };

  const sendToPresignedUrl = async (url, data) => {
    await axios.put(url, data, {
      headers: {
        'Content-Type': 'text/plain',
      },
    });
  };

  const patchToArchive = async (archiveId) => {
    const response = await updateCompletedArchive({
      archiveId: archiveId,
    });
  };
  const archiveMutation = useMutation(sendToArchive, {
    onSuccess: async ({ presignedUrl, archiveId }, variables) => {
      // 4. quizStart와 quizEnd를 기준으로 데이터를 자른다. 아직 안잘름
      const processedData = variables.data;
      const domain = variables.domain;
      console.log('processedData', domain, processedData);
      // 5. presignedUrl에 데이터를 보낸다
      await sendToPresignedUrl(presignedUrl, processedData);
      console.log('presignedUrl', presignedUrl);
      console.log('archiveId', archiveId);
      await patchToArchive(archiveId);
      // 6. 데이터를 보낸 후에는 데이터를 삭제한다
      switch (variables.domain) {
        case 'gps':
          setGpsData(null);
          break;
        case 'accel-gyro':
          setAceelgyroData([]);
          break;
        case 'touch':
          setSessionTouchData([]);
          break;
        case 'keyboard':
          setSessionKeyboardData([]);
          break;
        case 'stylus':
          setSessionStylusData([]);
          break;
        case 'voice':
          setVoiceData([]);
          break;
        case 'eye-tracking':
          setEyeTrackingData([]);
          break;
        default:
          break;
      }
    },
  });

  const sendDataToArchive = async (domain, data, endedAt) => {
    setIsUploading(true);
    await archiveMutation.mutateAsync({ domain, data, endedAt });
    setIsUploading(false);
  };

  const dataMappings = [
    { domain: 'gps', data: gpsData, endedAt: quizEnd },
    { domain: 'accel-gyro', data: accelgyroData, endedAt: quizEnd },
    { domain: 'touch', data: sessionTouchData, endedAt: quizEnd },
    { domain: 'keyboard', data: sessionKeyboardData, endedAt: quizEnd },
    { domain: 'stylus', data: sessionStylusData, endedAt: quizEnd },
    { domain: 'voice', data: voiceData, endedAt: quizEnd },
    { domain: 'eye-tracking', data: eyetrackingData, endedAt: quizEnd },
  ];

  const handleNextQuestionClick = async () => {
    // 1. 현재 시간을 quizEnd에 저장한다
    const currentQuizEnd = new Date().toISOString();
    setQuizEnd(currentQuizEnd);

    // 2. 로딩 UI 상태를 true로 변경한다
    setArchiveDataUpload(true);
    setUpload((prev) => prev + 1);

    // Wait for the data to be uploaded
    await Promise.all(
      dataMappings.map((mapping) => {
        if (mapping.data) {
          return sendDataToArchive(
            mapping.domain,
            mapping.data,
            currentQuizEnd
          );
        }
        return null;
      })
    );

    // Once data is uploaded and not in uploading state, transition to the next question
    if (!isUploading) {
      setQuizCurrentIndex(quizCurrentIndex + 1);
      setPrevQuizCurrentIndex(quizCurrentIndex);
      setArchiveDataUpload(false);
    }
  };

  // 마지막 응답 제출 시 설문 응답 데이터를 서버에 전송 후 스토어 데이터 초기화 동기적으로 처리
  const submitAll = async () => {
    // 1. 현재 시간을 quizEnd에 저장한다
    const currentQuizEnd = new Date().toISOString();
    setQuizEnd(currentQuizEnd);

    // 2. 로딩 UI 상태를 true로 변경한다
    setArchiveDataUpload(true);
    setUpload((prev) => prev + 1);

    // Wait for the data to be uploaded
    await Promise.all(
      dataMappings.map((mapping) => {
        if (mapping.data) {
          return sendDataToArchive(
            mapping.domain,
            mapping.data,
            currentQuizEnd
          );
        }
        return null;
      })
    );

    if (!isUploading) {
      setArchiveDataUpload(false);
      handleSubmit();
      history.push(`/quizpang/${groupId}`);
    }
  };

  return (
    <QuizEyetrackingLogger
      name="QuizEyetrackingLogger"
      quizType={JSON.parse(quizsetData.quizzes)[quizCurrentIndex]?.quizType}
      quizIndex={quizCurrentIndex}
      eyetrackingData={eyetrackingData}
      setEyeTrackingData={setEyeTrackingData}
    >
      <QuizGPSLogger
        name="QuizGPSLogger"
        quizType={JSON.parse(quizsetData.quizzes)[quizCurrentIndex]?.quizType}
        onGpsData={setGpsData}
        gpsData={gpsData}
      >
        <QuizAccelGyroLogger
          name="QuizAccelGyroLogger"
          quizType={JSON.parse(quizsetData.quizzes)[quizCurrentIndex]?.quizType}
          onAccelgyroData={setAceelgyroData}
        >
          <QuizTouchLoggerContainer
            name="QuizTouchLoggerContainer"
            myRef={containerRef}
            touchData={sessionTouchData}
            setTouchData={setSessionTouchData}
            quizType={
              JSON.parse(quizsetData.quizzes)[quizCurrentIndex]?.quizType
            }
            deviceType={sessionDeviceType}
          >
            <QuizAudioLogger
              name="QuizAudioLogger"
              quizType={
                JSON.parse(quizsetData.quizzes)[quizCurrentIndex]?.quizType ||
                null
              }
              isStartRecording={isStartRecording}
              onVoiceData={setVoiceData}
              clientId={user.clientId}
              quizId={JSON.parse(quizsetData.quizzes)[quizCurrentIndex]?.quizId}
              upload={upload}
            >
              <Container
                maxWidth="lg"
                sx={{
                  width: '100%',
                  height: '100%',
                  // 스크롤 가능하게
                  overflow: 'auto',
                  // 스크롤바 숨기기
                  '&::-webkit-scrollbar': {
                    display: 'none',
                  },
                  mt: '1rem',
                }}
              >
                <div>
                  <div className="flex justify-end items-center">
                    {quizCurrentIndex < quizsetTotalPage ? (
                      <Button
                        variant="contained"
                        onClick={() => {
                          handleNextQuestionClick();
                        }}
                      >
                        {archiveDataUpload ? (
                          <CircularProgress size={24} color="inherit" />
                        ) : (
                          '다음 문항'
                        )}
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        onClick={() => {
                          submitAll();
                        }}
                      >
                        제출하기
                      </Button>
                    )}
                  </div>
                  <Stack
                    direction="row"
                    spacing={2}
                    sx={{
                      mb: '1rem',
                    }}
                  >
                    <div>현재 페이지:{quizCurrentIndex + 1}</div>
                    <div>
                      남은 페이지:
                      {quizsetTotalPage
                        ? quizsetTotalPage - quizCurrentIndex
                        : 0}
                    </div>
                    <div>총 페이지:{quizsetTotalPage + 1}</div>
                  </Stack>
                  <div>
                    {JSON.stringify(
                      JSON.parse(quizsetData.quizzes)[quizCurrentIndex]?.choices
                    )}
                  </div>
                  {JSON.parse(quizsetData.quizzes)[quizCurrentIndex]
                    ?.quizType === 'RADIO' ? (
                    <RadioChoices
                      title={
                        JSON.parse(quizsetData.quizzes)[quizCurrentIndex]?.title
                      }
                      quizId={
                        JSON.parse(quizsetData.quizzes)[quizCurrentIndex]
                          ?.quizId
                      }
                      link={
                        JSON.parse(quizsetData.quizzes)[quizCurrentIndex]?.link
                      }
                      quizType={
                        JSON.parse(quizsetData.quizzes)[quizCurrentIndex]
                          ?.quizType
                      }
                      submit={submit}
                      handleSubmit={handleSubmit}
                      quizCurrentIndex={quizCurrentIndex}
                      key={quizCurrentIndex}
                      upload={upload}
                    />
                  ) : JSON.parse(quizsetData.quizzes)[quizCurrentIndex]
                      ?.quizType === 'OX' ? (
                    <TrueFalseChoices
                      title={
                        JSON.parse(quizsetData.quizzes)[quizCurrentIndex]?.title
                      }
                      quizId={
                        JSON.parse(quizsetData.quizzes)[quizCurrentIndex]
                          ?.quizId
                      }
                      link={
                        JSON.parse(quizsetData.quizzes)[quizCurrentIndex]?.link
                      }
                      quizType={
                        JSON.parse(quizsetData.quizzes)[quizCurrentIndex]
                          ?.quizType
                      }
                      submit={submit}
                      handleSubmit={handleSubmit}
                      quizCurrentIndex={quizCurrentIndex}
                      key={quizCurrentIndex}
                      upload={upload}
                    />
                  ) : JSON.parse(quizsetData.quizzes)[quizCurrentIndex]
                      ?.quizType === 'MULTIPLE' ? (
                    <TrueFalseChoices
                      title={
                        JSON.parse(quizsetData.quizzes)[quizCurrentIndex]?.title
                      }
                      quizId={
                        JSON.parse(quizsetData.quizzes)[quizCurrentIndex]
                          ?.quizId
                      }
                      link={
                        JSON.parse(quizsetData.quizzes)[quizCurrentIndex]?.link
                      }
                      quizType={
                        JSON.parse(quizsetData.quizzes)[quizCurrentIndex]
                          ?.quizType
                      }
                      submit={submit}
                      handleSubmit={handleSubmit}
                      quizCurrentIndex={quizCurrentIndex}
                      key={quizCurrentIndex}
                      upload={upload}
                    />
                  ) : JSON.parse(quizsetData.quizzes)[quizCurrentIndex]
                      ?.quizType === 'SECTION' ||
                    JSON.parse(quizsetData.quizzes)[quizCurrentIndex]
                      ?.quizType === 'HEADLINE' ? (
                    <SurveySection
                      title={
                        JSON.parse(quizsetData.quizzes)[quizCurrentIndex]?.title
                      }
                    />
                  ) : JSON.parse(quizsetData.quizzes)[quizCurrentIndex]
                      ?.quizType === 'TIME' ? (
                    <TimeChoices
                      title={
                        JSON.parse(quizsetData.quizzes)[quizCurrentIndex]?.title
                      }
                      quizId={
                        JSON.parse(quizsetData.quizzes)[quizCurrentIndex]
                          ?.quizId
                      }
                      link={
                        JSON.parse(quizsetData.quizzes)[quizCurrentIndex]?.link
                      }
                      quizType={
                        JSON.parse(quizsetData.quizzes)[quizCurrentIndex]
                          ?.quizType
                      }
                      submit={submit}
                      handleSubmit={handleSubmit}
                      quizCurrentIndex={quizCurrentIndex}
                      key={quizCurrentIndex}
                      upload={upload}
                    />
                  ) : JSON.parse(quizsetData.quizzes)[quizCurrentIndex]
                      ?.quizType === 'STYLUS' ? (
                    <StylusChoices
                      title={
                        JSON.parse(quizsetData.quizzes)[quizCurrentIndex]?.title
                      }
                      quizId={
                        JSON.parse(quizsetData.quizzes)[quizCurrentIndex]
                          ?.quizId
                      }
                      link={
                        JSON.parse(quizsetData.quizzes)[quizCurrentIndex]?.link
                      }
                      quizType={
                        JSON.parse(quizsetData.quizzes)[quizCurrentIndex]
                          ?.quizType
                      }
                      // stylusImage={stylusImage}
                      // setStylusImage={setStylusImage}
                      clientId={user.clientId}
                      quizsetSessionId={quizsetSessionId}
                      stylusData={sessionStylusData}
                      setStylusData={setSessionStylusData}
                      containerRef={containerRef}
                      deviceType={sessionDeviceType}
                      submit={submit}
                      handleSubmit={handleSubmit}
                      quizCurrentIndex={quizCurrentIndex}
                      key={quizCurrentIndex}
                      upload={upload}
                    />
                  ) : JSON.parse(quizsetData.quizzes)[quizCurrentIndex]
                      ?.quizType === 'LONG' ? (
                    <LongChoices
                      title={
                        JSON.parse(quizsetData.quizzes)[quizCurrentIndex]?.title
                      }
                      quizId={
                        JSON.parse(quizsetData.quizzes)[quizCurrentIndex]
                          ?.quizId
                      }
                      link={
                        JSON.parse(quizsetData.quizzes)[quizCurrentIndex]?.link
                        // uniqueSrc
                      }
                      quizType={
                        JSON.parse(quizsetData.quizzes)[quizCurrentIndex]
                          ?.quizType
                      }
                      keyboardData={sessionKeyboardData}
                      setKeyboardData={setSessionKeyboardData}
                      deviceType={sessionDeviceType}
                      submit={submit}
                      handleSubmit={handleSubmit}
                      quizCurrentIndex={quizCurrentIndex}
                      key={quizCurrentIndex}
                      upload={upload}
                      sessionType={sessionType}
                    />
                  ) : JSON.parse(quizsetData.quizzes)[quizCurrentIndex]
                      ?.quizType === 'SHORT' ? (
                    <ShortChoices
                      title={
                        JSON.parse(quizsetData.quizzes)[quizCurrentIndex]?.title
                      }
                      quizId={
                        JSON.parse(quizsetData.quizzes)[quizCurrentIndex]
                          ?.quizId
                      }
                      link={
                        JSON.parse(quizsetData.quizzes)[quizCurrentIndex]?.link
                      }
                      quizType={
                        JSON.parse(quizsetData.quizzes)[quizCurrentIndex]
                          ?.quizType
                      }
                      keyboardData={sessionKeyboardData}
                      setKeyboardData={setSessionKeyboardData}
                      deviceType={sessionDeviceType}
                      submit={submit}
                      handleSubmit={handleSubmit}
                      quizCurrentIndex={quizCurrentIndex}
                      key={quizCurrentIndex}
                      upload={upload}
                    />
                  ) : JSON.parse(quizsetData.quizzes)[quizCurrentIndex]
                      ?.subject === 'PQ-16' &&
                    JSON.parse(quizsetData.quizzes)[quizCurrentIndex]
                      ?.quizType === 'RADIO' ? (
                    <RadioChoices
                      title={
                        JSON.parse(quizsetData.quizzes)[quizCurrentIndex]?.title
                      }
                      quizId={
                        JSON.parse(quizsetData.quizzes)[quizCurrentIndex]
                          ?.quizId
                      }
                      link={
                        JSON.parse(quizsetData.quizzes)[quizCurrentIndex]?.link
                      }
                      quizType={
                        JSON.parse(quizsetData.quizzes)[quizCurrentIndex]
                          ?.quizType
                      }
                      submit={submit}
                      handleSubmit={handleSubmit}
                      quizCurrentIndex={quizCurrentIndex}
                      key={quizCurrentIndex}
                      upload={upload}
                    />
                  ) : (
                    <div>문항 유형이 없습니다.</div>
                  )}
                </div>
              </Container>
            </QuizAudioLogger>
          </QuizTouchLoggerContainer>
        </QuizAccelGyroLogger>
      </QuizGPSLogger>
    </QuizEyetrackingLogger>
  );
};
