import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import postureImg from 'assets/images/posture/caliImage.png';
import { Button } from '@mui/material';
import QuizReadyCalib from './quiz-ready-calib';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import QuizEyetrackerInit from './quiz-eyetracker-init';
import {
  notificationKind,
  useSystemNotification,
} from 'hooks/useSystemNotification';

export const QuizPostureCheckPage = () => {
  const { onSendMessage } = useSystemNotification();
  const { groupId } = useParams();
  const [openCalibration, setOpenCalibration] = useState(true);
  const [isCalibrationComplete, setIsCalibrationComplete] = useState(false);

  useEffect(() => {
    onSendMessage({
      notificationKind: notificationKind.QUIZSET_STUDENT_STATUS,
      payload: 'READY_INPROGRESS',
    });
  }, [onSendMessage]);

  useEffect(() => {
    if (isCalibrationComplete) {
      onSendMessage({
        notificationKind: notificationKind.QUIZSET_STUDENT_STATUS,
        payload: 'READY_SUCCESS',
      });
    }
  }, [isCalibrationComplete, onSendMessage]);

  if (!openCalibration) {
    return (
      <StyledContainer>
        <QuizEyetrackerInit
          groupId={groupId}
          onSuccess={setOpenCalibration}
          setIsCalibrationComplete={setIsCalibrationComplete}
        />
      </StyledContainer>
    );
  }

  if (isCalibrationComplete) {
    return (
      <StyledContainer>
        <StyledReady>
          <h1>자세 체크가 완료되었습니다!</h1>
          <StyledImg src={postureImg} />
        </StyledReady>
      </StyledContainer>
    );
  }

  return (
    <StyledContainer>
      <StyledReady>
        <h1>자세를 확인해주세요!</h1>
        <StyledImg src={postureImg} />
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: 20 }}
          onClick={() => {
            setOpenCalibration(false);
          }}
        >
          자세 체크 시작하기
        </Button>
      </StyledReady>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`;
const StyledReady = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1.8;
  font-size: 15px;
`;

const StyledImg = styled.img`
  width: 60%;
  height: 60%;
  object-fit: contain;
`;
