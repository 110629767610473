// useNetworkSpeed.js

import axios from "axios";
import { useState } from "react";

const imageAddr =
  "https://upload.wikimedia.org/wikipedia/commons/2/2d/Snake_River_%285mb%29.jpg";

function useNetworkSpeed() {
  const [maxSpeed, setMaxSpeed] = useState(null);
  const [avgSpeed, setAvgSpeed] = useState(null); // 평균 속도 상태 추가
  const [minSpeed, setMinSpeed] = useState(null);
  const [error, setError] = useState(null);
  const [ip, setIp] = useState(null);

  const getIpAddress = async () => {
    try {
      const ipData = await axios.get("https://geolocation-db.com/json/");
      const locationIp = ipData.data.IPv4;
      return locationIp;
    } catch (error) {
      console.log(error);
      // setError('IP 주소를 가져오는데 실패했습니다.');
      // throw error;
    }
  };
  const measureConnectionSpeed = () => {
    return new Promise((resolve, reject) => {
      const startTime = new Date().getTime();
      const download = new Image();

      download.crossOrigin = "anonymous";

      download.onload = function() {
        const endTime = new Date().getTime();
        const duration = (endTime - startTime) / 1000;

        // 5.25MB 이미지를 다운로드하는 데 걸린 시간을 계산합니다.
        const bitsLoaded = 5 * 8 * 1024 * 1024;
        const speedBps = (bitsLoaded / duration).toFixed(2);
        const speedKbps = (speedBps / 1024).toFixed(2);
        const speedMbps = (speedKbps / 1024).toFixed(2);

        resolve({
          bps: speedBps,
          kbps: speedKbps,
          mbps: speedMbps,
        });
      };

      download.onerror = function() {
        reject("유효하지 않은 이미지입니다.");
      };

      const cacheBuster = "?nnn=" + startTime;

      download.src = imageAddr + cacheBuster;
    });
  };

  const startMeasurement = async (measurements = 3) => {
    try {
      let speeds = [];
      const ipAddress = await getIpAddress();

      for (let i = 0; i < measurements; i++) {
        const measuredSpeed = await measureConnectionSpeed();
        speeds.push(parseFloat(measuredSpeed.mbps));
      }

      // 오름차순으로 속도 배열을 한 번만 정렬합니다.
      speeds.sort((a, b) => a - b);

      // 평균 속도 계산
      const totalSpeed = speeds.reduce((acc, speed) => acc + speed, 0);
      const averageSpeed = (totalSpeed / speeds.length).toFixed(2);

      // 정렬된 배열에서 최소 속도와 최고 속도를 설정합니다.
      setMinSpeed(speeds[0]); // 최소 속도는 정렬된 배열의 첫 번째 요소입니다.
      setMaxSpeed(speeds[speeds.length - 1]); // 최고 속도는 정렬된 배열의 마지막 요소입니다.

      // 측정 결과를 반환합니다.
      return {
        maxSpeed: speeds[speeds.length - 1], // 최고 속도
        avgSpeed: averageSpeed, // 평균 속도
        minSpeed: speeds[0], // 최소 속도
        ip: ipAddress,
      };
    } catch (err) {
      setError(err);
      throw err;
    }
  };

  // return { speed, error, startMeasurement };
  return { maxSpeed, avgSpeed, minSpeed, ip, error, startMeasurement };
}

export default useNetworkSpeed;
