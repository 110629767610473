import React from "react";
import { useDispatch } from "react-redux";
import styles from "./inviteCodeForm.module.scss";
import { getGroupByInviteCodePromise, getSchoolPromise } from "@store/actions";
import { Box, Button, HStack, Input, Stack, Text } from "@chakra-ui/react";

/**
 * @typedef {Object} InviteCodeFormProps
 * @prop {Function} nextStep - 다음단계로 이동하는 함수
 * @prop {Function} handleChange - 입력한 값을 저장하는 함수
 * @prop {import('@components/Modal/InviteCode').StudentInfo} values - 입력한 값을 저장하는 객체
 */

/**
 * 로그인할 때 사용
 * @param {InviteCodeFormProps} props
 */
const InviteCodeForm = ({ nextStep, handleChange, values }) => {
  const dispatch = useDispatch();

  const next = (e) => {
    const code = values.code.replace(/ /gi, "");
    console.log("[InviteCodeForm 입력한 초대코드]", code);
    e.preventDefault();
    dispatch(getGroupByInviteCodePromise(code))
      .then((response) => {
        console.log("[InviteCodeForm]", response);
        if (!response) throw new Error();
        dispatch(getSchoolPromise(response.schoolId)).then((School) => {
          nextStep();
        });
      })
      .catch((error) => {
        console.log("[InviteCodeForm Error]", error);
        alert("초대코드를 다시 입력해주세요.");
      });
  };

  return (
    <Box p={4}>
      <form onSubmit={next}>
        <Stack spacing={2} direction={"column"}>
          <Text fontSize="xl" fontWeight="bold" color="gray.600">
            선생님께 전달 받은 초대코드를 입력해주세요
          </Text>
          <HStack spacing={2}>
            {/* <input
            type="text"
            name="code"
            placeholder="초대코드"
            className={styles["input-normal"]}
            value={values.code}
            onChange={handleChange}
          /> */}
            <Input
              type="text"
              name="code"
              placeholder="초대코드"
              value={values.code}
              onChange={handleChange}
            />

            {/* <input
            type="submit"
            name="submit"
            value="다음"
            className={styles["input-submit"]}
          /> */}
            <Button type="submit" name="submit">
              다음
            </Button>
          </HStack>
        </Stack>
      </form>
    </Box>
  );
};

export default InviteCodeForm;
