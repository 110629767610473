import styled from 'styled-components'
import { useRef, useState } from 'react';
import { ReactComponent as TimeGrayImg } from '@assets/images/icons/time-gray.svg'
import { ReactComponent as PlusWhiteImg } from '@assets/images/icons/plus-white.svg'
import { ReactComponent as ArrowBottomImg } from '@assets/images/icons/arrow-bottom.svg';

const Dim = styled.div`
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  inset: 0 0 0 280px;
  z-index: 1;
`
const Container = styled.div`
  width: 550px;
  border-radius: 10px;
  border: 1px solid #E4E4E4;
  padding: 30px 0;
  background: var(--White, #FFF);
  display: flex;
  flex-direction: column;
  align-items: center;
`
const Header = styled.div``
const Title = styled.p`
  color: #212120;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 100% */
  letter-spacing: 0.25px;
`
const Desc = styled.p`
  margin-top: 10px;
  color: #212120;
  text-align: center;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 133.333% */
  letter-spacing: 0.25px;
`
const Body = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`
const Section = styled.div`
  display: flex;
  align-items: baseline;
`
const SectionKey = styled.div`
  width: 93px;
  margin-right: 23px;
  color: #212120;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 133.333% */
  letter-spacing: 0.25px;
`
const SectionValue = styled.div`
  width: 160px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #E4E4E4;
  padding: 5px 15px;
  background: ${props => props.disabled ? '#F1F1F1' : '#FFF'};
  display: flex;
  align-items: center;
  gap: 0 15px;
  position: relative;
  color: #212120;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
  letter-spacing: 0.25px;
`
const Footer = styled.div`
  margin-top: 40px;
  display: flex;
  align-items: center;
  gap: 10px;
`
const Btn = styled.button`
  width: 120px;
  height: 40px;
  line-height: 40px;
  border-radius: 5px;
`
const ConfirmBtn = styled(Btn)`
  background: #13305A;
  color: #FFF;
`
const CancelBtn = styled(Btn)`
  background: #E4E4E4;
  color: #212120;
`
const MenuButton = styled.button`
  width: 100%;
  height: 100%;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  inset: 0;
`
const MenuList = styled.ul`
  width: 160px;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #E4E4E4;
  background: #FFF;
  position: absolute;
  top: 100%;
  left: 0;
`
const MenuItem = styled.li`
  width: 150px;
  height: 30px;
  margin-top: 4px;
  border-radius: 5px;
  padding: 0 10px;
  background: ${props => props.select ? '#13305A' : '#FFF'};
  color: ${props => props.select ? '#FFF' : '#212120'};
  line-height: 30px;
  cursor: pointer;
  &:first-child {
    margin-top: 0;
  }
`
const Textfield = styled.textarea`
  width: 160px;
  height: 60px;
  border-radius: 5px;
  border: 1px solid #E4E4E4;
  padding: 5px 15px;
  background: #FFF;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
  letter-spacing: 0.25px;
  resize: none;
  &:placeholder {
    color: #E4E4E4;
  }
`
const FileContainer = styled.div`
  width: 160px;
  border-radius: 5px;
  border: 1px solid #E4E4E4;
  background: #FFF;
  display: flex;
  align-items: center;
`
const FileName = styled.p`
  min-width: 0;
  flex: 1;
  padding: 0 15px;
  color: #212120;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
  letter-spacing: 0.25px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`
const FileAddBtn = styled.button`
  width: 30px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #E4E4E4;
  background: #13305A;
  display: flex;
  justify-content: center;
  align-items: center;
`
const FileInput = styled.input`
  display: none;
`

const AttendanceStateChangePopup = ({ grade, _class, year, month, date, confirm, cancel }) => {
  const [showMenu, setShowMenu] = useState(false)
  const [attendanceState, setAttendanceState] = useState('결석')
  const [fileName, setFileName] = useState('파일 선택')

  const inputFile = useRef(null)

  return (
    <Dim>
      <Container>
        <Header>
          <Title>출석 상태 변경</Title>
          <Desc>30502 김이번 학생의 출석 상태를 변경합니다.</Desc>
        </Header>
        <Body>
          <Section>
            <SectionKey>출석 체크 시간</SectionKey>
            <SectionValue><TimeGrayImg />08:59</SectionValue>
          </Section>
          <Section>
            <SectionKey>공휴일 일자</SectionKey>
            <SectionValue>
              <MenuButton  onClick={() => { setShowMenu(!showMenu) }}>{attendanceState}<ArrowBottomImg /></MenuButton>
              {
                showMenu && (
                  <MenuList>
                    <MenuItem select={attendanceState === '출석'} onClick={() => { setAttendanceState('출석'); setShowMenu(false); }}>출석</MenuItem>
                    <MenuItem select={attendanceState === '지각'} onClick={() => { setAttendanceState('지각'); setShowMenu(false); }}>지각</MenuItem>
                    <MenuItem select={attendanceState === '결석'} onClick={() => { setAttendanceState('결석'); setShowMenu(false); }}>결석</MenuItem>
                    <MenuItem select={attendanceState === '조퇴'} onClick={() => { setAttendanceState('조퇴'); setShowMenu(false); }}>조퇴</MenuItem>
                  </MenuList>
                )
              }
            </SectionValue>
          </Section>
          <Section>
            <SectionKey>상세 내용</SectionKey>
            <Textfield placeholder="상세 내용 입력" />
          </Section>
          <Section>
            <SectionKey>증빙 자료</SectionKey>
            <FileContainer>
              <FileName>{fileName}</FileName>
              <FileAddBtn onClick={() => {
                inputFile.current.click()
              }}
              ><PlusWhiteImg /></FileAddBtn>
              <FileInput type="file" ref={inputFile} onInput={(e) => {
                setFileName(e.target.files[0].name)
              }} />
            </FileContainer>
          </Section>
        </Body>
        <Footer>
          <ConfirmBtn onClick={confirm}>완료</ConfirmBtn>
          <CancelBtn onClick={cancel}>취소</CancelBtn>
        </Footer>
      </Container>
    </Dim>
  )
}

export default AttendanceStateChangePopup