import { createQuizresponse, imageToFile, uploadQuizStylusWrite } from "@api";
import { Box, Image, Spinner, Stack, Text, VStack } from "@chakra-ui/react";
import { QuizStylusWrite } from "@pages/QuizPang/QuizSession";
import { QuizStylusWriteTldrawer } from "@pages/QuizPang/QuizSession/quiz-stylus-write-tldrawer";
import { getUserAgent } from "@pages/QuizPang/utils";
import {
  purgeHasAnswer,
  purgeSurveyResponseOk,
  purgeUploadResponse,
  purgeUploadTrigger,
  setHasAnswer,
  setSurveyResponseOk,
  setUploadResponse,
} from "@store/actions";
import { useMutation } from "@tanstack/react-query";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import SurveyLoading from "./SurveyLoading";
import useCreateQuizResponse from "./useCreateQuizResponse";
import ReactQuill from "react-quill";

const SurveyStylus = ({
  quizType,
  quizId,
  quizsetSessionId,
  link,
  linkType,
  title,
  isLastQuiz,
  groupId,
  setQuizEnded,
  quizEnded,
  type,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const clientId = user.clientId;
  const uploadTrigger = useSelector((state) => state.control.uploadTrigger);
  const uploadResponse = useSelector((state) => state.control.uploadResponse);
  const [stylusImage, setStylusImage] = useState(null);
  const [stylusData, setStylusData] = useState([]);
  const [stylusImageUpdateTrigger, setStylusImageUpdateTrigger] = useState(0);

  const responseLink = useRef(null);
  const containerRef = useRef(null);
  const [quizTimelimit, setQuizTimelimit] = useState(9999);
  console.log(uploadTrigger);
  //clearCanvase 자식컴포넌트에서 가져오기.
  let clearCanvas;
  const getClearFromSurvey = (func) => {
    clearCanvas = func;
  };
  const checkCanvasHasAnswer = async (ref) => {
    const paths = await ref?.current?.exportPaths();
    if (!paths.length) {
      dispatch(purgeHasAnswer());
    } else {
      dispatch(setHasAnswer());
    }
    console.log("ccc stylus canvas", paths?.length);
    // console.log('getCanvasRef', canvasRef);
  };

  //제출버튼 활성화.
  // if (!canvasRef?.current) {
  //   dispatch(purgeHasAnswer());
  // } else dispatch(setHasAnswer());
  // console.log(stylusImage);a
  const { mutate, isLoading, isSuccess } = useMutation((quizResponse) => {
    try {
      console.log("ccc 31 usemutation");
      createQuizresponse(quizResponse).then((r) => {
        setQuizTimelimit(9999);
        setStylusImage(null);
        // const canvas = document.getElementById('react-sketch-canvas-student');
        // canvas?.current.clearCanvas();
        console.log("ccc canvas", clearCanvas);
        clearCanvas();
        dispatch(purgeUploadTrigger());
        dispatch(purgeHasAnswer());
        dispatch(purgeUploadResponse());
        setQuizEnded(false);
        dispatch(setSurveyResponseOk());

        if (type !== "PACKAGE" && isLastQuiz) {
          dispatch(purgeSurveyResponseOk());
          alert("제출이 완료되었습니다!");
          history.push(`/focussurvey/${groupId}`);
        } else if (type === "PACKAGE" && isLastQuiz) {
          alert("제출이 완료되었습니다!");
        }
      });
    } catch (error) {
      setQuizTimelimit(9999);
      // clearCanvas();
      setStylusImage(null);
      dispatch(purgeUploadTrigger());
      dispatch(purgeHasAnswer());
      dispatch(purgeUploadResponse());
      console.log(error);
      alert("제출에 실패했습니다.");
    }
  });
  const uploadStylusImage = useCallback(
    async (stylusImage, clientId, quizId, quizsetSessionId) => {
      console.log("ccc 67 uploadStylusImage");
      const file = imageToFile(stylusImage, clientId);
      const link = await uploadQuizStylusWrite(file, quizsetSessionId, quizId);
      return link;
    },
    []
  );
  const handleCreateQuizresponse = async () => {
    console.log("ccc 94 handleCreateQuizResponse");
    setStylusImageUpdateTrigger((prevState) => prevState + 1);
    setQuizTimelimit(2);

    console.log("ccc 107 stylus Image", stylusImage);
  };
  useEffect(() => {
    if (stylusImage) {
      console.log("ccc 105 ");

      const link = uploadStylusImage(
        stylusImage,
        user.clientId,
        quizId,
        quizsetSessionId
      ).then((link) => {
        console.log("ccc 105 ", link);
        const quizresponse = {
          quizsetSessionId: quizsetSessionId,
          quizId: quizId,
          clientId: user.clientId,
          responseLink: link,
        };
        mutate(quizresponse);
      });
      setStylusImage(null);
      dispatch(purgeHasAnswer());
    }
  }, [stylusImage]);

  useCreateQuizResponse(uploadTrigger, handleCreateQuizresponse);

  if (isLoading) {
    // if (uploadTrigger) {
    return <SurveyLoading />;
  }

  return (
    <Stack direction={"column"} spacing={4} mb={8}>
      {title !== "" && (
        <Box border="1px" borderColor="gray.200" borderRadius={"lg"} p={8}>
          <ReactQuill
            theme="bubble"
            value={title}
            readOnly={true}
            modules={{ toolbar: false }}
          />
        </Box>
      )}
      {linkType === "IMG" && link !== "" && (
        <Box
          borderRadius={"lg"}
          border="1px"
          borderColor="gray.200"
          p={8}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          h={{
            base: "400px",
            md: "400px",
            lg: "400px",
          }}
        >
          <Image crossOrigin={"anonymous"} src={link} />
        </Box>
      )}
      <Box
        borderRadius={"lg"}
        border="1px"
        borderColor="gray.200"
        p={8}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <QuizStylusWrite
          containerRef={containerRef}
          deviceType={getUserAgent()}
          stylusData={stylusData}
          setStylusData={setStylusData}
          setStylusImage={setStylusImage}
          // setResponseLink={setResponseLink}
          stylusImageUpdateTrigger={stylusImageUpdateTrigger}
          quizMode={"survey"}
          quizTimelimit={quizTimelimit}
          getClearFromSurvey={getClearFromSurvey}
          checkCanvasHasAnswer={checkCanvasHasAnswer}
        />
      </Box>
    </Stack>
  );
};

export default SurveyStylus;
