import { createNetworkLatency } from "@api/network/networkApi";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  CloseButton,
  Container,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useNetworkSpeed from "utils/networkLatency/useNetworkLatency";
import { useInterval } from "./utils";
import useUserAgent from "utils/deviceInfo/useUserAgent";
import {
  setCameraPermission,
  setDevicemotionPermission,
  setLocationPermission,
  setMicrophonePermission,
  setNetworkSpeed,
} from "@store/actions";

const AlertManager = () => {
  const userDeviceInfo = useUserAgent();

  const user = useSelector((state) => state.user);
  const { speed, error, startMeasurement } = useNetworkSpeed();
  const [showAlert, setShowAlert] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then(() => dispatch(setCameraPermission()))
      .catch((error) =>
        console.error("카메라 설정중 오류가 발생하였습니다.", error)
      );
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(setMicrophonePermission())
      .catch((error) =>
        console.error("마이크 설정중 오류가 발생하였습니다.", error)
      );
    navigator.geolocation.getCurrentPosition(
      () => dispatch(setLocationPermission()),
      (error) => console.error("위치 설정중 오류가 발생하였습니다.", error)
    );
  }, []);

  useInterval(() => {
    console.log("[AlertManager]:useInterval!!!");
    sendNetworkSpeed();
  }, 1000 * 60 * 8);

  const sendNetworkSpeed = async (classroomId) => {
    try {
      const measuredSpeed = await startMeasurement();
      dispatch(setNetworkSpeed(measuredSpeed.avgSpeed));
      if (measuredSpeed.avgSpeed < 3) {
        setShowAlert(true);
      }
      createNetworkLatency({
        schoolId: user.schoolId,
        clientId: user.clientId,
        maxMbps: measuredSpeed.maxSpeed.toString(),
        avgMbps: measuredSpeed.avgSpeed.toString(),
        minMbps: measuredSpeed.minSpeed.toString(),
        ipAddr: measuredSpeed.ip.toString(),
      });
    } catch (err) {
      console.error(
        "[AlertManager]: 네트워크 속도 측정중 오류가 발생하였습니다.:",
        err
      );
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowAlert(false);
    }, 15000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Container
      position="absolute"
      top="20"
      left="0"
      right="0"
      zIndex="1000"
      maxW="30%"
      maxH="100%"
      overflow="hidden"
      pointerEvents="none"
    >
      {showAlert && (
        <Alert
          status="warning"
          variant="subtle"
          flexDirection="column"
          justifyContent="center"
          textAlign="center"
          height="200px"
          pointerEvents="auto"
        >
          <AlertIcon boxSize="40px" mr={0} />
          네트워크 속도가 느립니다.
          <CloseButton
            alignSelf="flex-start"
            position="relative"
            right={-1}
            top={-1}
            onClick={() => setShowAlert(false)}
          />
        </Alert>
      )}
    </Container>
  );
};

export default AlertManager;
