export const surveyList = [
  "9229db9f-6b0a-49d2-b359-69bf8a4e587f",
  "0b88df4e-22f3-4b19-9133-8497f300cba5",
  "b922562b-6aa8-456e-a98e-a5b4a392d5bc",
  "65fe08e4-e70a-4b1c-9471-8d6a96e66740",
  "15537fe0-7ffc-4a2d-b4ec-39b95600fd37",
  "484cb23f-3c04-4e4f-9487-d34d13a6c63a",
  "776e727f-0fad-4b45-b004-3be204458499",
  "3a35ef3c-047a-4883-8149-c3d3c1af3d42",
];
