import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getQuizsetSessionResultPromise } from "@app/store/quizsetSession/actions";
import { useParams, useHistory } from "react-router-dom";
import {
  useQuiz,
  useQuizset,
  useQuizsetSession,
  useQuizsetSessionResultByQuizsetSessionId,
} from "../hooks";
import useQuizResponseByQuizsetSessionId from "../hooks/useQuizResponse";
import { Button, Container, HStack, Heading, VStack } from "@chakra-ui/react";
import QuizLoading from "@components/QuizResult/Loading/QuizLoading";
import QuizResultList from "@components/QuizResult/QuizResultList";
import { Countdown } from "../countdown";
import Loading from "@components/Loading/Loading";
import ABQuizResultList from "@components/QuizResult/ABQuizResultList";

const QuizResult = () => {
  const history = useHistory();
  const { groupId, quizsetSessionId } = useParams();
  const {
    quizsetSession,
    isLoading: quizsetLoading,
    quizOrder,
    storageQuizTime,
    saveQuizTime,
    clearQuizTime,
  } = useQuizsetSession(groupId);
  console.log("31", quizsetSession);
  const { quizset } = useQuizset(quizsetSession?.quizsetId);
  const { quiz } = useQuiz(quizset?.quizzes?.[quizOrder].quizId);
  const result = useSelector(
    (state) => state.quizsetSession.resultsById[quizsetSessionId]
  );
  const user = useSelector((state) => state.user);
  const quizsetType = quizset?.quizsetType;
  const isABTest = quizsetType === "ABTEST";
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const { answer } = useQuizResponseByQuizsetSessionId(quizsetSessionId);

  const onTimeEnd = () => {
    setIsLoading(true);
    if (!quizsetLoading && !quizsetSession) {
      setTimeout(() => {
        clearQuizTime();
        setIsLoading(false);
        history.replace(`/quizpang/${groupId}`);
      }, 1000);
    }
  };

  useEffect(() => {
    if (typeof quizOrder === "number") {
      setTimeout(() => {
        dispatch(
          getQuizsetSessionResultPromise({
            quizsetSessionId,
            quizIndex: quizOrder + 1,
          })
        ).then(() => {
          setIsLoading(false);
        });
      }, 1000);
    }
  }, [dispatch, quizOrder, quizsetSessionId]);

  useEffect(() => {
    if (!quizsetLoading && !quizsetSession) {
      history.replace(`/quizpang/${groupId}`);
    }
  }, [groupId, history, quizsetLoading, quizsetSession]);

  if (!quizsetSession || !quizset) {
    return null;
  }
  if (isLoading) return <QuizLoading />;
  const isAutoPassed = quizset.isAutoPassed;
  const isShowRank = quizset.isShowRank;
  const isLast = quizset.quizzes.length - 1 === quizOrder;

  const handleQuizExit = () => {
    if (window.confirm("퀴즈를 나가시겠습니까?")) {
      clearQuizTime();
      history.replace(`/quizpang/${groupId}?tabKey=quiz`);
    }
  };
  console.log(quizsetType);
  return (
    <Container maxW="container.xl" p={8}>
      <HStack spacing={4} justifyContent="space-between" alignItems="center">
        <Heading fontSize="20px">퀴즈 결과</Heading>
        <>
          {!isLast && isAutoPassed && (
            <Countdown
              isSmall={true}
              saveQuizTime={saveQuizTime}
              startSecond={storageQuizTime?.second ?? quiz.timelimit}
              onZero={onTimeEnd}
            />
          )}
          <Button onClick={handleQuizExit}>퀴즈 나가기</Button>
        </>
      </HStack>
      <VStack w="full" spacing={4} mt={4}>
        {isABTest ? (
          <ABQuizResultList
            quizOrder={quizOrder}
            quizsetSessionId={quizsetSessionId}
            isNextQuiz={!isLast && isAutoPassed}
            answerData={answer}
            isShowRank={isShowRank}
            userClientId={user.clientId}
          />
        ) : (
          <QuizResultList
            quizOrder={quizOrder}
            quizsetSessionId={quizsetSessionId}
            isNextQuiz={!isLast && isAutoPassed}
            answerData={answer}
            isShowRank={isShowRank}
          />
        )}
      </VStack>
    </Container>
  );
};

export default QuizResult;
