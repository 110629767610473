import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import InviteCodeModal from "./InviteCodeModal";
import InviteRestInfoModal from "./InviteRestInfoModal";
import InviteCodeForm from "@components/Forms/inviteCodeForm";
import RestInfoForm from "@components/Forms/restInfoForm";
import styles from "./InviteCode.module.scss";
// NOTE: vscode버그인거 같은데 import사이에 주석있으면 하이라이팅이 안되는 불편함이 있어서 주석처리함
/*import // decipherInviteCodePromise,
// studentSignUpPromise,
// studentJoinUpPromise,
// signInPromise,
"@store/actions";*/
import "@store/actions";
import { Text } from "@chakra-ui/react";

/**
 * @typedef {Object} StudentInfo
 * @prop {string} code - 학생코드(uuid)
 * @prop {string} studentNumber - 학생번호
 * @prop {string} name - 이름
 */

function InviteCode({ showInvite, setShowInvite }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const [step, setStep] = useState(1);
  const [infos, setInfos] = useState(
    /** @type {StudentInfo} */ {
      code: "",
      studentNumber: "",
      name: "",
    }
  );

  /** @type {(React.ChangeEventHandler<HTMLInputElement>) => void} */
  const handleChange = (e) => {
    setInfos({
      ...infos,
      [e.target.name]: e.target.value,
    });
    // console.log("[InviteCode]", infos);
  };

  const prevStep = () => {
    // setStep(prev => prev - 1);/////
    setStep(step - 1);
  };

  const nextStep = () => {
    // setStep(prev => prev + 1);/////
    setStep(step + 1);
  };

  const reset = () => {
    ///////////
    setStep(1);
    setInfos({ code: "", studentNumber: "", name: "" });
    setShowInvite(false);
  };

  // const verifyInviteCode = () => {///////////
  //   dispatch(decipherInviteCodePromise(infos.code))
  //     .then((response) => {
  //       console.log('then', response);
  //       if (response.group.state === 'DELETED') {
  //         throw new Error('login failed!', response);
  //       }
  //       nextStep();
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //       alert('존재하지 않는 수업입니다.\n초대코드를 다시 확인해주세요.');
  //     });
  // };

  // const signInHandler = () => {//////////////////
  //   dispatch(decipherInviteCodePromise(infos.code))
  //     .then(({ group, school }) => {
  //       dispatch(
  //         signInPromise({
  //           school: school.id,
  //           grade: group.grade,
  //           class_name: group.class_name,
  //           student_number: infos.studentNumber,
  //           username: infos.name,
  //         })
  //       )
  //         .then((clientData) => {
  //           if (clientData === undefined) {
  //             throw new Error("login failed!");
  //           }
  //           dispatch(
  //             studentJoinUpPromise({
  //               group: group.id,
  //               student_number: infos.studentNumber,
  //               username: user.username,
  //               student_id: user.id,
  //             })
  //           );
  //         })
  //         .catch((error) => {
  //           console.error(error);
  //           alert(
  //             "로그인에 실패했어요.\n입력한 정보를 다시 확인하고 로그인해주세요"
  //           );
  //         });
  //     })
  //     .catch((err) => console.error(err));
  // };

  // const signUpHandler = async (e) => {///////////////
  //   e.preventDefault();

  //   dispatch(decipherInviteCodePromise(infos.code))
  //     .then(({ group, school }) => {
  //       console.log('!!!!!!!', group);
  //       console.log('?????????', school);
  //       dispatch(
  //         studentSignUpPromise({
  //           group: group.id,
  //           student_number: infos.studentNumber,
  //           username: infos.name,
  //         })
  //       )
  //         .then((clientData) => {
  //           if (clientData === undefined) {
  //             throw new Error("sign up failed");
  //           }
  //         })
  //         .catch((err) => {
  //           console.log("call sign in and joinup");
  //           signInHandler();
  //         });
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //     });
  // };

  switch (step) {
    case 1:
      return (
        <Modal
          show={showInvite}
          onHide={() => {
            setStep(1);
            setInfos({ code: "", studentNumber: "", name: "" });
            setShowInvite(false);
          }}
          centered
          className={styles["modal"]}
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-lg">초대 코드 로그인</Modal.Title>
          </Modal.Header>
          <div className={styles["body"]}>
            <InviteCodeForm
              nextStep={nextStep}
              handleChange={handleChange}
              values={infos}
            />
          </div>
        </Modal>
        // <InviteCodeModal
        //   visible={showInvite}
        //   code={infos.code}
        //   onChangeCode={handleChange}
        //   onHide={reset}
        //   onCancel={() => {
        //     reset();
        //     setShowInvite(false);
        //   }}
        //   onComplete={verifyInviteCode}
        // />
      );
    case 2:
      return (
        <Modal
          show={showInvite}
          onHide={() => {
            setStep(1);
            setInfos({ code: "", studentNumber: "", name: "" });
            setShowInvite(false);
          }}
          centered
          className={styles["modal"]}
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-lg">
              나머지 정보를 입력해주세요
            </Modal.Title>
          </Modal.Header>
          <div>
            <RestInfoForm
              nextStep={nextStep}
              prevStep={prevStep}
              handleChange={handleChange}
              values={infos}
            />
          </div>
        </Modal>
        // <InviteRestInfoModal
        //   visible={showInvite}
        //   values={infos}
        //   handleChange={handleChange}
        //   prevStep={prevStep}
        //   onHide={reset}
        //   onCancel={() => {
        //     reset();
        //     setShowInvite(false);
        //   }}
        //   onComplete={signUpHandler}
        // />
      );
    default:
      return <div />;
  }
}

export default InviteCode;
