import React, { useEffect, useState } from 'react';
import { createQuizresponse, getQuizChoiceByQuizId } from '@api';
import {
  Box,
  Image,
  Spinner,
  Stack,
  Text,
  VStack,
  CheckboxGroup,
} from '@chakra-ui/react';
import {
  purgeHasAnswer,
  purgeSurveyResponseOk,
  purgeUploadResponse,
  purgeUploadTrigger,
  setHasAnswer,
  setSurveyResponseOk,
  setUploadResponse,
} from '@store/actions';
import { useQuery, useMutation } from '@tanstack/react-query';

import { useDispatch, useSelector } from 'react-redux';

import RadioCard from './RadioCard';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import MultipleCard from './MultipleCard';
import useCreateQuizResponse from './useCreateQuizResponse';
import SurveyLoading from './SurveyLoading';
import ReactQuill from 'react-quill';
const SurveyMultiple = ({
  quizId,
  quizsetSessionId,
  link,
  linkType,
  title,
  isLastQuiz,
  groupId,
  setQuizEnded,
  type,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const uploadTrigger = useSelector((state) => state.control.uploadTrigger);
  const [choiceId, setChoiceId] = useState(null);

  const { data: choices, isLoading: queryIsLoading, isError } = useQuery({
    queryKey: ['surveyChoice', quizId],
    queryFn: () => {
      try {
        const choices = getQuizChoiceByQuizId(quizId).then((r) => {
          console.log('query', r);
          return r.data.choices;
        });
        return choices;
      } catch (error) {
        console.error(error);
        // error 처리
      }
    },
  });

  const { mutate: mutateResponse, isLoading: mutateIsLoading } = useMutation(
    (quizResponse) => {
      try {
        createQuizresponse(quizResponse).then((r) => {
          setChoiceId(null);
          dispatch(purgeUploadTrigger());
          dispatch(purgeHasAnswer());
          dispatch(purgeUploadResponse());
          setQuizEnded(false);
          dispatch(setSurveyResponseOk());

          if (type !== 'PACKAGE' && isLastQuiz) {
            dispatch(purgeSurveyResponseOk());
            alert('제출이 완료되었습니다!');
            history.push(`/focussurvey/${groupId}`);
          } else if (type === 'PACKAGE' && isLastQuiz) {
            alert('제출이 완료되었습니다!');
          }
        });
      } catch (error) {
        setChoiceId(null);
        dispatch(purgeUploadTrigger());
        dispatch(purgeHasAnswer());
        dispatch(purgeUploadResponse());
        console.log(error);
        alert('제출에 실패했습니다.');
      }
    },
  );

  //정답 비어있는 지 확인
  if (choiceId !== null) {
    dispatch(setHasAnswer());
  } else {
    dispatch(purgeHasAnswer());
  }

  const handleCreateQuizresponse = async () => {
    const quizresponse = {
      quizsetSessionId: quizsetSessionId,
      choiceId: choiceId,
      clientId: user.clientId,
    };
    mutateResponse(quizresponse);
  };

  useCreateQuizResponse(uploadTrigger, handleCreateQuizresponse);

  // if (mutateIsLoading) {
  if (uploadTrigger) {
    return <SurveyLoading />;
  }

  return (
    <Stack direction={'column'} spacing={4} mb={8}>
      {title !== '' && (
        <Box
          border="1px"
          borderColor="gray.200"
          borderRadius={'lg'}
          p={8}
          overflow={'auto'}
          maxHeight={'200px'}
        >
          <ReactQuill
            theme="bubble"
            value={title}
            readOnly={true}
            modules={{ toolbar: false }}
          />
        </Box>
      )}
      {linkType === 'IMG' && link !== '' && (
        <Box
          borderRadius={'lg'}
          border="1px"
          borderColor="gray.200"
          p={8}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          h={{
            base: '400px',
            md: '400px',
            lg: '400px',
          }}
        >
          <Image crossOrigin={'anonymous'} src={link} />
        </Box>
      )}
      <Box
        borderRadius={'lg'}
        bg={'gray.200'}
        p={8}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'flex-start'}
        flexDirection={'column'}
        gap={4}
        minHeight={'200px'}
      >
        <CheckboxGroup
          display={'flex'}
          justifyContent={'center'}
          alignItems={'flex-start'}
          flexDirection={'column'}
          gap={6}
        >
          {!queryIsLoading &&
            choices?.map((data, index) => {
              return (
                <MultipleCard
                  key={index}
                  index={index}
                  value={data?.choiceId}
                  selectedChoiceId={choiceId}
                  setChoiceId={setChoiceId}
                >
                  {data?.description}
                </MultipleCard>
              );
            })}
        </CheckboxGroup>
      </Box>
    </Stack>
  );
};

export default SurveyMultiple;
