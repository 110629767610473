import React from "react";
import QuizResultAccordion from "./Accordion/QuizResultAccordion";
import { useQuizsetSessionResultByQuizsetSessionId } from "@pages/QuizPang/hooks";

const QuizResultList = ({
  isShowRank,
  answerData,
  quizOrder,
  quizsetSessionId
}) => {
  const { quizResult } = useQuizsetSessionResultByQuizsetSessionId(
    quizsetSessionId,
    quizOrder + 1
  );
  const quizResultData = quizResult?.quizresults ?? [];
  const leaderBoardData = quizResult?.leaderboards ?? [];

  return (
    <QuizResultAccordion
      quizresults={quizResultData}
      leaderboards={leaderBoardData}
      answerData={answerData}
      isShowRank={isShowRank}
    />
  );
};

export default QuizResultList;
