import { POST_DATA_SUCCESS, POST_DATA_FAILURE } from './actionTypes';

const initialState = {
  // Initialize your domain types here
  postStatus: null,
  postError: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_DATA_SUCCESS:
      console.log(
        `${action.payload.domainType} data posted successfully:`,
        action.payload.response,
      );
      return {
        ...state,
        postStatus: `Success: ${action.payload.domainType} data posted successfully`,
        postError: null,
      };
    case POST_DATA_FAILURE:
      // console.error(`Error while posting ${action.payload.domainType} data`, action.payload.error);
      return {
        ...state,
        postStatus: `Failure: Error while posting ${action.payload.domainType} data`,
        postError: action.payload.error,
      };
    default:
      return state;
  }
};

export default reducer;
