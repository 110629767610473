import React from "react";
import { Stack, Box, Image, Text } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import {
  purgeUploadTrigger,
  purgeUploadResponse,
  setHasAnswer,
  purgeHasAnswer,
  setSurveyResponseOk,
} from "@store/actions";
import { useEffect } from "react";
import ReactQuill from "react-quill";
const SurveyHeadline = ({
  quizId,
  quizsetSessionId,
  link,
  linkType,
  title,
  setQuizEnded,
}) => {
  const uploadTrigger = useSelector((state) => state.control.uploadTrigger);
  const hasAnswer = useSelector((state) => state.control.hasAnswer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!hasAnswer) {
      dispatch(setHasAnswer());
      console.log("참");
    }
  }, []);

  useEffect(() => {
    if (uploadTrigger) {
      dispatch(purgeUploadTrigger());
      dispatch(purgeUploadResponse());
      dispatch(purgeHasAnswer());
      setQuizEnded(false);
      dispatch(setSurveyResponseOk());
      console.log("리셋");
    }
  }, [uploadTrigger]);
  return (
    <Stack direction={"column"} spacing={4} mb={8}>
      {title !== "" && (
        <Box
          bg="gray.200"
          borderRadius={"lg"}
          p={8}
          overflow={"auto"}
          maxHeight={"200px"}
        >
          <ReactQuill
            theme="bubble"
            value={title}
            readOnly={true}
            modules={{ toolbar: false }}
          />
        </Box>
      )}
      {linkType === "IMG" && link !== "" && (
        <Box
          borderRadius={"lg"}
          bg={"gray.200"}
          p={8}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          h={{
            base: "400px",
            md: "400px",
            lg: "400px",
          }}
        >
          <Image src={link} />
        </Box>
      )}
    </Stack>
  );
};

export default SurveyHeadline;
