import styled from 'styled-components';

import StateCheck from '@images/icons/attendance/state-check.svg'
import StateLate from '@images/icons/attendance/state-late.svg'
import StateAbsent from '@images/icons/attendance/state-absent.svg'
import StateLeave from '@images/icons/attendance/state-leave.svg'

const Td = styled.td`
  width: 187px;
  height: 162px;
  border: 1px solid #E4E4E4;
`
const TdItem = styled.button`
  width: 100%;
  height: 100%;
  padding: 14px;
  display: flex;
  flex-direction: column;
  gap: 10px 0;
  align-items: center;
`
const TdHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: #212120;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.25px;
`
const TdBody = styled.div`
  min-height: 0;
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const StateIcon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: url(${props => props.stateImg}) no-repeat center / 100% auto;
`
const StateText = styled.div`
  margin-top: 10px;
  color: ${props => props.stateColor};
  text-align: center;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 146.667% */
  letter-spacing: 0.1px;
`

const TableTd = ({ i, j, year, month, date, selectDate }) => {
  const state = Math.floor(Math.random() * 4)
  const stateImg = [StateCheck, StateLate, StateAbsent, StateLeave][state]
  const stateText = ['08:25 출석', '08:25 지각', '결석', '08:25 조퇴'][state]
  const stateColor = ['#3B848F', '#FF9A03', '#FF0000', '#BDBDBD'][state]

  return (
    <Td>
      <TdItem onClick={() => { selectDate({ year, month, date }) }}>
        <TdHeader>{date}</TdHeader>
        <TdBody>
          {
            j !== 0 && j !== 6 && (
              <>
                <StateIcon stateImg={stateImg}></StateIcon>
                <StateText stateColor={stateColor}>{stateText}</StateText>
              </>
            )
          }
        </TdBody>
      </TdItem>
    </Td>
  )
}

export default TableTd