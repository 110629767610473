import React, { useState } from "react";
import ProfileImage from "../ProfileImage";
import styles from "./ModalUpdateProfileImage.module.scss";
import UploadFileRoundedIcon from "@mui/icons-material/UploadFileRounded";
import { Button } from "@mui/material";
// import { updateProfileImage } from "@api";
import BaseModal from "./BaseModal";
import { useDispatch, useSelector } from "react-redux";
import { profileImgPath, uploadImage } from "@api";
import { setProfileImageURL, setReportLog } from "@store/actions";
import { ButtonConstants } from "constants/buttonConstants";

const ModalUpdateProfileImage = props => {
  const { visible, onHide = () => {}, currentProfileImage = "" } = props;

  const [file, setFile] = useState();
  const [preview, setPreview] = useState();
  const [img, setImg] = useState();

  const handleImage = e => {
    const file = e.target.files[0];
    const url = URL.createObjectURL(file);
    setFile(file);
    setPreview(url);

    setImg({
      uri: url,
      type: file.type,
      name: file.name,
    });
  };
  const user = useSelector(
    (/** @type {import('@store').State} */ state) => state.user,
  );
  const dispatch = useDispatch();

  // const save = async () => {
  //   await updateProfileImage(file);
  //   onHide();
  // };
  const save = async () => {
    try {
      dispatch(setReportLog(ButtonConstants.CONFIG.CONFIRM_IMAGE_CHANGE));
      await uploadImage(file, user.clientId);
      alert("프로필 사진이 변경되었습니다.");
      dispatch(
        setProfileImageURL(
          `${profileImgPath(user.clientId)}?${new Date().getTime()}`,
        ),
      );
      // NOTE: 프로필 이미지 업데이트 처리 필요
      // updateImageKey(new Date().toUTCString());
      // ToastAndroid.show("프로필 사진이 변경되었습니다.", 2000);
      // onUpdateAvatar();
      // setIsVisible(false);
      props.onHide();
    } catch (e) {
      alert(e);
      props.onHide();
    }
  };

  return (
    <BaseModal
      visible={visible}
      onHide={onHide}
      title="프로필 사진 변경"
      onCancel={onHide}
      completeText="변경"
      onComplete={save}
    >
      <div className={styles["body-wrapper"]}>
        <div className={styles["profile-image"]}>
          {/* NOTE: 기본 프로필 이미지 적용 필요 */}
          <ProfileImage
            size={120}
            url={preview ? preview : props.currentProfileImage}
          />
        </div>
        <label htmlFor="contained-button-file">
          <input
            accept="image/*"
            id="contained-button-file"
            multiple
            type="file"
            style={{ opacity: 0, width: 0 }}
            onChange={handleImage}
          />
          <Button
            variant="contained"
            startIcon={<UploadFileRoundedIcon />}
            component="span"
            onClick={() => {
              dispatch(setReportLog(ButtonConstants.CONFIG.LOAD_IMAGE));
            }}
          >
            이미지 불러오기
          </Button>
        </label>
      </div>
    </BaseModal>
  );
};

export default ModalUpdateProfileImage;
