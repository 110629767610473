import React, { memo, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import styles from "./SidebarClassRoom.module.scss";
import SidebarClassList from "@components/Layout/SidebarClassList";
import {
  selectSubjectGroups,
  selectAllClients,
  selectActiveClassroom,
  selectGroupById,
  selectAllUnreadNotifications,
} from "@store/selectors";
import ProfileImage from "@components/ProfileImage";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import SidebarTab from "@components/SidebarTab/SidebarTab";
import DesktopWindowsIcon from "@mui/icons-material/DesktopWindows";
import { ReactComponent as PeopleImg } from "@images/icons/people.svg";
import { ReactComponent as ABImg } from "@images/icons/a-b.svg";
import { ReactComponent as HomeworkImg } from "@images/icons/homework.svg";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SensorsIcon from "@mui/icons-material/Sensors";
import { profileImgPath } from "@api";
import {
  getSchoolPromise,
  setReportLog,
  setSelectedClass,
} from "@store/actions";
import { selectTeacherMemberships } from "@store/membership/selector";
import { useParams } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { ButtonConstants } from "constants/buttonConstants";

function SidebarClassRoom({ inClass = false, active }) {
  const dispatch = useDispatch();
  const isActive = !!useSelector(state => selectActiveClassroom(state));
  const user = useSelector(state => state.user);
  const location = useLocation();
  const sub_group = useSelector(state => selectSubjectGroups(state));
  const { groupId } = useParams();
  const subject = useSelector(state => selectGroupById(state, groupId));
  const teacherMemberships = useSelector(state =>
    selectTeacherMemberships(state),
  );
  const notifications = useSelector(state =>
    selectAllUnreadNotifications(state),
  );
  const clients = useSelector(state => selectAllClients(state));

  const myProfileImage = useMemo(() => {
    if (!user) {
      return undefined;
    }

    return profileImgPath(user.clientId);
  }, [user]);

  useEffect(() => {
    groupId && dispatch(setSelectedClass(groupId));
  }, [dispatch, groupId]);

  useEffect(() => {
    if (sub_group[0]?.school) {
      dispatch(getSchoolPromise(sub_group[0].school));
    }
  }, [dispatch, sub_group]);

  const findClientName = groupId => {
    if (!groupId) return null;

    const foundMembership = teacherMemberships?.find(
      membership => membership.groupId === groupId,
    );
    if (!foundMembership) return null;

    const foundClient = clients.find(
      client => foundMembership?.clientId === client.clientId,
    );
    if (foundClient !== null) return foundClient?.userName;

    return null;
  };

  return (
    <div className={styles["wrapper"]}>
      <div className={styles["inner-side"]}>
        <div className={styles["student-info-container"]}>
          {user.signedIn && (
            <>
              <ProfileImage size={40} url={myProfileImage} />
              <div className={styles["user-name-container"]}>
                <div className={styles["p-user-name"]}>{user?.userName}</div>
                <div className={styles["p-student-number"]}>
                  학번 : {user?.studentNumber}
                </div>
              </div>
            </>
          )}
        </div>
        <div className={styles["horizontal-line"]} />
        {!isActive && (
          <>
            <SidebarTab
              icon={
                <HomeRoundedIcon
                  style={{
                    width: 24,
                    height: 24,
                    color: "#757575",
                    marginRight: 24,
                  }}
                />
              }
              href={"/home"}
              text="처음으로"
            />
            <div className={styles["horizontal-line"]} />
          </>
        )}
        <div className={styles["classList-wrapper"]}>
          <div className={styles["span-classList"]}>
            {isActive && <SensorsIcon />}
            <Tooltip title={subject ? subject.subjectName : ""} arrow>
              <div className={styles["subjectName"]}>
                {`${subject ? subject.subjectName : ""}`}
              </div>
            </Tooltip>
            <div className={styles["text"]}>
              {`수업${isActive ? " 중" : ""}`}
            </div>
          </div>
          {/* <div className={styles['span-classList-add']}>
            {findClientName(groupId)}선생님
          </div> */}
        </div>
        <div className={styles["horizontal-line"]} />
        <SidebarTab
          icon={
            <NotificationsIcon
              style={{
                width: 24,
                height: 24,
                color: active ? "#058692" : "#757575",
                marginRight: 24,
              }}
            />
          }
          noti={notifications.length || 0}
          text="수업 공지사항"
          href={`/notice/${groupId}`}
          active={location.pathname.includes("notice")}
          onClick={() => {
            dispatch(setReportLog(ButtonConstants.SIDEBAR.NOTICE));
          }}
        />
        {subject?.groupType === "SUBJECT" && (
          <SidebarTab
            icon={
              <DesktopWindowsIcon
                style={{
                  width: 24,
                  height: 24,
                  color: active ? "#058692" : "#757575",
                  marginRight: 24,
                }}
              />
            }
            href={`/classroom/${groupId}`}
            text="수업 화면"
            active={
              location.pathname.includes("classroom") &&
              !location.pathname.includes("classroom/band")
            }
            disabled={true}
          />
        )}
        <SidebarTab
          icon={
            <PeopleImg
              style={{
                width: 24,
                height: 24,
                color: active ? "#058692" : "#757575",
                marginRight: 24,
              }}
            />
          }
          text="모둠 활동"
          href={`/classroom/band/${groupId}`}
          active={location.pathname.includes("classroom/band")}
        />
        <SidebarTab
          icon={<ABImg fill="#757575" style={{ marginRight: 24 }} />}
          text="퀴즈팡"
          href={`/quizpang/${groupId}`}
          active={location.pathname.includes("quizpang")}
        />
        {/* <SidebarTab
          icon={<ABImg fill="#757575" style={{ marginRight: 24 }} />}
          text="설문지"
          href={`/focussurvey/${groupId}`}
          active={location.pathname.includes("quizpang")}
        /> */}
        <SidebarTab
          icon={<HomeworkImg style={{ marginRight: 24 }} />}
          text="과제"
          href={`/assignment/${groupId}`}
          active={location.pathname.includes("assignment")}
        />
      </div>
    </div>
  );
}

export default memo(SidebarClassRoom, (prev, next) => {
  return (
    prev.active === next.active &&
    prev.groupId === next.groupId &&
    prev.inClass === next.inClass &&
    prev.subject === next.subject
  );
});
