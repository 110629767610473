import styles from './AttendanceTodayState.module.scss'

const AttendanceTodayState = () => {
  return (
    <div className={styles['container']}>
      <div className={styles['state-container']}>
        <div className={styles['state-icon']}></div>
        <div className={styles['state-text']}>
          <p className={styles['state-date']}>9월 28일 목요일 <span className={styles['state']}>미출석</span> 상태 입니다.</p>
          <p className={styles['state-desc']}>교실에서 출석 체크 버튼을 눌러 얼굴 인식을 완료해주세요.</p>
        </div>
      </div>
      <div className={styles['btn-container']}>
        <button className={styles['btn']}>출석 체크</button>
      </div>
    </div>
  )
}

export default AttendanceTodayState;