// export const SAVE_EYETRACKINGDATA = "SAVE_EYETRACKINGDATA";

export const SET_EYETRACKING_DATA = "SET_EYETRACKING_DATA";

export const PURGE_EYETRACKING_DATA = "PURGE_EYETRACKING_DATA";

export const CREATE_EYETRACKING_DATA = "CREATE_EYETRACKING_DATA";

export const CREATE_EYETRACKING_DATA_SUCCESS =
  "CREATE_EYETRACKING_DATA_SUCCESS";

export const CREATE_EYETRACKING_DATA_FAIL = "CREATE_EYETRACKING_DATA_FAIL";
