import { call, put, delay, takeLatest, select } from 'redux-saga/effects';
import { set } from 'idb-keyval';
import axios from 'axios';
import {
  CLIP_AND_SEND_GPS_DATA,
  CLIP_AND_SEND_TOUCH_DATA,
  CLIP_AND_SEND_KEYBOARD_DATA,
  CLIP_AND_SEND_STYLUS_DATA,
  CLIP_AND_SEND_ACCELGYRO_DATA,
  CLIP_AND_SEND_EYETRACKING_DATA,
} from './actionTypes';
import { postDataSuccess, postDataFailure } from './actions';
import {
  deleteGpsData,
  deleteTouchData,
  deleteKeyboardData,
  deleteStylusData,
  deleteAccelgyroData,
  deleteEyetrackingData,
} from '@store/actions';
import { createArchive, updateCompletedArchive } from '@api/archive/archiveApi';

const MAX_RETRIES = 3;

// Function to get data from state
function* clipAndSendGpsDataSaga(action) {
  const { clientId, quizId, domainType, quizStart, quizEnd } = action.payload;
  const gpsData = yield select((state) => state.dataSave.gpsData);
  console.log('quizStart:', quizStart);
  console.log('quizEnd:', quizEnd);
  console.log('gps:', gpsData);

  // Clip GPS data based on quiz start and end times.
  const clippedData = gpsData.filter(
    (data) => data.timeStamp >= quizStart && data.timeStamp <= quizEnd
  );
  console.log('gps clippedData:', clippedData);
  // console.log("quizStart:", quizStart)
  // console.log("quizEnd:", quizEnd)

  let response;
  let retries = 0;
  while (retries < MAX_RETRIES) {
    try {
      response = yield call(
        postDataToServer,
        clientId,
        quizId,
        domainType,
        quizStart,
        quizEnd
      );
      yield put(postDataSuccess(domainType, response.data));
      yield delay(300);

      const presignedUrl = response.data.presignedUrl;
      yield call(uploadDataToPresignedUrl, presignedUrl, clippedData);
      yield call(completeArchive, response.data.archiveId);
      yield delay(300);
      // If the upload is successful, exit the loop.
      break;
    } catch (error) {
      console.log('Post failed, retrying');
      if (retries < MAX_RETRIES - 1) {
        // If not at maximum retries for post, increment retry counter and continue.
        retries += 1;
      } else {
        // If at maximum retries for post, throw the error.
        yield put(postDataFailure(error.message));
        return;
      }
    }
  }

  // Delete sent data.
  yield put(deleteGpsData({ startTime: quizStart, endTime: quizEnd }));
}

// Function to get data from state
function* clipAndSendTouchDataSaga(action) {
  const { clientId, quizId, domainType, quizStart, quizEnd } = action.payload;
  const touchData = yield select((state) => state.dataSave.touchData);
  console.log('touch:', touchData);

  // Clip Touch data based on quiz start and end times.
  //const filteredData = touchData.flat().filter(data => {return data.it >= quizStart && data.it <= quizEnd;});
  const clippedData = touchData.filter((data) => {
    const dataTime = new Date(data.it).getTime();
    const quizStartTime = new Date(quizStart).getTime();
    const quizEndTime = new Date(quizEnd).getTime();

    return dataTime >= quizStartTime && dataTime <= quizEndTime;
  });

  //const clippedData =  [...new Set(filteredData.map(item => JSON.stringify(item)))].map(item => JSON.parse(item));

  console.log('touch clippedData:', clippedData);
  // console.log("quizStart:", quizStart)
  // console.log("quizEnd:", quizEnd)

  let response;
  let retries = 0;
  while (retries < MAX_RETRIES) {
    try {
      response = yield call(
        postDataToServer,
        clientId,
        quizId,
        domainType,
        quizStart,
        quizEnd
      );
      yield put(postDataSuccess(domainType, response.data));
      yield delay(300);

      const presignedUrl = response.data.presignedUrl;
      yield call(uploadDataToPresignedUrl, presignedUrl, clippedData);
      yield call(completeArchive, response.data.archiveId);
      yield delay(300);
      break;
    } catch (error) {
      console.log('Post failed, retrying');
      console.log(
        '🚀 ~ file: saga.js:102 ~ function*clipAndSendTouchDataSaga ~ retries:',
        retries
      );
      if (retries < MAX_RETRIES - 1) {
        // If not at maximum retries for post, increment retry counter and continue.
        retries += 1;
      } else {
        // If at maximum retries for post, throw the error.
        yield put(postDataFailure(error.message));
        return;
      }
    }
  }

  // Delete sent data.
  yield put(deleteTouchData({ startTime: quizStart, endTime: quizEnd }));
}

function* clipAndSendKeyboardDataSaga(action) {
  const { clientId, quizId, domainType, quizStart, quizEnd } = action.payload;
  const keyboardData = yield select((state) => state.dataSave.keyboardData);
  console.log('keyboard:', keyboardData);

  // Clip Keyboard data based on quiz start and end times.
  // const filteredData = keyboardData.flat().filter(data => {return data.it >= quizStart && data.it <= quizEnd;});

  // const clippedData =  [...new Set(filteredData.map(item => JSON.stringify(item)))].map(item => JSON.parse(item));

  const clippedData = keyboardData.filter((data) => {
    console.log('data.it:', data);
    return data.timeStamp >= quizStart && data.timeStamp <= quizEnd;
  });

  console.log('keyboard clippedData:', clippedData);
  // console.log("quizStart:", quizStart)
  // console.log("quizEnd:", quizEnd)

  let response;
  let retries = 0;
  while (retries < MAX_RETRIES) {
    try {
      response = yield call(
        postDataToServer,
        clientId,
        quizId,
        domainType,
        quizStart,
        quizEnd
      );
      yield put(postDataSuccess(domainType, response.data));
      yield delay(300);

      const presignedUrl = response.data.presignedUrl;
      yield call(uploadDataToPresignedUrl, presignedUrl, clippedData);
      yield call(completeArchive, response.data.archiveId);
      yield delay(300);
      // If the upload is successful, exit the loop.
      break;
    } catch (error) {
      console.log('Post failed, retrying');
      if (retries < MAX_RETRIES - 1) {
        // If not at maximum retries for post, increment retry counter and continue.
        retries += 1;
      } else {
        // If at maximum retries for post, throw the error.
        yield put(postDataFailure(error.message));
        return;
      }
    }
  }

  // Delete sent data.
  yield put(deleteKeyboardData({ startTime: quizStart, endTime: quizEnd }));
}

function* clipAndSendStylusDataSaga(action) {
  const { clientId, quizId, domainType, quizStart, quizEnd } = action.payload;
  const stylusData = yield select((state) => state.dataSave.stylusData);
  console.log('stylus:', stylusData);

  // Clip Stylus data based on quiz start and end times.
  // const filteredData = stylusData.flat().filter(data => {return data.it >= quizStart && data.it <= quizEnd;});

  // const clippedData =  [...new Set(filteredData.map(item => JSON.stringify(item)))].map(item => JSON.parse(item));

  const clippedData = stylusData.filter((data) => {
    return data.it >= quizStart && data.it <= quizEnd;
  });

  console.log('stylus clippedData:', clippedData);
  // console.log("quizStart:", quizStart)
  // console.log("quizEnd:", quizEnd)

  let response;
  let retries = 0;
  while (retries < MAX_RETRIES) {
    try {
      response = yield call(
        postDataToServer,
        clientId,
        quizId,
        domainType,
        quizStart,
        quizEnd
      );
      yield put(postDataSuccess(domainType, response.data));
      yield delay(300);

      const presignedUrl = response.data.presignedUrl;
      yield call(uploadDataToPresignedUrl, presignedUrl, clippedData);
      yield call(completeArchive, response.data.archiveId);
      yield delay(300);
      // If the upload is successful, exit the loop.
      break;
    } catch (error) {
      console.log('Post failed, retrying');
      if (retries < MAX_RETRIES - 1) {
        // If not at maximum retries for post, increment retry counter and continue.
        retries += 1;
      } else {
        // If at maximum retries for post, throw the error.
        yield put(postDataFailure(error.message));
        return;
      }
    }
  }

  // Delete sent data.
  yield put(deleteStylusData({ startTime: quizStart, endTime: quizEnd }));
}

function* clipAndSendAccelgyroDataSaga(action) {
  const { clientId, quizId, domainType, quizStart, quizEnd } = action.payload;

  const accelgyroData = yield select((state) => state.dataSave.accelgyroData);
  const { accel_g, accel_nog, gyro } = accelgyroData || {};

  console.log('accel-gyro:', accelgyroData);

  // Make sure the data arrays exist before attempting to filter them
  const clippedAccelG = accel_g
    ? accel_g.filter((data) => data.t >= quizStart && data.t <= quizEnd)
    : [];
  const clippedAccelNoG = accel_nog
    ? accel_nog.filter((data) => data.t >= quizStart && data.t <= quizEnd)
    : [];
  const clippedGyro = gyro
    ? gyro.filter((data) => data.t >= quizStart && data.t <= quizEnd)
    : [];

  // Combine all clipped data into one object
  const clippedData = {
    accel_g: clippedAccelG,
    accel_nog: clippedAccelNoG,
    gyro: clippedGyro,
  };

  console.log('accel-gyro clippedData:', clippedData);

  let response;
  let retries = 0;
  while (retries < MAX_RETRIES) {
    try {
      response = yield call(
        postDataToServer,
        clientId,
        quizId,
        domainType,
        quizStart,
        quizEnd
      );
      yield put(postDataSuccess(domainType, response.data));
      yield delay(300);

      const presignedUrl = response.data.presignedUrl;
      yield call(uploadDataToPresignedUrl, presignedUrl, clippedData);
      yield call(completeArchive, response.data.archiveId);
      yield delay(300);
      // If the upload is successful, exit the loop.
      break;
    } catch (error) {
      console.log('Post failed, retrying');
      if (retries < MAX_RETRIES - 1) {
        // If not at maximum retries for post, increment retry counter and continue.
        retries += 1;
      } else {
        // If at maximum retries for post, throw the error.
        yield put(postDataFailure(error.message));
        return;
      }
    }
  }

  // Delete sent data.
  yield put(deleteAccelgyroData({ startTime: quizStart, endTime: quizEnd }));
}

// Function to get data from state
function* clipAndSendEyetrackingDataSaga(action) {
  const { clientId, quizId, domainType, quizStart, quizEnd } = action.payload;
  const eyetrackingData = yield select(
    (state) => state.dataSave.eyetrackingData
  );
  console.log('quizStart:', quizStart);
  console.log('quizEnd:', quizEnd);
  console.log('eye-tracking:', eyetrackingData);

  // Clip GPS data based on quiz start and end times.
  //const clippedData = eyetrackingData.filter(data => data.timeStamp >= quizStart && data.timeStamp <= quizEnd);
  //const clippedData = eyetrackingData.filter(data => {return data.it >= quizStart && data.it <= quizEnd;});
  const clippedData = eyetrackingData.flat().filter((data) => {
    const dataDate = new Date(data.timeStamp);
    const startDate = new Date(quizStart);
    const endDate = new Date(quizEnd);

    return dataDate >= startDate && dataDate <= endDate;
  });

  console.log('eye-tracking clippedData:', clippedData);
  // console.log("quizStart:", quizStart)
  // console.log("quizEnd:", quizEnd)
  //indexedDB 관련 코드
  // const saveDataKey = `${clientId}_${quizId}_${domainType}`;
  // yield call(set, saveDataKey, clippedData);

  let response;
  let retries = 0;
  while (retries < MAX_RETRIES) {
    try {
      response = yield call(
        postDataToServer,
        clientId,
        quizId,
        domainType,
        quizStart,
        quizEnd
      );
      yield put(postDataSuccess(domainType, response.data));
      yield delay(300);

      const presignedUrl = response.data.presignedUrl;
      yield call(uploadDataToPresignedUrl, presignedUrl, clippedData);
      yield call(completeArchive, response.data.archiveId);
      yield delay(300);
      // If the upload is successful, exit the loop.
      break;
    } catch (error) {
      console.log('Post failed, retrying');
      if (retries < MAX_RETRIES - 1) {
        // If not at maximum retries for post, increment retry counter and continue.
        retries += 1;
      } else {
        // If at maximum retries for post, throw the error.
        yield put(postDataFailure(error.message));
        return;
      }
    }
  }

  // Delete sent data.
  yield put(deleteEyetrackingData({ startTime: quizStart, endTime: quizEnd }));
}

async function postDataToServer(
  clientId,
  quizId,
  domainType,
  quizStart,
  quizEnd
) {
  const response = await createArchive({
    clientId: clientId,
    context: 'quizpang',
    contextId: quizId,
    domain: domainType,
    startedAt: quizStart,
    endedAt: quizEnd,
  });

  return response;
}

async function uploadDataToPresignedUrl(presignedUrl, data) {
  const response = await axios.put(presignedUrl, data, {
    headers: {
      'Content-Type': 'text/plain',
    },
  });
  return response.data;
}

async function completeArchive(archiveId) {
  const response = await updateCompletedArchive({
    archiveId: archiveId,
  });
}

function* dataArchiveSaga() {
  yield takeLatest(CLIP_AND_SEND_GPS_DATA, clipAndSendGpsDataSaga);
  yield takeLatest(CLIP_AND_SEND_TOUCH_DATA, clipAndSendTouchDataSaga);
  yield takeLatest(CLIP_AND_SEND_KEYBOARD_DATA, clipAndSendKeyboardDataSaga);
  yield takeLatest(CLIP_AND_SEND_STYLUS_DATA, clipAndSendStylusDataSaga);
  yield takeLatest(CLIP_AND_SEND_ACCELGYRO_DATA, clipAndSendAccelgyroDataSaga);
  yield takeLatest(
    CLIP_AND_SEND_EYETRACKING_DATA,
    clipAndSendEyetrackingDataSaga
  );
}

export default dataArchiveSaga;
