import React, { memo, useEffect, useRef, useState } from "react";
import styles from "./classroom.module.scss";
import { useHistory, useParams } from "react-router-dom";
import {
  selectAllPrograms,
  selectActiveClassroom,
  selectAllClients,
  selectGroupProgramconfigs,
} from "@store/selectors";
import {
  setSelectedClass,
  setSelectedPage,
  setSocketData,
  deleteClassroomPromise,
  getActiveClassroomPromise,
  getGroupFilelinkPromise,
  getActiveClassroomByGroupPromise,
  getGroupProgramconfigsPromise,
  getMembershipPromise,
  getMembershipsByGroupPromise,
  setTeacherClientId,
  setFocusType,
  purgeFocusType,
  getTimetablesByGroupPromise,
} from "@store/actions";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames/bind";
import EnabledApps from "@components/ClassRoom/EnabledApps/EnabledApps";
import SharedScreenFull from "@components/Modal/SharedScreenFull";
import SidebarClassRoom from "@components/Layout/SidebarClassRoom";
import PageWrapper from "@components/Layout/PageWrapper";
import BaseCard from "@components/Card/baseCard";
import ProfileImage from "@components/ProfileImage";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import { profileImgPath } from "@api";
import FileLinkList from "@components/ClassRoom/FileLink/FileLinkList";
import { selectTeacherMembershipByGroup } from "@store/membership/selector";
import { CommentsLayer } from "@components/ClassRoom/CommentsLayer/CommentsLayer";
import ClassroomEyetrackingLogger from "./eyetracking-logger";
import styled from "styled-components";
import { ConstansType } from "common/Constans";

const SECONDS = 1000;
const MINUTES = 60;

const Classroom = ({ props, location }) => {
  const params = useParams();
  const { groupId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((/** @type {import('@store').State} */ state) => {
    return state.user;
  });
  const [isMonitoring, setMonitoring] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const [screenCaptureIntervalId, setScreenCaptureIntervalId] = useState(0);
  const seconds = useRef(0);
  const defaultRef = useRef();
  const cx = classNames.bind(styles);
  const { selectedStd, teacherScreen } = useSelector((state) => state.control);
  const [activeSubjectClassroom, setActiveSubjectClassroom] = useState(
    /** @type {focuspang.Classroom?} */ (null)
  );
  const [filelinks, setFilelinks] = useState(
    /** @type {focuspang.Filelink[]?} */ (null)
  );
  const [programconfigs, setProgramconfigs] = useState(
    /** @type {focuspang.Programconfig[]?} */ (null)
  );
  const [groups, setGroups] = useState(
    /** @type {focuspang.Group[]?} */ (null)
  );
  const [currentGroup, setCurrentGroup] = useState(
    /** @type {focuspang.Group?} */ (null)
  );
  const [timetable, setTimetable] = useState(
    /** @type {focuspang.Timetable?} */ (null)
  );

  const [eyetrackingData, setEyeTrackingData] = useState(null);
  const [openCalibration, setOpenCalibration] = useState(true);
  const [isCalibrationComplete, setIsCalibrationComplete] = useState(false);

  // 소켓을 보내고 있는지 확인하는 변수
  const [isRestart, setIsRestart] = useState(false);
  const [focusState, setFocusState] = useState(0);
  const focusTimeState = useSelector((state) => state.control.focusTimeState);
  const focusType = useSelector((state) => state.control.focusType);

  useEffect(() => {
    if (!focusTimeState) {
      setFocusState(0);
      return;
    }
    if (focusType === "ADD_STUDENT_FOCUS_STATUS_LIST") {
      if (focusState !== 1) {
        setFocusState(1);
      }
    } else if (focusType === "ADD_STUDENT_GOOD_FOCUS_STATUS_LIST") {
      if (focusState !== 2) {
        setFocusState(2);
      }
    } else {
      if (focusState !== 0) {
        setFocusState(0);
      }
    }
  }, [focusType, focusTimeState]);

  const clients = useSelector((state) => selectAllClients(state));
  const activeClassroomRedux = useSelector((state) =>
    selectActiveClassroom(state)
  );

  const memebership = useSelector((state) =>
    selectTeacherMembershipByGroup(state, groupId)
  );

  // 0: not focused, 1: focused, 2: well focused
  const [currentScreenState, setCurrentScreenState] = useState(1);
  const [insideCnt, setInsideCnt] = useState(0);
  const [totalCnt, setTotalCnt] = useState(0);
  const [focusCnt, setFocusCnt] = useState(0);

  useEffect(() => {
    if (totalCnt >= 10) {
      const insideRatio = insideCnt / totalCnt;

      if (insideRatio >= 0.5) {
        setFocusCnt(focusCnt + 1);
        if (currentScreenState === 0) {
          setCurrentScreenState(1);
          dispatch(purgeFocusType());
        }
      } else {
        setFocusCnt(0);
        if (currentScreenState !== 0) {
          setCurrentScreenState(0);
          dispatch(setFocusType(ConstansType.ADD_STUDENT_FOCUS_STATUS_LIST));
        }
      }

      setInsideCnt(0);
      setTotalCnt(0);
    } else {
      if (eyetrackingData?.screenState === "inside")
        setInsideCnt(insideCnt + 1);
      setTotalCnt(totalCnt + 1);
    }

    if (focusCnt >= 2) {
      if (currentScreenState !== 2) {
        setCurrentScreenState(2);
        dispatch(setFocusType(ConstansType.ADD_STUDENT_GOOD_FOCUS_STATUS_LIST));
      }
      setFocusCnt(0);
    }
  }, [eyetrackingData]);

  useEffect(() => {
    let focusType = "REMOVE_STUDENT_FOCUS_STATUS_LIST";
    switch (currentScreenState) {
      case 0:
        focusType = "ADD_STUDENT_FOCUS_STATUS_LIST";
        break;
      case 1:
        focusType = "REMOVE_STUDENT_FOCUS_STATUS_LIST";
        break;
      case 2:
        focusType = "ADD_STUDENT_GOOD_FOCUS_STATUS_LIST";
        break;
      default:
        break;
    }
    dispatch(
      setSocketData({
        method: "POST",
        uri: "/classroom/sendImage",
        groupId: activeSubjectClassroom?.groupId,
        clientId: user?.clientId,
        type: focusType,
        data: "",
      })
    );
    setIsRestart(false);
    console.log("sendSocketData", focusType);
  }, [currentScreenState]);

  useEffect(() => {
    const fetchTimetableData = async () => {
      if (groups !== null) {
        const timetableData = (
          await Promise.all(
            groups.map((group) => {
              return dispatch(getTimetablesByGroupPromise(group.groupId));
            })
          )
        )
          .flat()
          .find((timetable) => timetable.groupId === currentGroup?.groupId);
        console.log("[Classroom] timetable", timetableData);
        setTimetable(timetableData);
      }
    };
    fetchTimetableData();
  }, [groups]);

  useEffect(() => {
    if (!memebership) {
      return;
    }
    dispatch(setTeacherClientId(memebership?.clientId));
    dispatch(getGroupFilelinkPromise(groupId));
  }, [groupId]);

  useEffect(() => {
    const fetchData = async () => {
      const groups = (await dispatch(getMembershipPromise(user.clientId))).map(
        (membership) => membership.group
      );
      setGroups(groups);

      const activeClassroom = await dispatch(
        getActiveClassroomByGroupPromise(params.groupId)
      );
      dispatch(getMembershipsByGroupPromise(params.groupId));
      if ((params.groupId ?? null) !== null) {
        if ((activeClassroom ?? null) === null) {
          history.replace(`/classroom/off/${params.groupId}`);
        } else {
          console.log("[Classroom] activeClassroom", activeClassroom);
          setActiveSubjectClassroom(activeClassroom);

          global.eventEmitter.on("filelinkchanged", async () => {
            const filelinks = await dispatch(
              getGroupFilelinkPromise(params.groupId)
            );
            console.log("[Classroom] filelinks", filelinks);
            setFilelinks(filelinks);
          });
          global.eventEmitter.emit("filelinkchanged");

          global.eventEmitter.on("programconfigchanged", async () => {
            const programconfigs = await dispatch(
              getGroupProgramconfigsPromise(params.groupId)
            );
            console.log("[Classroom] programconfigs", programconfigs);
            setProgramconfigs(programconfigs);
          });
          global.eventEmitter.emit("programconfigchanged");
        }
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    setActiveSubjectClassroom(activeClassroomRedux);
  }, [activeClassroomRedux]);

  const findClientName = (clientId) => {
    if (!clientId) return "";

    const foundClient = clients.find((client) => client.clientId === clientId);
    if (foundClient !== null) return foundClient?.userName;

    return "";
  };

  const convertTimerToString = (time) => {
    const minute = Math.floor(time / MINUTES);
    const second = Math.floor(time) - minute * MINUTES;
    return `${minute}:${second.toString().padStart(2, "0")}`;
  };

  useEffect(() => {
    if (!activeSubjectClassroom) {
      return;
    }

    const timer = setInterval(() => {
      const diff =
        (new Date(activeSubjectClassroom.finishAt) - new Date()) / SECONDS;

      const diffSecond = parseInt(diff);
      if (diffSecond <= 0) {
        dispatch(
          deleteClassroomPromise(activeSubjectClassroom?.classroomId)
        ).then(() => {
          dispatch(
            setSocketData({
              method: "POST",
              uri: "/classroom/sendImage",
              groupId: activeSubjectClassroom?.groupId,
              clientId: user.clientId,
              type: "CLASS_END",
              data: "",
            })
          );
          dispatch(getActiveClassroomPromise(user.clientId));
        });
      }
      seconds.current = parseInt(Math.abs(diff));
    }, 1000);
    return () => clearInterval(timer);
  }, [activeSubjectClassroom]);

  const startCapture = async () => {
    const displayMediaOptions = {
      video: {
        cursor: "always",
      },
      audio: false,
    };
    if (isMonitoring === 0) {
      try {
        setMonitoring(1);
        const display = await navigator.mediaDevices.getDisplayMedia(
          displayMediaOptions
        );
        const videoElem = document.getElementById("video");
        videoElem.srcObject = display;
        videoElem.srcObject
          .getVideoTracks()[0]
          .addEventListener("ended", () => {
            var timer = null;
            console.log("stop");
            stopCapture(videoElem);
            clearInterval(timer);
          });
      } catch (err) {
        console.error("Error when start capturing screen : ", err);
      }
    }
  };

  const stopCapture = (videoElem) => {
    try {
      if (videoElem == null) return;

      let tracks = videoElem.srcObject?.getTracks();

      tracks?.forEach((track) => track.stop());
      videoElem.srcObject = null;
    } catch (err) {
      console.error("Error when stop capturing screen : ", err);
    }
  };

  const stopCaptureInterval = () => {
    clearInterval(screenCaptureIntervalId);
    setScreenCaptureIntervalId(0);
  };

  useEffect(() => {
    if (!activeSubjectClassroom) {
      stopCaptureInterval();
      dispatch(setSelectedPage(""));

      const videoElem = document.getElementById("video");
      stopCapture(videoElem);
      return;
    }

    if (activeSubjectClassroom.screenShared) {
      setModalShow(true);
    } else {
      setModalShow(false);
    }

    startCapture();
    dispatch(setSelectedClass(activeSubjectClassroom.groupId));
    dispatch(setSelectedPage("CLASS"));
    dispatch(getGroupFilelinkPromise(activeSubjectClassroom.groupId)).then(
      (res) => {
        setFilelinks(res);
      }
    );
    return;
  }, [activeSubjectClassroom]);

  const allPrograms = useSelector((
    /** @type {import('@store').State} */ state
  ) => selectAllPrograms(state));
  const groupProgramconfigs = useSelector((state) =>
    selectGroupProgramconfigs(state, activeSubjectClassroom?.groupId)
  );
  const allowedPrograms = allPrograms.filter((program) => {
    const allowedProgramconfigs = programconfigs?.filter(
      (data) => data.isAllowed
    );
    const programIds = allowedProgramconfigs?.map((data) => data.programId);

    return programIds?.includes(program.programId);
  });

  return (
    <>
      <ClassroomEyetrackingLogger
        name="ClassroomEyetrackingLogger"
        activeClassroom={activeSubjectClassroom?.groupId}
        onEyeTrackingData={setEyeTrackingData}
        restartEyeTracker={isRestart}
        setRestartEyeTracker={setIsRestart}
        focusTimeState={focusTimeState}
      >
        <PageWrapper>
          <SidebarClassRoom
            groupId={activeSubjectClassroom?.groupId}
            subject={currentGroup?.subjectName}
            active={"classroom"}
            inClass
          />
          <div className={styles["wrapper"]}>
            <BaseCard radius={8} style={{ marginBottom: 8, padding: 16 }}>
              <div className={cx("body-container")}>
                <div
                  className={styles["teacher-screen-wrap"]}
                  style={{
                    transition: "box-shadow 1.2s ease-in-out",
                    boxShadow:
                      focusState === 1
                        ? "0 0 10px 10px rgba(255, 184, 0, 0.7)"
                        : focusState === 2
                        ? "0 0 10px 10px rgba(0, 255, 0, 0.7)"
                        : "none",
                  }}
                >
                  <CommentsLayer isVisible={!modalShow} />
                  {teacherScreen ? (
                    <img
                      src={`data:image/webp;base64,${teacherScreen}`}
                      alt="screenshot"
                      className={styles["teacher-screen"]}
                    />
                  ) : (
                    <div className={styles["teacher-screen-preview"]}></div>
                  )}
                  <div className={styles["show-fullscreen-btn"]}>
                    <div className={styles["profile-wrapper"]}>
                      <ProfileImage
                        size={28}
                        url={profileImgPath(selectedStd)}
                      />
                      <div className={styles["name"]}>
                        {findClientName(selectedStd)}
                      </div>
                      <TimerOutlinedIcon className={styles["timer-icon"]} />
                      <div>{convertTimerToString(seconds.current)}</div>
                    </div>
                    <button
                      onClick={() => {
                        try {
                          window.cAPI.setTopMost(activeSubjectClassroom);
                        } catch (error) {
                          // this is not error calling browser
                        }
                        setModalShow(true);
                      }}
                    >
                      <FullscreenIcon className={styles["fullscreen-icon"]} />
                      <div className={styles["fullscreen-btn-text"]}>
                        전체 화면 보기
                      </div>
                    </button>
                  </div>

                  <SharedScreenFull
                    show={modalShow}
                    setShow={setModalShow}
                    screenImg={teacherScreen}
                    name={findClientName(selectedStd)}
                    timeString={convertTimerToString(seconds.current)}
                  />
                </div>

                <div className={cx("default-container")} ref={defaultRef}>
                  <div className={styles["program-wrapper"]}>
                    <div className={cx("section-container")}>
                      {allowedPrograms || allowedPrograms.length !== 0 ? (
                        <>
                          <span className={cx("span-section-title")}>
                            사용 가능한 앱/웹
                          </span>
                          <EnabledApps enabledApps={allowedPrograms} />
                        </>
                      ) : (
                        <span>{"사용할 수 있는 앱/웹이 없어요."}</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </BaseCard>
            <FileLinkList filelinks={filelinks} />
          </div>
        </PageWrapper>
      </ClassroomEyetrackingLogger>
    </>
  );
};

export default memo(Classroom, (prev, next) => {
  return prev === next;
});
