import { AccordionItem, Text } from "@chakra-ui/react";
import React from "react";
import QuizResultAccordionButton from "./QuizResultAccordionButton";
import ResultPanel from "./AccordionPanel/AccordionPanel";
import RankingPanel from "./AccordionPanel/RankingPanel";
import ExplainPanel from "./AccordionPanel/ExplainPanel";
import QuizAccordionPanel from "./AccordionPanel";

const QuizResultAccordionItem = ({
  resultDataItem,
  answerData,
  leaderboards,
  isShowRank,
}) => {
  const { quizType, choices } = resultDataItem;
  console.log("12", choices);
  const totalParticipants = choices.reduce(
    (sum, choice) => sum + choice.chooserCount,
    0
  );
  const isNeedGrading = ["STYLUS", "LONG"].includes(quizType);
  return (
    <AccordionItem>
      <QuizResultAccordionButton resultDataItem={resultDataItem} />
      {!isNeedGrading ? (
        <ResultPanel
          choices={choices}
          quizType={quizType}
          answerData={answerData}
          totalParticipants={totalParticipants}
        />
      ) : (
        <QuizAccordionPanel color="red">
          <Text>선생님이 채점을 완료할 때까지 기다려주세요.</Text>
        </QuizAccordionPanel>
      )}
      {isShowRank && <RankingPanel leaderboards={leaderboards} />}
      <ExplainPanel resultDataItem={resultDataItem} />
    </AccordionItem>
  );
};

export default QuizResultAccordionItem;
