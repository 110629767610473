import { Center, Heading } from "@chakra-ui/react";
const QuizLoading = () => {
  return (
    <Center height="70vh">
      <Heading>퀴즈 결과를 불러오고 있습니다. 잠시만 기다려주세요!</Heading>
    </Center>
  );
};

export default QuizLoading;
