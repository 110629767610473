import { createPromiseAction } from "@adobe/redux-saga-promise";
import {
  CREATE_EYETRACKING_DATA,
  CREATE_EYETRACKING_DATA_FAIL,
  CREATE_EYETRACKING_DATA_SUCCESS,
  PURGE_EYETRACKING_DATA,
  SET_EYETRACKING_DATA,
} from "./actionTypes";

export const purgeEyetrackingData = () => ({
  type: PURGE_EYETRACKING_DATA,
});

export const setEyetrackingData = (eyetrackingData) => ({
  type: SET_EYETRACKING_DATA,
  eyetrackingData: eyetrackingData,
});

export const createEyetrackingDataPromise = createPromiseAction(
  CREATE_EYETRACKING_DATA
);

export const createEyetrackingDataSuccess = () => ({
  type: CREATE_EYETRACKING_DATA_SUCCESS,
});

export const createEyetrackingDataFail = (error) => ({
  type: CREATE_EYETRACKING_DATA_FAIL,
  payload: error,
});
