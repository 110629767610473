import { createQuizresponse } from "@api";
import {
  Box,
  Image,
  Input,
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Spinner,
  Stack,
  Text,
  VStack,
  HStack,
} from "@chakra-ui/react";
import {
  purgeHasAnswer,
  purgeUploadResponse,
  purgeUploadTrigger,
  setHasAnswer,
  setSurveyResponseOk,
  setUploadResponse,
} from "@store/actions";
import { useMutation } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import SurveyLoading from "./SurveyLoading";
import useCreateQuizResponse from "./useCreateQuizResponse";
import ReactQuill from "react-quill";

const SurveyTime = ({
  quizId,
  quizsetSessionId,
  link,
  linkType,
  title,
  isLastQuiz,
  groupId,
  setQuizEnded,
  type,
}) => {
  console.log("ccc longlong");
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const uploadTrigger = useSelector((state) => state.control.uploadTrigger);
  const [minute, setMinute] = useState("");
  const [hour, setHour] = useState("");
  const [responseText, setResponseText] = useState("");

  const { mutate: mutateResponse, isLoading: mutateIsLoading } = useMutation(
    (quizResponse) => {
      try {
        createQuizresponse(quizResponse).then((r) => {
          setResponseText("");
          setQuizEnded(false);
          dispatch(purgeUploadTrigger());
          dispatch(purgeHasAnswer());
          dispatch(purgeUploadResponse());
          dispatch(setSurveyResponseOk());
          if (type !== "PACKAGE" && isLastQuiz) {
            alert("제출이 완료되었습니다!");
            history.push(`/focussurvey/${groupId}`);
          } else if (type === "PACKAGE" && isLastQuiz) {
            alert("제출이 완료되었습니다!");
          }
        });
      } catch (error) {
        setResponseText("");
        dispatch(purgeUploadTrigger());
        dispatch(purgeHasAnswer());
        dispatch(purgeUploadResponse());
        console.log(error);
        alert("제출에 실패했습니다.");
      }
    }
  );

  if (responseText !== "") {
    dispatch(setHasAnswer());
  } else {
    dispatch(purgeHasAnswer());
  }

  const handleCreateQuizresponse = async () => {
    console.log("ccc long");
    const quizresponse = {
      quizsetSessionId: quizsetSessionId,
      quizId: quizId,
      clientId: user.clientId,
      responseText: responseText,
    };
    mutateResponse(quizresponse);
  };

  useCreateQuizResponse(uploadTrigger, handleCreateQuizresponse);

  // if (mutateIsLoading) {
  if (uploadTrigger) {
    return <SurveyLoading />;
  }

  return (
    <Stack direction={"column"} spacing={4} mb={8}>
      {title !== "" && (
        <Box bg="gray.200" borderRadius={"lg"} p={8}>
          <ReactQuill
            theme="bubble"
            value={title}
            readOnly={true}
            modules={{ toolbar: false }}
          />
        </Box>
      )}
      {linkType === "IMG" && link !== "" && (
        <Box
          borderRadius={"lg"}
          bg={"gray.200"}
          p={8}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          h={{
            base: "400px",
            md: "400px",
            lg: "400px",
          }}
        >
          <Image
            //cors 처리
            crossOrigin={"anonymous"}
            src={link}
          />
        </Box>
      )}
      <Box
        borderRadius={"lg"}
        bg={"gray.200"}
        p={8}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <HStack>
          <FormControl display={"flex"}>
            <NumberInput
              value={hour}
              min={0}
              max={23}
              type="number"
              maxW="100px"
              mr="2rem"
              bg={"white"}
              onChange={(value) => {
                setHour(value);
                if (hour !== "" && minute !== "") {
                  setResponseText(`${hour}시간 ${minute}분`);
                }
              }}
            >
              <NumberInputField placeholder="시간" />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            {/* <FormLabel>시간</FormLabel> */}
            <NumberInput
              value={minute}
              min={0}
              max={59}
              type="number"
              maxW="100px"
              mr="2rem"
              bg={"white"}
              onChange={(value) => {
                setMinute(value);
                if (hour !== "" && minute !== "") {
                  setResponseText(`${hour}시간 ${minute}분`);
                }
              }}
            >
              <NumberInputField placeholder="분" />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            {/* <FormLabel>분</FormLabel> */}
          </FormControl>
        </HStack>
      </Box>
    </Stack>
  );
};

export default SurveyTime;
