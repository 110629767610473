export const ConstansType = {
  // 명단 삭제 == purge, 1
  REMOVE_STUDENT_FOCUS_STATUS_LIST: 'REMOVE_STUDENT_FOCUS_STATUS_LIST',
  // 집중 안함 == add orange, 0
  ADD_STUDENT_FOCUS_STATUS_LIST: 'ADD_STUDENT_FOCUS_STATUS_LIST',
  // 집중 잘함 == add green, 2
  ADD_STUDENT_GOOD_FOCUS_STATUS_LIST: 'ADD_STUDENT_GOOD_FOCUS_STATUS_LIST',

  // 수업 시작
  CLASS_START: 'CLASS_START',
  // 수업 중
  DOING_CLASS: 'DOING_CLASS',
  // 퀴즈 퍼미션 준비 요청 from 선생님
  QUIZSET_PERMISSION_READY: 'QUIZSET_PERMISSION_READY',
  // 퀴즈 퍼미션 준비 완료
  QUIZSET_PERMISSION_STUDENT_READY: 'QUIZSET_PERMISSION_STUDENT_READY',
  // 퀴즈 준비 from 선생님
  QUIZSET_READY: 'QUIZSET_READY',
  // 학생 퀴즈 준비 to 선생님
  QUIZSET_STUDENT_READY: 'QUIZSET_STUDENT_READY',
  // 퀴즈 시작
  QUIZSET_START: 'QUIZSET_START',
  // 퀴즈 진행 중
  QUIZSET_DOING: 'QUIZSET_DOING',
  // 학생 퀴즈 작성 완료
  QUIZ_STUDENT_COMPLETED: 'QUIZ_STUDENT_COMPLETED',
  // 학생 수업 참여
  CLASS_STUDENT_JOIN: 'CLASS_STUDENT_JOIN',
  // 학생 퀴즈 작성 상태 체크 요청: 선생님이 학생에게
  QUIZ_STUDENT_STATUS_CHECK: 'QUIZ_STUDENT_STATUS_CHECK',
  // 학생 퀴즈 작성 상태 체크 송신: 학생이 선생님에게
  QUIZ_STUDENT_STATUS_SEND: 'QUIZ_STUDENT_STATUS_SEND',
  // 다음 퀴즈 시작
  QUIZ_NEXT_START: 'QUIZ_NEXT_START',
  // 퀴즈 종료
  QUIZSET_COMPLETED: 'QUIZSET_COMPLETED',
  // Eyetracking disable
  DISABLE_STUDENT_FOCUS_STATUS_LIST: 'DISABLE_STUDENT_FOCUS_STATUS_LIST',
  // Eyetracking enable
  ENABLE_STUDENT_FOCUS_STATUS_LIST: 'ENABLE_STUDENT_FOCUS_STATUS_LIST',
  // 현재 퀴즈 진행 중 여부 확인
  QUIZSET_SESSION_ACTIVE_CHECK: 'QUIZSET_SESSION_ACTIVE_CHECK',
  // 현재 퀴즈 진행 중 컨펌
  QUIZSET_SESSION_ACTIVE_CONFIRM: 'QUIZSET_SESSION_ACTIVE_CONFIRM',
};
