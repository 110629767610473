import {
  Button,
  Container,
  Flex,
  HStack,
  Heading,
  Stack,
  VStack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SurveyWrapper from "./SurveyWrapper";
import { useSurveySessionStudent } from "@pages/QuizPang/hooks/useSurvey";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { setReportLog } from "@store/actions";
import { ButtonConstants } from "constants/buttonConstants";

const SurveyPackageWrapper = props => {
  const user = useSelector(state => state.user);
  const { groupId } = useParams();
  const history = useHistory();
  const surveySetList = useSelector(state => state.survey.quizsetIdList);
  const surveyData = useSurveySessionStudent(groupId, user?.clientId);
  const [currentIndex, setCurrentIndex] = useState(0);
  const dispatch = useDispatch();
  if (surveyData.isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  if (surveyData.isError) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="justify-center items-center flex flex-col space-y-4 ">
          <Heading>설문을 요청하였으나 설문이 존재하지 않습니다.</Heading>
          <HStack>
            <Button
              colorScheme="blue"
              onClick={() => {
                console.log("elmelm");
                surveyData.refetch();
                dispatch(setReportLog(ButtonConstants.SURVEY.RELOAD_SURVEY));
              }}
            >
              설문 다시 불러오기
            </Button>
            <Button
              colorScheme="blue"
              onClick={() => {
                history.push(`/quizpang/${groupId}`);
                dispatch(
                  setReportLog(ButtonConstants.SURVEY.BACK_TO_SURVEY_HOME),
                );
              }}
            >
              설문 홈으로 돌아가기
            </Button>
          </HStack>
        </div>
      </div>
    );
  }

  const surveys = Object.keys(surveySetList.quizsetIdIndex).map(quizsetId => {
    const surveySession = surveyData.surveySession.find(
      session => session.quizsetSession.quizsetId === quizsetId,
    );
    return {
      quizsetId,
      quizsetSessionId: surveySession?.quizsetSession.quizsetSessionId,
    };
  });

  const isSurveyEmpty = surveys.some(
    survey =>
      survey.quizsetSessionId === undefined ||
      survey.quizsetSessionId === null ||
      survey.quizsetSessionId === "",
  );

  if (isSurveyEmpty) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="justify-center items-center flex flex-col space-y-4 ">
          <Heading>설문을 요청하였으나 설문이 존재하지 않습니다.</Heading>
          <HStack>
            <Button
              colorScheme="blue"
              onClick={() => {
                surveyData.refetch();
                dispatch(setReportLog(ButtonConstants.SURVEY.RELOAD_SURVEY));
              }}
            >
              설문 다시 불러오기
            </Button>
            <Button
              colorScheme="blue"
              onClick={() => {
                history.push(`/quizpang/${groupId}`);
                dispatch(
                  setReportLog(ButtonConstants.SURVEY.BACK_TO_SURVEY_HOME),
                );
              }}
            >
              설문 홈으로 돌아가기
            </Button>
          </HStack>
        </div>
      </div>
    );
  }

  const currentSurvey = surveys[currentIndex];

  return (
    <Container maxW={"container.xl"}>
      <Stack>
        <Flex mt={4}>
          <Button
            onClick={() => {
              history.push(`/quizpang/${groupId}`);
              dispatch(
                setReportLog(ButtonConstants.SURVEY.BACK_TO_SURVEY_HOME),
              );
            }}
          >
            설문 홈으로 돌아가기
          </Button>
        </Flex>
        {currentSurvey && (
          <SurveyWrapper
            PropsGroupId={groupId}
            PropsQuizsetId={currentSurvey.quizsetId}
            PropsQuizsetSessionId={currentSurvey.quizsetSessionId}
            type="PACKAGE"
            setCurrentIndex={setCurrentIndex}
            currentIndex={currentIndex}
            surveys={surveys}
          />
        )}
      </Stack>
    </Container>
  );
};

export default SurveyPackageWrapper;
