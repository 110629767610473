import { Spinner, Text, VStack, Box } from '@chakra-ui/react';
import { setUploadResponse } from '@store/actions';
import React from 'react';
import { useDispatch } from 'react-redux';

const SurveyLoading = () => {
  const dispatch = useDispatch();
  dispatch(setUploadResponse());
  return (
    <Box
      borderRadius={'lg'}
      bg={'gray.200'}
      p={8}
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      h={{
        base: '400px',
        md: '400px',
        lg: '400px',
      }}
    >
      <VStack spacing={4}>
        <Spinner />
        <Text fontSize="2xl" fontWeight="bold">
          답변을 제출하는 중입니다...
        </Text>
      </VStack>
    </Box>
  );
};

export default SurveyLoading;
