import { getQuizChoiceByQuizId } from '@api';
import { FormControlLabel, Radio, RadioGroup, Stack } from '@mui/material';
import { Column } from '@pages/Survey/common';
import { createSurveyresponse } from '@store/actions';
import quiz from '@store/quiz/reducer';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { useDispatch } from 'react-redux';

/**라디오 선택지 */
export const RadioChoices = ({
  title,
  quizId,
  link,
  quizType,
  submit,
  handleSubmit,
  setSurveyAnswers,
  quizCurrentIndex,
  upload,
  type,
}) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState();

  const { data, isLoading, isError } = useQuery({
    queryKey: ['surveyChoice', quizId],
    queryFn: () => getQuizChoiceByQuizId(quizId),
  });

  useEffect(() => {
    if (submit) {
      handleSubmit(value, quizId);
    }
  }, [handleSubmit, submit, value, quizId]);

  useEffect(() => {
    if (value) {
      setSurveyAnswers((prev) => {
        return {
          ...prev,
          quizId: quizId,
          quizType: quizType,
          choidId: value,
        };
      });
      setValue(null);
    }
  }, [quizCurrentIndex]);

  const choices = data?.data.choices;

  useEffect(() => {
    if (!value) {
      return;
    }
    dispatch(createSurveyresponse(quizId, value, quizType, null, null));
  }, [upload, value]);

  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{
        mb: 2,
      }}
    >
      <ReactQuill
        value={title}
        readOnly
        theme="bubble"
        style={{
          width: '100%',
          height: 'auto',
        }}
      />
      {link && link !== 'NotSet' ? (
        <img
          crossOrigin="anonymous"
          src={link}
          alt="quiz-image"
          style={{
            width: '50%',
            height: 'auto',
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginBottom: '20px',
          }}
        />
      ) : null}

      <RadioGroup
        row
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
          gap: '10px',
          width: '100%',
          fontSize: '12px',
        }}
        value={value}
      >
        {choices?.map((data, index) => (
          <FormControlLabel
            key={index}
            value={data.choiceId}
            surveyResponseData={data.choiceId}
            control={<Radio />}
            label={data.description}
            labelPlacement="bottom"
            onChange={(e) => {
              setValue(data.choiceId);
            }}
            sx={{
              marginTop: '30px',
              fontSize: '13px !important',
              minWidth: 0,
              flexShrink: 1,
            }}
          />
        ))}
      </RadioGroup>
    </Stack>
  );
};
