import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { createArchive } from '@api/archive/archiveApi';

const MAX_RETRIES = 3;

const QuizAudioLogger = ({
  name,
  quizType,
  isStartRecording,
  onVoiceData,
  clientId,
  quizId,
  upload,
  quizSessionType,
  ...props
}) => {
  const [decibelValue, setDecibelValue] = useState(0);
  const [audioUrl, setAudioUrl] = useState();
  const media = useRef(null);
  const audioContext = useRef(null);
  const analyser = useRef(null);
  const streamRef = useRef(null);
  const chunksRef = useRef([]);
  const recording = useRef(false);
  const animationFrameId = useRef(null);
  const isUnmounted = useRef(false);

  let startTime = null;
  let finishTime = null;

  useEffect(() => {
    startRecording();
    console.log('QuizAudioLogger Mounted');
    startTime = new Date().toISOString();
    return () => {
      isUnmounted.current = true;
      stopRecording();
      console.log('QuizAudioLogger Unmounted');
    };
  }, []);

  useEffect(() => {
    stopRecording();
  }, [upload]);

  const startRecording = () => {
    console.log('Enter startRecording');
    audioContext.current = new (window.AudioContext ||
      window.webkitAudioContext)();
    analyser.current = audioContext.current.createAnalyser();
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(stream => {
        const input = audioContext.current.createMediaStreamSource(stream);
        const mediaRecorder = new MediaRecorder(stream);
        input.connect(analyser.current);

        mediaRecorder.addEventListener('dataavailable', event => {
          chunksRef.current.push(event.data);
        });

        console.log('Chunks:', chunksRef);

        mediaRecorder.start();
        media.current = mediaRecorder;
        recording.current = true;
        streamRef.current = stream;
        updateDecibelValue();
      })
      .catch(error => {
        console.error('An error occurred while getting audio stream.', error);
      });
  };

  const stopRecording = async () => {
    if (recording.current) {
      recording.current = false;
      if (media.current && media.current.state === 'recording') {
        media.current.stop();
        await new Promise(resolve => {
          media.current.addEventListener('stop', resolve);
        });
      }
      window.self.cancelAnimationFrame(animationFrameId.current);
      finishTime = new Date().toISOString();

      const audioBlob = new Blob(chunksRef.current, { type: 'audio/wav' });
      console.log('AudioBlob:', audioBlob);
      setAudioUrl(URL.createObjectURL(audioBlob));

      if (quizSessionType === 'QUIZ') {
        onVoiceData({
          chunks: chunksRef.current,
          startTimestamp: startTime,
          endTimestamp: finishTime,
        });
      } else {
        onVoiceData(prevData => [
          ...prevData,
          {
            chunks: audioBlob,
            startTimestamp: startTime,
            endTimestamp: finishTime,
          },
        ]);
      }

      console.log('Version 23.08.07-17:45 ');
      const postResponse = await postDataToServer(
        clientId,
        quizId,
        'voice',
        startTime,
        finishTime,
      );
      console.log('Voice BS : ', postResponse);
      const presignedUrl = postResponse.data.presignedUrl;
      console.log('PresignedUrl Voice : ', presignedUrl);
      const postResponse2 = await uploadDataToPresignedUrl(
        presignedUrl,
        audioBlob,
      );
      if (!isUnmounted.current) {
        console.log('Voice OS : ', postResponse2);
      }

      // const postResponse = await postDataToServer(
      //   clientId,
      //   quizId,
      //   "voice",
      //   startTime,
      //   finishTime
      // );
      // console.log("Voice BS : ", postResponse);
      // const presignedUrl = postResponse.data.presignedUrl;
      // console.log("PresignedUrl Voice : ", presignedUrl);

      // const postResponse2 = await uploadDataToPresignedUrl(
      //   presignedUrl,
      //   audioBlob
      // );
      // if (!isUnmounted.current) {
      //   console.log("Voice OS : ", postResponse2);
      // }

      resetVariables();
    }
  };

  const resetVariables = () => {
    streamRef.current.getTracks().forEach(track => track.stop());
    streamRef.current = null;
    setAudioUrl(null);
    media.current = null;
    animationFrameId.current = null;
    chunksRef.current = []; // Clear the chunksRef for next recording
  };

  const updateDecibelValue = () => {
    if (!recording.current) return;
    animationFrameId.current = window.self.requestAnimationFrame(
      updateDecibelValue,
    );
    const dataArray = new Uint8Array(analyser.current.frequencyBinCount);
    analyser.current.getByteFrequencyData(dataArray);
    const samples = dataArray.filter(value => value > 80);
    setDecibelValue(Math.max(samples));
  };

  return (
    <div
      name={name}
      style={{
        position: 'fixed', // 화면을 덮는 고정 위치 요소로 변경
        top: 0, // 상단에 위치
        left: 0, // 왼쪽에 위치
        right: 0, // 오른쪽에 위치
        bottom: 0, // 하단에 위치
        overflowY: 'scroll', // 세로 스크롤바를 표시
        WebkitOverflowScrolling: 'touch', // iOS에서 스크롤을 매끄럽게
        zIndex: 99, // 다른 요소들보다 위에 표시
        touchAction: 'none', // 스크롤 방지
      }}
      {...props}
    ></div>
  );
};

export default QuizAudioLogger;

async function postDataToServer(
  clientId,
  quizId,
  domainType,
  quizStart,
  quizEnd,
) {
  const response = await createArchive({
    clientId: clientId,
    context: 'quizpang',
    contextId: quizId,
    domain: domainType,
    startedAt: quizStart,
    endedAt: quizEnd,
  });
  return response;
}

async function uploadDataToPresignedUrl(presignedUrl, data) {
  const response = await axios.put(presignedUrl, data, {
    headers: {
      'Content-Type': 'audio/wav',
    },
  });

  return response.data;
}
